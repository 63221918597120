import { useClickAway } from '@hooks';
import cn from 'classnames';
import React, { FC, ReactNode, useState, useEffect } from 'react';

export interface DrawerProps {
  isOpen: boolean;
  children: ReactNode;
  childrenClassName?: string;
  onClose: () => void;
}

export const Drawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  childrenClassName,
  children,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const ref = useClickAway(onClose);

  useEffect(() => {
    let animationTimer: NodeJS.Timeout;

    if (isOpen) {
      animationTimer = setTimeout(() => setIsAnimating(true), 0);
    } else {
      animationTimer = setTimeout(() => setIsAnimating(false), 300);
    }

    return () => {
      clearTimeout(animationTimer);
    };
  }, [isOpen]);

  return !isAnimating && !isOpen ? null : (
    <div className="fixed inset-0 z-10 overflow-hidden transition-all duration-300 ease-in-out">
      <div className="fixed inset-0 transition-all duration-300 ease-in-out transform">
        <div
          className={cn('absolute inset-0 ', {
            'backdrop-blur-lg': isOpen,
          })}
        />
      </div>

      <div
        ref={ref}
        className={cn(
          'fixed inset-y-0 right-0 max-w-full flex outline-none bg-white transform transition-transform ease-in-out duration-300',
          {
            'translate-x-0': isOpen && isAnimating,
            'translate-x-full': !isOpen || !isAnimating,
          }
        )}
      >
        <div className="w-screen max-w-md">
          <div
            className={cn(
              'relative flex flex-col h-full bg-white shadow-xl',
              childrenClassName
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
