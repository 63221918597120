import { Tabs } from '@ui';
import React, { FC, useState } from 'react';
import { tabs } from './config';
import { Enterprise, Locations, Roles, Users } from './tabs';

export const EnterpriseSettings: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className="h-full">
      <Tabs value={tabIndex} onChange={setTabIndex} tabs={tabs} />

      {tabIndex === 0 && <Enterprise />}
      {tabIndex === 1 && <Locations />}
      {tabIndex === 2 && <Users />}
      {tabIndex === 3 && <Roles />}
    </div>
  );
};
