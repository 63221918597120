import { z } from 'zod';

const addLocationFields = z.object({
  name: z.string({ required_error: 'Required' }),
  address: z.string({ required_error: 'Required' }),
  areaCode: z.string({ required_error: 'Required' }).or(z.number()),
  phone: z.string({ required_error: 'Required' }).or(z.number()),
});

const AddLocationSchema = addLocationFields;

export type AddLocationValues = z.infer<typeof AddLocationSchema>;

export { AddLocationSchema };
