import React from 'react';
import { Colors, Icons } from '@utils';

import { Icon } from '../Icon';

interface IndicatorProps {
  label: string;
  counter: number;
  rising?: boolean;
  hideArrow?: boolean;
}

export const Indicator: React.FC<IndicatorProps> = ({
  label,
  counter,
  rising = true,
  hideArrow = false,
}) => (
  <div className="flex flex-col items-center justify-center flex-1 p-5 bg-white rounded-xl">
    <div className="flex flex-row items-center mb-[2px]">
      <span className="text-3xl text-fullfork mr-[6px]">{counter}</span>

      {!hideArrow && (
        <div className="h-[15px] w-[15px] flex justify-center items-center bg-lightgrey rounded-full">
          <Icon
            icon={rising ? Icons.ArrowRightUp : Icons.ArrowRightDown}
            color={rising ? Colors.Success : Colors.Danger}
            size={10}
          />
        </div>
      )}
    </div>

    <span className="text-[10px] leading-[15px] text-darkgrey text-center">
      {label}
    </span>
  </div>
);
