import React, { useEffect, useRef, useState } from 'react';
import { useClickAway } from '@hooks';
import { Colors, DateFormats, Icons, formatDate } from '@utils';
import { DateRange, DayPicker } from 'react-day-picker';
import { sub } from 'date-fns';
import cn from 'classnames';

import { Icon } from '../Icon';

import { Caption, DayContent } from './Custom';

import 'react-day-picker/dist/style.css';
import './style.css';

const today = new Date();

const defaultSelected: DateRange = {
  from: sub(today, { days: 7 }),
  to: today,
};

interface DatePickerProps {
  type?: 'default' | 'range';
  label?: string;
  value: Date;
  disableDaysBefore?: Date;
  withNoBorder?: boolean;
  className?: string;
  onChange: (date: Date) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  type = 'default',
  label,
  value,
  disableDaysBefore,
  withNoBorder,
  className,
  onChange,
}) => {
  const isRange = type === 'range';

  const buttonRef = useRef<HTMLButtonElement>(null);

  const [date, setDate] = useState<Date | undefined>(value);
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const [isPickerVisible, setPickerVisible] = useState(false);

  useEffect(() => {
    onChange(date as Date);
  }, [date]);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const ref = useClickAway(() => setPickerVisible(false), [buttonRef]);

  const selectedDateFormatted = formatDate(
    date || new Date(),
    DateFormats.AMERICAN_FORMAT
  );
  const rangeFromFormatted = formatDate(
    range?.from || new Date(),
    DateFormats.AMERICAN_FORMAT
  );
  const rangeToFormatted = formatDate(
    range?.to || range?.from || new Date(),
    DateFormats.AMERICAN_FORMAT
  );

  return (
    <div className="relative w-min">
      {!!label && (
        <p className="block text-[11px] leading-[16px] text-darkgrey mb-[5px]">
          {label}
        </p>
      )}

      <button
        ref={buttonRef}
        type="button"
        onClick={() => setPickerVisible(!isPickerVisible)}
        className={cn(
          'relative w-min bg-white border border-borderColor rounded-[5px]',
          {
            '!border-none': withNoBorder,
          },
          className
        )}
      >
        <div className="h-[40px] flex flex-row justify-between items-center px-3">
          <div className="flex">
            <div>
              <Icon icon={Icons.Calendar} color={Colors.DarkGrey} size={18} />
            </div>

            <span className="text-[13px] text-darkgrey uppercase ml-2 pt-1">
              {isRange ? rangeFromFormatted : selectedDateFormatted}
            </span>
          </div>

          {isRange ? (
            <>
              <span className="text-[13px] text-darkgrey mx-3 pt-1">to</span>

              <Icon icon={Icons.Calendar} color={Colors.DarkGrey} size={18} />

              <span className="text-[13px] text-darkgrey uppercase ml-2 pt-1">
                {rangeToFormatted}
              </span>
            </>
          ) : (
            <div className="ml-2">
              <Icon
                icon={Icons.CollapsibleArrow}
                size={8}
                color={Colors.DarkGrey}
              />
            </div>
          )}
        </div>
      </button>

      {isPickerVisible && (
        <div
          ref={ref}
          className={cn(
            'absolute z-10 top-[44px] rounded-[10px] bg-white shadow-md',
            { 'top-[65px]': !!label }
          )}
        >
          <DayPicker
            disabled={disableDaysBefore ? { before: disableDaysBefore } : false}
            mode={(isRange ? 'range' : 'single') as any}
            onSelect={(isRange ? setRange : setDate) as any}
            selected={isRange ? range : date}
            components={{ Caption, DayContent }}
            defaultMonth={today}
            showOutsideDays
          />
        </div>
      )}
    </div>
  );
};
