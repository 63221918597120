/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CartItem, CartSlice } from './types';
import { uuid } from '@utils';

const initialState: CartSlice = {
  isOpen: false,
  items: [],
  total: 0,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    openCart: (state) => {
      state.isOpen = true;
    },
    closeCart: (state) => {
      state.isOpen = false;
    },

    addItem: (state, { payload }: PayloadAction<Omit<CartItem, 'id'>>) => {
      const { price, count, name } = payload;

      const duplicateItemIndex = state.items
        .map((item) => item.name)
        .indexOf(name);

      if (duplicateItemIndex !== -1) {
        state.items[duplicateItemIndex].count += 1;

        return;
      }

      state.items.push({ ...payload, id: uuid() });
      state.total += price * count;
    },
    deleteItem: (state, { payload }: PayloadAction<string>) => {
      state.items = state.items.filter((item) => item.id !== payload);

      state.total = state.items.reduce(
        (acc, { count, price }) => acc + count * price,
        0
      );
    },
    editItem: (state, { payload }: PayloadAction<CartItem>) => {
      const { id } = payload;

      state.items = state.items.map((item) =>
        item.id === id ? payload : item
      );
      state.total = state.items.reduce(
        (acc, { count, price }) => acc + count * price,
        0
      );
    },
  },
});

export const cartActions = cartSlice.actions;
