/* eslint-disable quote-props */

import { Colors, Images } from '@utils';

export interface ProductModal {
  title: string;
  description: string;
  price: number;
  color: string;
  logo: string;
  images: string[];
}

export const appStoreModals: Record<string, ProductModal> = {
  'fullfork': {
    title: 'Fullfork',
    description:
      'Lorem ipsum dolor sit amet consectetur. Sagittis nisl viverra netus orci non erat malesuada. A id tortor ullamcorper congue. Iaculis ultrices vitae tincidunt nulla nullam laoreet quam sed lobortis. Viverra tortor justo sagittis enim. A nunc vel quam purus blandit cursus ante mauris egestas. Sed molestie urna ultrices metus volutpat volutpat at lobortis nisi. Eu sed tempus phasellus pulvinar et. Vel nunc et tellus cursus dui ac. ',
    price: 99,
    color: Colors.FullFork,
    logo: Images.FullforkLogo,
    images: [
      Images.FullforkLogo,
      Images.FullforkDashboard,
      Images.FullforkTable,
    ],
  },
  'plum-wifi': {
    title: 'Plum WiFi',
    description:
      'Lorem ipsum dolor sit amet consectetur. Sagittis nisl viverra netus orci non erat malesuada. A id tortor ullamcorper congue. Iaculis ultrices vitae tincidunt nulla nullam laoreet quam sed lobortis. Viverra tortor justo sagittis enim. A nunc vel quam purus blandit cursus ante mauris egestas. Sed molestie urna ultrices metus volutpat volutpat at lobortis nisi. Eu sed tempus phasellus pulvinar et. Vel nunc et tellus cursus dui ac. ',
    price: 99,
    color: Colors.PlumWifi,
    logo: Images.PlumWifiLogo,
    images: [
      Images.PlumWifiLogo,
      Images.PlumWifiDashboard,
      Images.PlumWifiSettings,
    ],
  },
  'zip-loyalty': {
    title: 'Zip Loyalty',
    description:
      'Lorem ipsum dolor sit amet consectetur. Sagittis nisl viverra netus orci non erat malesuada. A id tortor ullamcorper congue. Iaculis ultrices vitae tincidunt nulla nullam laoreet quam sed lobortis. Viverra tortor justo sagittis enim. A nunc vel quam purus blandit cursus ante mauris egestas. Sed molestie urna ultrices metus volutpat volutpat at lobortis nisi. Eu sed tempus phasellus pulvinar et. Vel nunc et tellus cursus dui ac. ',
    price: 99,
    color: Colors.ZipLoyalty,
    logo: Images.ZipLoyaltyLogo,
    images: [
      Images.ZipLoyaltyLogo,
      Images.ZipLoyaltyDashboard,
      Images.ZipLoyaltyTable,
    ],
  },
  'zip-marketing': {
    title: 'Zip Marketing',
    description:
      'Lorem ipsum dolor sit amet consectetur. Sagittis nisl viverra netus orci non erat malesuada. A id tortor ullamcorper congue. Iaculis ultrices vitae tincidunt nulla nullam laoreet quam sed lobortis. Viverra tortor justo sagittis enim. A nunc vel quam purus blandit cursus ante mauris egestas. Sed molestie urna ultrices metus volutpat volutpat at lobortis nisi. Eu sed tempus phasellus pulvinar et. Vel nunc et tellus cursus dui ac. ',
    price: 99,
    color: Colors.ZipMarketing,
    logo: Images.ZipMarketingLogo,
    images: [
      Images.ZipMarketingLogo,
      Images.ZipMarketingDashboard,
      Images.ZipMarketingTable,
    ],
  },
  'zip-surveillance': {
    title: 'Zip Surveillance',
    description:
      'Lorem ipsum dolor sit amet consectetur. Sagittis nisl viverra netus orci non erat malesuada. A id tortor ullamcorper congue. Iaculis ultrices vitae tincidunt nulla nullam laoreet quam sed lobortis. Viverra tortor justo sagittis enim. A nunc vel quam purus blandit cursus ante mauris egestas. Sed molestie urna ultrices metus volutpat volutpat at lobortis nisi. Eu sed tempus phasellus pulvinar et. Vel nunc et tellus cursus dui ac. ',
    price: 99,
    color: Colors.ZipSurveillance,
    logo: Images.ZipSurveillanceLogo,
    images: [
      Images.ZipSurveillanceLogo,
      Images.ZipSurveillanceDashboard,
      Images.ZipSurveillanceTable,
    ],
  },
};

export const locations = [
  '190 S. State College Blvd, Brea, CA 92821',
  'Xyz Blvd, 1234 Street, Pasadena, CA 12345',
  'ABC Street, 567 Blvd, Irvine, CA 12345',
];
