import { z } from 'zod';

const notificationSettingsFields = z.object({
  email: z
    .string({ required_error: 'Email is required' })
    .email('Invalid email.'),
  phone: z.string({ required_error: 'Phone is required' }),
});

const NotificationSettingsSchema = notificationSettingsFields;

export type NotificationSettingsValues = z.infer<
  typeof NotificationSettingsSchema
>;

export { NotificationSettingsSchema };
