import { z } from 'zod';

const settingsFields = z.object({
  routerName: z.string({ required_error: 'Required' }),
  guestSSID: z.string({ required_error: 'Required' }),
  employeeESSID: z.string({ required_error: 'Required' }),
  ESSIDPassword: z.string({ required_error: 'Required' }),
  bandWidth: z.number({ required_error: 'Required' }),
  sessionDuration: z.number({ required_error: 'Required' }),
  downloadLimit: z.number({ required_error: 'Required' }),
  from: z.any(),
  to: z.any(),
  days: z.any().array(),
});

const SettingsSchema = settingsFields;

export type SettingsValues = z.infer<typeof SettingsSchema>;

export { SettingsSchema };
