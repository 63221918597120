import { Autocomplete, Icon, RouterSwitch, Spinner, Text } from '@ui';
import React, { FC, useState } from 'react';
import { Icons, MOCKED_ENTERPRISE_ID } from '@utils';
import { useGetAllLocationsQuery } from '@api';
import { useDispatch, useSelector } from '@store';
import { plumWifiActions, plumWifiSelectors } from '@store/entities';
import { adaptAddressOptions, adaptDashboardCards } from '../helpers/adapters';
import cn from 'classnames';

export const Dashboard: FC = () => {
  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(true);

  const {
    currentLocationId: locationId,
    settings,
    isSettingsLoading,
  } = useSelector(plumWifiSelectors.all);

  const { data: locations, isLoading } =
    useGetAllLocationsQuery(MOCKED_ENTERPRISE_ID);

  const onSelectLocation = (id: number) => {
    dispatch(plumWifiActions.setCurrentLocation(id));
  };

  return (
    <div className="w-full  px-10 bg-plumwifi-bg py-9 rounded-[10px] !backdrop-brightness-125">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-5">
          <RouterSwitch checked={enabled} onChange={setEnabled} />

          <button
            type="button"
            className="flex items-center text-white gap-x-[5px] hover:opacity-80 active:brightness-75"
          >
            <Text variant="h5">Reboot Router</Text>
            <Icon
              icon={Icons.Restart}
              className={cn('transition-all duration-500', {
                'rotate-180': enabled,
              })}
            />
          </button>
        </div>

        <Autocomplete
          value={locationId}
          onChange={onSelectLocation}
          options={locations ? adaptAddressOptions(locations) : []}
          loading={isLoading}
        />
      </div>
      <div className="flex items-stretch justify-between gap-x-9 mt-9">
        {adaptDashboardCards(settings).map((card) => (
          <div
            key={card.label}
            className="flex flex-col items-center justify-center py-10 border flex-1 border-white rounded-[10px] text-white bg-plumwifi"
          >
            {isSettingsLoading ? (
              <Spinner className="h-[64px]" remSize={2} />
            ) : (
              <>
                <Text variant="h2" className="!font-bold whitespace-nowrap">
                  {`${card.value} ${card.suffix}`}
                </Text>
                <span className="text-[13px] leading-5 flex gap-x-1 items-center justify-center">
                  <div className=" bg-white rounded-full h-[17px] w-[17px] text-plumwifi flex items-center justify-center">
                    <Icon icon={card.icon} size={11} />
                  </div>
                  {card.label}
                </span>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
