import cn from 'classnames';
import React from 'react';

interface CounterProps {
  count: number;
  label: string;
  className?: string;
  onClick?: () => void;
}

export const Counter: React.FC<CounterProps> = ({
  count,
  label,
  className,
  onClick,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        'p-4 rounded-md w-max bg-gradient-to-r from-fullfork to-customerology',
        className
      )}
    >
      <div className="flex flex-row py-4 px-5 rounded-xl border-[0.5px] border-white text-white">
        <span className="mr-2 text-3xl font-bold">{count}</span>
        <span className="pt-3 text-sm">{label}</span>
      </div>
    </button>
  );
};
