import React, { InputHTMLAttributes } from 'react';

import { Icons } from '../../utils';
import { Icon } from '../Icon';

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({ label, ...rest }) => (
  <div>
    {!!label && (
      <p className="block text-[11px] leading-[16px] text-darkgrey mb-[5px]">
        {label}
      </p>
    )}

    <div className="w-full h-[40px] flex items-center border border-borderColor rounded-[5px]">
      <div className="pl-[10px] -translate-y-[1px]">
        <Icon icon={Icons.Search} size={15} />
      </div>

      <input
        className="w-full h-[38px] flex items-center px-[10px] pt-[1px] text-[13px] outline-none rounded-[5px] placeholder:text-darkgrey"
        {...rest}
      />
    </div>
  </div>
);
