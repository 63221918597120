import { Colors, Icons } from '@utils';
import { Button, Icon, Text } from '@ui';
import React, { FC } from 'react';

const inputNames = ['Email Address', 'Phone Number'];

interface ConnectSlideProps {
  logo: string;
  logoAlt: string | undefined;
  withFacebook: boolean;
  withInstagram: boolean;
  withTwitter: boolean;
}

export const ConnectSlide: FC<ConnectSlideProps> = ({
  logo,
  logoAlt,
  withFacebook,
  withInstagram,
  withTwitter,
}) => {
  return (
    <div className="flex flex-col justify-between rounded-[10px] bg-white w-[350px] h-[420px] py-5 px-[14px]">
      <div className="flex items-center justify-start gap-x-4">
        {logo ? (
          <img
            src={logo}
            alt={logoAlt || 'logo'}
            className="object-cover min-w-[75px] !h-[75px] !w-[75px] rounded-full"
          />
        ) : (
          <div className="min-h-[75px] min-w-[75px] bg-lightgrey rounded-full" />
        )}

        <div>
          <Text variant="h5" className="!font-bold">
            CONNECT TO FREE WIFI
          </Text>
          <Text variant="body2" className="!font-bold">
            To connect to free WiFi. Please enter your information below:
          </Text>
        </div>
      </div>

      <div className="flex items-center justify-center h-5 pt-4 gap-x-5">
        {withFacebook && (
          <Icon icon={Icons.Facebook} color={Colors.PlumWifi} size={20} />
        )}
        {withInstagram && (
          <Icon icon={Icons.Instagram} color={Colors.PlumWifi} size={20} />
        )}
        {withTwitter && (
          <Icon icon={Icons.Twitter} color={Colors.PlumWifi} size={20} />
        )}
      </div>

      {inputNames.map((text) => (
        <div
          key={text}
          className="border mt-5 border-borderColor rounded-[10px] p-[13px] text-darkgrey text-[16px] leading-6 font-bold cursor-default"
        >
          {text}
        </div>
      ))}

      <Text variant="body2" className="mt-5 text-text !font-bold">
        By Connecting, I agree to allow my information to be used for future
        marketing communication
      </Text>

      <Button
        disabled
        label="Connect"
        color="plum-wifi"
        className="mt-5 w-full !min-h-[49px] font-bold !rounded-[10px] disabled:!opacity-100"
      />
    </div>
  );
};
