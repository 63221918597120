import { PageTab, SelectOption } from '@ui';
import { Icons } from '@utils';

export const tabs: PageTab[] = [
  { icon: Icons.GlobeStretched, label: 'Overview' },
  { icon: Icons.Pencil, label: 'Customization' },
  { icon: Icons.SettingsStretched, label: 'Settings' },
];

export interface DashboardCard {
  label: string;
  value: number;
  suffix: string;
  icon: string;
}

export const emptyDashboardCards: DashboardCard[] = [
  {
    value: 0,
    suffix: 'Users',
    label: 'Users Connected',
    icon: Icons.Users,
  },
  {
    value: 0,
    suffix: 'Mb',
    label: 'Download Limit',
    icon: Icons.Users,
  },
  {
    value: 0,
    suffix: 'Mbps',
    label: 'Bandwidth',
    icon: Icons.Users,
  },
  {
    value: 0,
    suffix: 'Hour',
    label: 'Session',
    icon: Icons.Users,
  },
];

export const addressOptions: SelectOption[] = [
  {
    label: '1st Random Street, Brea, CA',
    value: '1st Random Street, Brea, CA',
  },
  {
    label: '2nd Random Street, Brea, CA',
    value: '2nd Random Street, Brea, CA',
  },
  {
    label: 'ABC Blvd. Irvine, CA',
    value: 'ABC Blvd. Irvine, CA',
  },
  {
    label: 'cdn Blvd. Irvine, CA',
    value: 'cdn Blvd. Irvine, CA',
  },
];

export const chartOptions: SelectOption[] = [
  {
    label: 'Loyalty Users',
    value: 0,
  },
  {
    label: 'New Users',
    value: 1,
  },
  {
    label: 'Total Connections (MTD)',
    value: 2,
  },
  {
    label: 'Avg Session Length (MTD)',
    value: 3,
  },
];

export const chartData = [
  { date: 'Feb 22', counter: 15 },
  { date: 'Mar 22', counter: 5 },
  { date: 'Apr 22', counter: 12 },
  { date: 'May 22', counter: 11 },
  { date: 'Jun 22', counter: 6 },
  { date: 'Jul 22', counter: 15 },
  { date: 'Aug 22', counter: 5 },
  { date: 'Sep 22', counter: 18 },
  { date: 'Oct 22', counter: 22 },
  { date: 'Nov 22', counter: 13 },
  { date: 'Dec 22', counter: 35 },
  { date: 'Jan 23', counter: 45 },
  { date: 'Feb 23', counter: 45 },
];
