import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../baseQuery';
import { HTTPMethod } from '../config';

import { Offer } from './models';
import {
  CreateOfferRequest,
  EditOfferRequest,
  GetOfferCountRequest,
  GetOffersRequest,
} from './config';

export const FETCH_OFFERS_LIMIT = 10;

export const offerApi = createApi({
  baseQuery,
  reducerPath: 'offerApi',
  tagTypes: ['Offer', 'OfferCount'],
  endpoints: (builder) => ({
    getOffers: builder.query<Offer[], GetOffersRequest>({
      query: ({ id, pageNum, active }) => {
        const baseParams = { pageNum, fetchLimit: FETCH_OFFERS_LIMIT };

        return {
          url: `/Enterprise/${id}/Offer/Paging`,
          params: active !== null ? { ...baseParams, active } : baseParams,
          method: HTTPMethod.GET,
        };
      },
      providesTags: ['Offer'],
    }),
    getOfferCount: builder.query<number, GetOfferCountRequest>({
      query: ({ id, active }) => ({
        url: `/Enterprise/${id}/OfferCount`,
        params: active !== null ? { active } : {},
        method: HTTPMethod.GET,
      }),
      providesTags: ['OfferCount'],
    }),
    getOfferById: builder.query<Offer, number>({
      query: (id) => ({
        url: `/Offer/${id}`,
        method: HTTPMethod.GET,
      }),
      providesTags: ['Offer'],
    }),
    createOffer: builder.mutation<void, CreateOfferRequest>({
      query: ({ id, offer }) => ({
        url: `/Enterprise/${id}/Offer`,
        body: offer,
        method: HTTPMethod.POST,
      }),
      invalidatesTags: ['Offer', 'OfferCount'],
    }),
    editOffer: builder.mutation<void, EditOfferRequest>({
      query: ({ id, offer }) => ({
        url: `/Offer/${id}`,
        body: offer,
        method: HTTPMethod.PUT,
      }),
      invalidatesTags: ['Offer', 'OfferCount'],
    }),
    toggleOfferState: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `/Offer/${id}/toggleState`,
        method: HTTPMethod.GET,
      }),
      invalidatesTags: ['Offer', 'OfferCount'],
    }),
    deleteOffer: builder.mutation<void, number>({
      query: (id) => ({
        url: `/Offer/${id}`,
        method: HTTPMethod.DELETE,
      }),
      invalidatesTags: ['Offer', 'OfferCount'],
    }),
  }),
});

export const {
  useGetOffersQuery,
  useGetOfferCountQuery,
  useGetOfferByIdQuery,
  useCreateOfferMutation,
  useEditOfferMutation,
  useDeleteOfferMutation,
  useToggleOfferStateMutation,
} = offerApi;
