/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PlumWifiSlice } from './types';
import { Router, locationApi, plumWifiApi } from '@api';

const initialState: PlumWifiSlice = {
  currentLocationId: null,
  currentRouter: null,
  settings: null,
  isSettingsLoading: false,
};

export const plumWifiSlice = createSlice({
  name: 'plumWifi',
  initialState,
  reducers: {
    setCurrentLocation: (state, { payload }: PayloadAction<number>) => {
      state.currentLocationId = payload;
    },
    setCurrentRouter: (state, { payload }: PayloadAction<Router>) => {
      state.currentRouter = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      locationApi.endpoints.getAllLocations.matchFulfilled,
      (state, action) => {
        state.currentLocationId = action.payload[0].id || null;
      }
    );
    builder.addMatcher(
      plumWifiApi.endpoints.getRoutersByLocation.matchFulfilled,
      (state, action) => {
        state.currentRouter = action.payload?.length ? action.payload[0] : null;
      }
    );
    builder.addMatcher(
      plumWifiApi.endpoints.getWifiSettingsByLocation.matchFulfilled,
      (state, action) => {
        state.settings = action.payload;
        state.isSettingsLoading = false;
      }
    );
    builder.addMatcher(
      plumWifiApi.endpoints.getWifiSettingsByLocation.matchPending,
      (state) => {
        state.isSettingsLoading = true;
      }
    );
  },
});

export const plumWifiActions = plumWifiSlice.actions;
