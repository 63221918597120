import {
  TableOptions,
  defaultColumnSizing,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import cn from 'classnames';

import { SortIcons } from './SortIcons';
import { ControlPanel } from '../ControlPanel';
import { AllOrNothing } from '@types';
import { Switch } from '../Switch';
import { Icon } from '../Icon';
import { Icons } from '@utils';
import { Text } from '../Text';

interface AdditionalTableProps {
  className?: string;
  theadClassName?: string;
  tdClassName?: string;
  allowTextFull?: boolean;
}

type TableProps<Data> = Pick<TableOptions<Data>, 'data' | 'columns'> &
  AdditionalTableProps &
  AllOrNothing<{
    withControlPanel: boolean;
    uniqueKey: keyof Data;
    statusKey: keyof Data;
    isError: boolean;
    onToggle: (id: number) => void;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
  }> &
  AllOrNothing<{
    withStatusSwitch: boolean;
    uniqueKey: keyof Data;
    statusKey: keyof Data;
    isError: boolean;
    onToggle: (id: number) => void;
  }> &
  AllOrNothing<{
    withEdit: boolean;
    uniqueKey: keyof Data;
    onEdit: (id: number) => void;
  }>;

export const Table = <Data extends object>({
  data,
  columns,
  uniqueKey,
  statusKey,
  withControlPanel,
  withStatusSwitch,
  withEdit,
  className,
  theadClassName,
  tdClassName,
  isError,
  allowTextFull,
  onToggle,
  onEdit,
  onDelete,
}: TableProps<Data>) => {
  const table = useReactTable({
    data,
    columns,
    defaultColumn: { ...defaultColumnSizing },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <table
      className={cn(
        'w-full mb-6 border border-[#DCDCDC] rounded-md border-collapse overflow-x-scroll',
        className
      )}
    >
      <thead
        className={cn(
          'bg-[#F9F9F9] border border-[#DCDCDC] max-h-[66px]',
          theadClassName
        )}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th
                  key={header.id}
                  style={{
                    width:
                      header.column.columnDef.header?.valueOf() ===
                      'Description'
                        ? 600
                        : undefined,
                  }}
                  className={cn('text-left py-3 px-5', tdClassName)}
                >
                  {header.isPlaceholder ? null : (
                    <button
                      className="flex items-center justify-center"
                      type="button"
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      <span className="font-normal text-[15px] leading-[22.5px] text-darkgrey mr-2">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </span>
                      <SortIcons sort={header.column.getIsSorted()} />
                    </button>
                  )}
                </th>
              );
            })}

            {(withControlPanel || withEdit) && <td />}
          </tr>
        ))}
      </thead>

      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id} className="border border-[#DCDCDC]">
            {row.getVisibleCells().map((cell) => {
              const isIdColumn =
                cell.column.columnDef.header?.valueOf() === 'ID';
              const isStatusColumn =
                cell.column.columnDef.header?.valueOf() === 'Status';

              return (
                <td
                  key={cell.id}
                  className={cn(
                    'py-3 px-5 overflow-y-hidden align-top',
                    {
                      'text-fullfork': isIdColumn,
                      'text-danger': isStatusColumn && !cell.getValue(),
                      'text-success': isStatusColumn && cell.getValue(),
                    },
                    tdClassName
                  )}
                >
                  {withStatusSwitch && cell.column.id === 'Status' ? (
                    <Switch
                      checked={!!cell.getValue()}
                      onCheckedChange={() => {
                        onToggle(row.original[uniqueKey] as number);
                      }}
                    />
                  ) : (
                    <div
                      className={cn({
                        'overflow-hidden line-clamp-2 -webkit-line-clamp-2 -webkit-box-orient-vertical':
                          !allowTextFull,
                      })}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  )}
                </td>
              );
            })}

            {withControlPanel && (
              <td className={cn('py-3 px-5', tdClassName)}>
                <ControlPanel
                  className="ml-auto"
                  checked={!!row.original[statusKey]}
                  isError={isError}
                  onDelete={() => onDelete(row.original[uniqueKey] as number)}
                  onToggle={() => onToggle(row.original[uniqueKey] as number)}
                  onEdit={() => onEdit(row.original[uniqueKey] as number)}
                />
              </td>
            )}

            {withEdit && (
              <td className={cn('py-3 px-5', tdClassName)}>
                <button
                  type="button"
                  onClick={() => onEdit(row.original[uniqueKey] as number)}
                  className="flex items-center justify-start hover:opacity-80 active:opacity-100"
                >
                  <Icon icon={Icons.Pencil} size={19} />
                  <Text variant="body1" className="ml-1">
                    Edit
                  </Text>
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
