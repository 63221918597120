import { backgroundEnum } from '@utils';
import { Solution, SolutionTitle } from '@types';
import cn from 'classnames';
import React, { FC } from 'react';

interface SubscriptionLabelProps {
  variant: Solution;
  price: number;
}

export const SubscriptionLabel: FC<SubscriptionLabelProps> = ({
  price,
  variant,
}) => {
  const [firstWord, secondWord] = SolutionTitle[variant].split(' ');

  return (
    <label className="flex justify-start items-center bg-[#F2F2F2] text-[13px] rounded-[5px] h-7 pl-[10px] w-min shadow-sm">
      <b>
        {' '}
        $
        {price}
      </b>
      <span
        className={cn(
          'h-7 rounded-[5px] text-white leading-7 px-[14px] ml-1 whitespace-nowrap',
          backgroundEnum(variant)
        )}
      >
        <b>{firstWord}</b>
        {' '}
        {secondWord}
      </span>
    </label>
  );
};
