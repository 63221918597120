import { Icon, LoaderContainer, Text } from '@ui';
import React, { FC } from 'react';
import { Colors, Icons } from '@utils';
import cn from 'classnames';
import { useDispatch, useSelector } from '@store';
import { plumWifiActions, plumWifiSelectors } from '@store/entities';
import { Router, useGetRoutersByLocationQuery } from '@api';
import { match } from 'ts-pattern';
import { skipToken } from '@reduxjs/toolkit/dist/query';

interface RouterItem {
  enabled: boolean;
  name: string;
}

export const routersList: RouterItem[] = [
  {
    enabled: true,
    name: 'Wifi Router 1',
  },
  {
    enabled: false,
    name: 'Wifi Router 2',
  },
  {
    enabled: false,
    name: 'Wifi Router 3',
  },
];

interface RoutersListProps {
  className?: string;
}

export const RoutersList: FC<RoutersListProps> = ({ className }) => {
  const dispatch = useDispatch();

  const locationId = useSelector(plumWifiSelectors.locationId);
  const currentRouter = useSelector(plumWifiSelectors.router);

  const {
    data: routers,
    isLoading,
    isFetching,
  } = useGetRoutersByLocationQuery(locationId ?? skipToken, {
    refetchOnMountOrArgChange: !locationId,
    skip: !locationId,
  });

  const onChangeRouter = (router: Router) => {
    dispatch(plumWifiActions.setCurrentRouter(router));
  };

  return (
    <div className={className}>
      <Text variant="h3" className="!font-bold">
        List of Routers
      </Text>

      <LoaderContainer
        loading={isLoading || isFetching}
        color={Colors.PlumWifi}
      >
        <ul className="flex flex-col mt-4 text-white gap-y-4">
          {match({ isRoutersAvailable: !!routers?.length })
            .with({ isRoutersAvailable: true }, () =>
              routers?.map((router) => (
                <button
                  key={router.id}
                  type="button"
                  onClick={() => onChangeRouter(router)}
                  className={cn(
                    'flex justify-between items-center  gap-x-[10px] rounded-[10px] py-[13px] px-4 hover:bg-plumwifi hover:text-white hover:opacity-100',
                    {
                      'bg-plumwifi cursor-default':
                        router.id === currentRouter?.id,
                      'opacity-20 text-text cursor-pointer':
                        router.id !== currentRouter?.id,
                    }
                  )}
                >
                  <div className="flex items-center justify-start gap-x-[10px] whitespace-nowrap">
                    <div
                      className={cn('h-[12px] w-[12px] rounded-full', {
                        'bg-success': router.macAddress,
                        'bg-danger': !router.macAddress,
                      })}
                    />
                    {router.name}
                  </div>
                  <Icon icon={Icons.ArrowRight} size={10} />
                </button>
              ))
            )
            .with({ isRoutersAvailable: false }, () => (
              <span className="text-text">
                There is no routers for current location
              </span>
            ))
            .exhaustive()}
        </ul>
      </LoaderContainer>
    </div>
  );
};
