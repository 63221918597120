import React, { useEffect, useMemo, useState } from 'react';
import { Images, MOCKED_ENTERPRISE_ID } from '@utils';
import { Indicator, Loader, Select } from '@ui';
import { ChartValue, useGetOverviewQuery } from '@api';
import { match } from 'ts-pattern';
import { BarChart } from '@features';

export const Overview: React.FC = () => {
  const [selectedChart, setSelectedChart] = useState<number | null>(null);
  const [currentChartData, setCurrentChartData] = useState<ChartValue[] | null>(
    null
  );

  const { data: overview, isLoading } = useGetOverviewQuery({
    Enterprise_Id: MOCKED_ENTERPRISE_ID,
  });

  const chartOptions = useMemo(() => {
    return overview?.chartElements
      ? overview?.chartElements.map((el) => ({
          label: el.title,
          value: el.chartCode,
        }))
      : [];
  }, [overview?.chartElements]);

  const handleSelectChange = (newValue: number) => {
    setSelectedChart(newValue);
  };

  useEffect(() => {
    if (chartOptions?.length) {
      setSelectedChart(chartOptions[0].value);
    }
  }, [chartOptions]);

  const onSelectedChartChange = () => {
    if (overview?.chartElements && selectedChart) {
      const newChartData = overview.chartElements.find(
        (el) => el.chartCode === selectedChart
      )?.chartValues;

      setCurrentChartData(newChartData || null);
    }
  };

  useEffect(() => {
    onSelectedChartChange();
  }, [selectedChart]);

  return (
    <div className="flex flex-col flex-1 h-[80%] px-4">
      <div className="mb-8 ml-1">
        <img src={Images.FullforkLogo} alt="Logo" width={179} height={40} />
      </div>

      <div className="flex h-full">
        {match({ isLoading, isOverviewAvailable: !!overview })
          .with({ isLoading: true }, () => <Loader centered withTopIndent />)
          .with({ isOverviewAvailable: true }, () => (
            <>
              <div className="flex h-fit flex-col w-[400px] bg-fullfork-bg rounded-lg mr-20">
                <div className="flex flex-col pt-5 pb-8 px-11 shadow-panel-shadow">
                  <div className="flex flex-row items-center mb-5">
                    <input
                      type="radio"
                      id="month-data"
                      className="h-[13px] w-[13px] accent-white bg-transparent mr-2"
                    />

                    <label
                      htmlFor="month-data"
                      className="font-bold text-white text-[15px] leading-[22.5px]"
                    >
                      Last 30 Days
                    </label>
                  </div>

                  <div className="">
                    <div className="grid h-full grid-cols-2 gap-y-6 gap-x-8">
                      {overview?.dataElements &&
                        overview.dataElements.map((el, index) => (
                          <Indicator
                            key={index}
                            label={el.title as string}
                            counter={el.elementValue as number}
                            rising={el.improvement}
                            hideArrow={el.improvement === null}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col grow ">
                <div className="flex flex-row items-center mb-5">
                  <input
                    type="radio"
                    id="filter-data"
                    className="h-[13px] w-[13px] accent-white bg-transparent mr-2"
                  />

                  <label
                    htmlFor="filter-data"
                    className="text-[21px] leading-[31.5px]"
                  >
                    Last 12 Months
                  </label>
                </div>

                {overview?.chartElements && (
                  <div className="flex flex-col max-h-[500px] flex-1 bg-white border border-lightgrey rounded-[10px] shadow-panel-shadow py-8 pl-4 pr-14">
                    <div className="mb-5 w-fit ml-9">
                      <Select
                        type="filter"
                        options={chartOptions}
                        value={selectedChart}
                        onChange={handleSelectChange}
                      />
                    </div>

                    <div className="flex-1">
                      <BarChart
                        data={currentChartData || []}
                        xDataKey="valueLabel"
                        yDataKey="elementValue"
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          ))
          .otherwise(() => null)}
      </div>
    </div>
  );
};
