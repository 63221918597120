import { z } from 'zod';

const reportSchedulerFields = z.object({
  name: z.string({ required_error: 'Name required' }),
  startDate: z.date({ required_error: 'Date required' }),
  startTime: z.any({ required_error: 'Time required' }),
  frequency: z.string({ required_error: 'Required' }),
  timezone: z.number({ required_error: 'Timezone Required' }),
  mapping: z.string().array().nonempty(),
  from: z.string().array().nonempty().max(1),
  to: z.string().array().nonempty(),
  cc: z.string().array(),
  bcc: z.string().array(),
  subject: z.string({ required_error: 'Subject required' }),
  message: z.string().optional(),
});

const ReportSchedulerSchema = reportSchedulerFields;

export type ReportSchedulerValues = z.infer<typeof ReportSchedulerSchema>;

export { ReportSchedulerSchema };
