import { CustomContentProps, SnackbarContent } from 'notistack';
import React, { forwardRef } from 'react';
import { match } from 'ts-pattern';
import { Icons } from '@utils';
import classNames from 'classnames';

import { Icon } from '../Icon';

interface ReportCompleteProps extends CustomContentProps {}

export const Snackbar = forwardRef<HTMLDivElement, ReportCompleteProps>(
  ({ ...props }, ref) => {
    return (
      <SnackbarContent
        className={classNames(
          'px-4 !h-14 rounded-md flex justify-start items-center text-white',
          {
            'bg-danger': props.variant === 'error',
            'bg-fullfork': props.variant === 'info',
          }
        )}
        ref={ref}
      >
        {match(props.variant)
          .with('error', () => (
            <div className="flex items-center justify-center w-6 h-6 mr-4 border border-white rounded-full">
              <Icon icon={Icons.Close} size="20px" />
            </div>
          ))
          .with('info', () => (
            <div className="flex items-center justify-center w-6 h-6 mr-4 border border-white rounded-full">
              <Icon icon={Icons.InfoSymbol} size="16px" />
            </div>
          ))
          .otherwise(() => null)}
        <span className="">{props.message}</span>
      </SnackbarContent>
    );
  }
);
