import { Icon, LoaderContainer, SearchInput, Select, Table, Text } from '@ui';
import { Icons } from '@utils';
import React, { FC, useMemo, useState } from 'react';
import { User, userFieldsOptions, users } from './config';
import { createColumnHelper, noop } from '@tanstack/react-table';
import { Pagination } from '@features';
import { EditUserModal } from './EditUserModal';

export const Users: FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [userId, setUserId] = useState<number>(0);

  const columnHelper = createColumnHelper<User>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        header: 'User Name',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.role, {
        header: 'Role',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.email, {
        header: 'Email Address',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.phone, {
        header: 'Phone Number',
        cell: (cell) => cell.getValue() || '-',
      }),
    ],
    []
  );

  const onEdit = (id: number) => {
    setUserId(id);
    setModalOpen(true);
  };

  return (
    <section className="h-full pt-11">
      <div className="flex flex-col justify-start h-full">
        <div className="flex items-center justify-between">
          <Text variant="h4">List of Users</Text>

          <button
            type="button"
            onClick={() => setModalOpen(true)}
            className="flex items-center justify-start hover:opacity-80 active:opacity-100"
          >
            <Text variant="h4">Add</Text>
            <Icon icon={Icons.Close} className="ml-2 rotate-45" size={19} />
          </button>
        </div>

        <div className="flex items-center w-full py-5 px-4 mt-[10px] border border-[#DCDCDC] rounded-md mb-4">
          <div className="mr-[10px]">
            <Select
              value="name"
              options={userFieldsOptions}
              onChange={noop}
              withoutBorder
            />
          </div>

          <div className="grow">
            <SearchInput placeholder="Search" />
          </div>
        </div>

        <LoaderContainer loading={false}>
          <div className="overflow-auto custom-scroll max-h-[350px] h-auto">
            {users?.length ? (
              <Table
                className="!m-0"
                withEdit
                withStatusSwitch={false}
                withControlPanel={false}
                data={users || []}
                columns={columns}
                uniqueKey="name"
                statusKey="name"
                isError={false}
                onEdit={onEdit}
                onDelete={noop}
                onToggle={noop}
              />
            ) : (
              <div className="flex items-center justify-center w-full pt-10 pb-14">
                There is no data
              </div>
            )}
          </div>
        </LoaderContainer>
      </div>

      <Pagination
        className="mx-auto"
        page={currentPage}
        setPage={setCurrentPage}
        pageCount={3}
      />

      <EditUserModal
        userId={userId}
        withTitle
        isModalOpen={isModalOpen}
        onModalClose={() => setModalOpen(false)}
        title="Edit User Details"
      />
    </section>
  );
};
