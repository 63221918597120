import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  DatePicker,
  FileInput,
  Icon,
  Input,
  LoaderContainer,
  Modal,
  ModalProps,
  Select,
  Switch,
  Textarea,
  TimePicker,
} from '@ui';
import {
  Colors,
  Icons,
  MOCKED_ENTERPRISE_ID,
  getHoursFromTime,
  pluralize,
} from '@utils';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import {
  Offer,
  useCreateOfferMutation,
  useEditOfferMutation,
  useGetOfferByIdQuery,
  useUploadFileMutation,
} from '@api';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useToast } from '@hooks';

import {
  AddOfferSchema,
  AddOfferValues,
  adaptEditOffer,
  adaptAddOffer,
} from './helpers';

import { Time } from '@features/DayToggler/types';
import { Expiration, OfferPreview } from './OfferPreview';
import {
  addDays,
  compareAsc,
  differenceInDays,
  differenceInHours,
  getHours,
  set,
} from 'date-fns';
import Collapsible from 'react-collapsible';
import { weekDaysOptions } from '../../config';
import { select } from 'ts-pattern/dist/patterns';
import { reset } from 'numeral';
import { generateFinePrint } from './helpers/adapters';

type OfferModalProps = ModalProps & {
  offerId?: number | null;
  setOfferId: (id: number | null) => void;
};
const dayMapping: {
  [key: string]: number;
} = {
  Su: 1,
  M: 2,
  T: 3,
  W: 4,
  Th: 5,
  F: 6,
  Sa: 7,
};
function convertCronToDays(cron: string | undefined | null) {
  if (!cron || cron === undefined) {
    return [];
  }
  const cronDays = cron.split(' ')[5];
  if (cronDays === '*' || !cronDays) {
    return [];
  }
  const numberDays = cronDays?.split(',')?.map((day) => {
    return day;
  });

  const daysArray = numberDays.map((day) => {
    return Object.keys(dayMapping).find(
      (key) => dayMapping[key] === Number(day)
    ) as string;
  });
  return daysArray ?? [];
}
export const OfferModal: React.FC<OfferModalProps> = ({
  offerId,
  isModalOpen,
  setOfferId,
  onModalClose,
}) => {
  const { showErrorToast } = useToast();

  const [currentOffer, setCurrentOffer] = useState<Offer | null>(null);

  const [isPreviewVisible, setPreviewVisible] = useState(false);

  const [isAdvanceOptionsOpen, setAdvanceOptionsOpen] = useState(false);
  const [isOfferOptionsOpen, setOfferOptionsOpen] = useState(false);

  const [createOffer] = useCreateOfferMutation();
  const [editOffer] = useEditOfferMutation();
  const { data: offer, isFetching: isOfferLoading } = useGetOfferByIdQuery(
    offerId ?? skipToken
  );

  const [uploadFile] = useUploadFileMutation();

  const isEditMode = !!offerId;

  const onSubmit = async (
    values: AddOfferValues,
    { resetForm }: FormikHelpers<AddOfferValues>
  ) => {
    // console.log(values);
    try {
      let imageUrl;

      if (values.imageUrl && values?.imageUrl?.name) {
        imageUrl = await uploadFile(values.imageUrl).unwrap();
      } else {
        imageUrl = values.imageUrl;
      }

      if (offer && offerId) {
        await editOffer({
          id: offerId,
          offer: {
            ...adaptEditOffer(values, offer),
            imageUrl: imageUrl || null,
          },
        }).unwrap();
        // console.log('working', 'submit success', offer2);

        resetForm();
        onModalClose();

        return;
      }
      console.log(values);
      const data = await createOffer({
        id: MOCKED_ENTERPRISE_ID,
        offer: { ...adaptAddOffer(values), imageUrl: imageUrl || null },
      }).unwrap();
      console.log(data, adaptAddOffer(values));
      resetForm();
      onModalClose();
    } catch (error) {
      console.error('Error submitting new offer', error);
      showErrorToast();
    }
  };
  const formatValidStartTime = (raw_time: string) => {
    console.log('🚀 ~ formatValidStartTime ~ formatValidStartTime:', raw_time);
    const time = new Date(raw_time);
    console.log(time);
    const hours = time.getUTCHours();
    const minutes = time.getUTCMinutes();
    const formattedHours = hours > 12 ? hours - 12 : hours;

    return {
      selectedHour: `${formattedHours}:00`,
      isAMSelected: hours < 12,
    };
  };
  const form = useFormik<AddOfferValues>({
    initialValues: useMemo(
      () => ({
        title: (!isOfferLoading && currentOffer?.title) || '',
        offerCode: (!isOfferLoading && currentOffer?.offerCode) || '',
        description: (!isOfferLoading && currentOffer?.description) || '',
        validFrom:
          !isOfferLoading && currentOffer?.validFrom
            ? new Date(currentOffer?.validFrom)
            : new Date(),
        validUntil:
          !isOfferLoading && currentOffer?.validUntil
            ? new Date(currentOffer.validUntil)
            : null,

        imageUrl: (!isOfferLoading && currentOffer?.imageUrl) || '',
        enabled: true,
        noExpiration: (!isOfferLoading && !currentOffer?.validUntil) ?? true,
        selectDays: !isOfferLoading
          ? convertCronToDays(currentOffer?.cron_Validity)
          : [],
        // selectDays: [],
        validTime: {
          isAMSelected:
            !isOfferLoading &&
            !!currentOffer?.restrictions?.validStartTime1 &&
            formatValidStartTime(currentOffer.restrictions.validStartTime1)
              .isAMSelected,
          selectedHour:
            (!isOfferLoading &&
              !!currentOffer?.restrictions?.validStartTime1 &&
              formatValidStartTime(currentOffer.restrictions.validStartTime1)
                .selectedHour) ||
            '08:00',
        },
        enableNoTimeRestriction:
          (!isOfferLoading &&
            !currentOffer?.restrictions?.validTimeDuration1) ??
          true,
        forHours:
          (!isOfferLoading &&
            !!currentOffer?.restrictions?.validTimeDuration1 &&
            Number(
              currentOffer?.restrictions?.validTimeDuration1?.split(':')[0]
            )) ||
          14,
        eligibleUseCount:
          (!isOfferLoading && currentOffer?.restrictions?.eligibleUseCount) ||
          0,
        staffGrantable:
          (!isOfferLoading && currentOffer?.restrictions?.staffGrantable) ||
          false,
        isStanding:
          (!isOfferLoading && currentOffer?.restrictions?.isStanding) || false,
        dontSendExpireReminder:
          (!isOfferLoading &&
            currentOffer?.restrictions?.dontSendExpireReminder) ??
          true,
        isSendWebLinks: false,
        couponRedeemLimit: 0,
        slackTime: 0,
        expirationReminders: 0,
        availability: 0,
        cron_Validity: (!isOfferLoading && currentOffer?.cron_Validity) || null,
        everydayEnabled:
          (!isOfferLoading &&
            convertCronToDays(currentOffer?.cron_Validity).length === 0) ??
          true,
        finePrint: (!isOfferLoading && currentOffer?.finePrint) || '',
      }),

      [currentOffer]
    ),
    enableReinitialize: true,
    validationSchema: toFormikValidationSchema(AddOfferSchema),
    onSubmit,
  });

  const {
    values,
    isSubmitting,
    isValid,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = form;

  const onSwitchActive = () => {
    setFieldValue('enabled', !values.enabled);
  };
  const onSwitchOfferType = () => {
    setFieldValue('everydayEnabled', !values.everydayEnabled);
  };
  useEffect(() => {
    if (values.everydayEnabled) {
      setFieldValue('selectDays', []);
      onSelectDaysChange([]);
    }
  }, [values.everydayEnabled]);
  useEffect(() => {
    console.log(values);
  }, [values]);
  useEffect(() => {
    if (!offerId) {
      setCurrentOffer(null);
    }

    if (offer && offerId) {
      setCurrentOffer(offer);

      const selectedDays = convertCronToDays(offer.cron_Validity);
      setFieldValue('selectDays', selectedDays);
      setFieldValue('cron_Validity', offer.cron_Validity);
      setFieldValue('everydayEnabled', selectedDays.length === 0);
    }

    return () => {
      setCurrentOffer(null);
      resetForm();
    };
  }, [offer, offerId]);

  const onSelectDaysChange = (selectedDays: string[]) => {
    setFieldValue('selectDays', selectedDays);
    console.log(selectedDays);

    const cronDays = selectedDays?.map((day) => dayMapping[day]).join(',');
    const cronExpression = `0 0 * * ? ${cronDays} *`;

    // Set the value of the 'cronDays' field
    setFieldValue('cron_Validity', cronExpression);
    console.log('cronDays', cronExpression);
  };
  const onChangeValidTime = (time: Time) => {
    setFieldValue('validTime', time);
  };

  const onClose = () => {
    setOfferId(null);
    setCurrentOffer(null);
    resetForm();
    onModalClose();
  };

  const expiration: Expiration = useMemo(() => {
    const today = new Date();
    if (!values.validUntil) {
      return {
        value: 0,
        text: 'No expiration',
        expired: false,
      };
    }

    const expiresOn = new Date(
      new Date(values.validUntil).setHours(
        getHoursFromTime(values.validTime) + values.forHours
      )
    );

    const dayDifference = differenceInDays(expiresOn, today);

    if (dayDifference) {
      return {
        value: dayDifference,
        text: `${pluralize(dayDifference, 'day')} to go`,
        expired: dayDifference < 0,
      };
    }

    const hoursDifference = differenceInHours(expiresOn, today);

    return {
      value: hoursDifference,
      text: `${pluralize(hoursDifference, 'hour')} to go`,
      expired: hoursDifference < 0,
    };
  }, [values.validUntil, values.validTime, values.forHours]);

  const forHoursLimit = useMemo(() => {
    const hoursInDay = 24;

    return hoursInDay - getHoursFromTime(values.validTime);
  }, [values.validTime]);

  const onChangeValidFrom = (date: Date) => {
    //
    setFieldValue('validFrom', date);

    // if set to no expiration we don't need to change the valid until date
    if (!values.validUntil) return;

    if (compareAsc(date, values.validUntil) === 1) {
      const newExpireDate = addDays(date, 7);
      setFieldValue('validUntil', newExpireDate);
    }
  };

  const onChangeValidUntil = (date: Date) => {
    setFieldValue('validUntil', date || new Date());
  };

  const siblingIcon = (onToggle: () => void) => (
    <button
      type="button"
      onClick={onToggle}
      className="-rotate-90  chevron !top-0"
    >
      <Icon icon={Icons.ArrowLeft} color={Colors.Text} className="" />
    </button>
  );

  const onToggleAdvanceOptions = () =>
    setAdvanceOptionsOpen(!isAdvanceOptionsOpen);

  const onToggleOfferOptions = () => setOfferOptionsOpen(!isOfferOptionsOpen);

  useEffect(() => {
    if (values.noExpiration) {
      setFieldValue('validUntil', null);
    }
  }, [values.noExpiration]);

  useEffect(() => {
    if (!values.enableNoTimeRestriction || values.selectDays) {
      //  setFieldValue('validUntil', null);
      const finePrint = generateFinePrint(
        values.selectDays,
        values.validTime,
        values.forHours,
        values.enableNoTimeRestriction
      );

      setFieldValue('finePrint', finePrint);
    }
  }, [
    values.selectDays,
    values.validTime,
    values.forHours,
    values.enableNoTimeRestriction,
  ]);

  return (
    <FormikProvider value={form}>
      <Modal isModalOpen={isModalOpen} onModalClose={onClose}>
        <LoaderContainer loading={isOfferLoading}>
          <div className="w-[750px]">
            <span className="font-bold text-[29px] leading-[43.5px]">
              {isEditMode ? 'Edit Store Offer' : 'Add Store Offers'}
            </span>
            <p>
              {isEditMode
                ? 'Please edit store offer for specific locations.'
                : 'Please add store offers for specific locations.'}
            </p>
            <div className="flex mt-0 mb-4">
              <div className="w-[240px] flex flex-col justify-between mr-[30px] gap-y-2">
                <div className="mb-1">
                  <Input<AddOfferValues>
                    placeholder="Title e.g. $2.00 off"
                    fieldName="title"
                    maxLength={30}
                  />
                </div>

                <div className="mb-0">
                  <Input<AddOfferValues>
                    placeholder="Offer code e.g. $2OFF"
                    fieldName="offerCode"
                  />
                </div>

                <div className="mb-0">
                  <FileInput<AddOfferValues>
                    placeholder="image.jpg"
                    fieldName="imageUrl"
                    withPreview
                  />
                </div>

                <Textarea<AddOfferValues>
                  placeholder="Description"
                  // limit={150}
                  fieldName="description"
                />
                <div className="flex items-center gap-2 justify-between pl-[10px]">
                  <span
                    className="text-[13px] leading-[20px] text-darkgrey "
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {values.enabled ? 'Active' : 'Inactive'}
                  </span>
                  <div className="">
                    <Switch
                      checked={values.enabled}
                      onCheckedChange={onSwitchActive}
                    />
                  </div>
                </div>
              </div>

              <div className="w-[335px] flex flex-col">
                <div className="flex items-center">
                  <div className="mx-[14px] space-x-4">
                    <span className="text-[13px] leading-[20px] text-darkgrey">
                      Everyday
                    </span>
                    <Switch
                      checked={!values.everydayEnabled}
                      onCheckedChange={onSwitchOfferType}
                    />
                  </div>
                  <span className="text-[13px] leading-[20px] text-darkgrey">
                    Specific Days
                  </span>
                </div>

                <div className="mt-0">
                  <Select
                    closeMenuOnSelect={false}
                    value={values.selectDays}
                    type="multi"
                    placeholder="Select Days"
                    options={weekDaysOptions}
                    onChange={onSelectDaysChange}
                    disabled={values.everydayEnabled}
                  />
                </div>
                <div className="flex flex-col my-4">
                  <div className="flex items-center gap-2">
                    <div className="">
                      <Input<AddOfferValues>
                        type="checkbox"
                        fieldName="noExpiration"
                        checked={!values.validUntil}
                      />
                    </div>
                    <span className="text-[13px] leading-[20px] text-darkgrey w-max">
                      No Expiration
                    </span>
                  </div>
                  <div className="flex ">
                    <div className="mr-5">
                      <DatePicker
                        value={values.validFrom}
                        label="Valid from"
                        onChange={onChangeValidFrom}
                        disableDaysBefore={new Date()}
                      />
                    </div>
                    {values.noExpiration ? null : (
                      <DatePicker
                        value={
                          values.validUntil ?? addDays(values.validFrom, 7)
                        }
                        label="Valid until"
                        onChange={onChangeValidUntil}
                        disableDaysBefore={values.validFrom}
                      />
                    )}
                  </div>
                </div>

                <div className="flex  flex-col justify-center ">
                  <div className="flex items-center gap-2">
                    <div className="">
                      <Input<AddOfferValues>
                        type="checkbox"
                        fieldName="enableNoTimeRestriction"
                        checked={values.enableNoTimeRestriction}
                      />
                    </div>
                    <span className="text-[13px] leading-[20px] text-darkgrey w-max">
                      Valid All Day
                    </span>
                  </div>
                  {values.enableNoTimeRestriction ? null : (
                    <>
                      <div className="flex items-center">
                        <div className="mr-5">
                          <TimePicker
                            label="Valid Time"
                            value={values.validTime}
                            onChange={onChangeValidTime}
                          />
                        </div>

                        <div className="w-[40px] mr-[10px]">
                          <Input<AddOfferValues>
                            label="For"
                            placeholder="6"
                            type="number"
                            min={0}
                            max={23}
                            fieldName="forHours"
                          />
                        </div>
                      </div>

                      <span className="text-[13px] leading-[20px] text-darkgrey mt-0">
                        Hours
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* eslint-disable  */}
            {/* <div className="mt-0">
            es-lint disable 
              <Collapsible
                trigger={
                  <div className="w-full font-bold">Advance Options</div>
                }
                open={isAdvanceOptionsOpen}
                transitionTime={100}
                triggerSibling={siblingIcon(onToggleAdvanceOptions)}
                handleTriggerClick={onToggleAdvanceOptions}
              >
<div className="flex justify-between items-center py-[10px] px-[15px] bg-[#F8F8F8] rounded-[10px] mt-[0px]">
                  <div className="flex items-center">
                    <span className="text-[13px] leading-[20px] mr-[10px]">
                      Eligible Use:
                    </span>

                    <div className="w-[40px]">
                      <Input<AddOfferValues>
                        placeholder="0"
                        type="number"
                        min={0}
                        max={100}
                        fieldName="eligibleUseCount"
                      />
                    </div>
                  </div>

                  <div className="flex items-center">
                    <span className="text-[13px] leading-[20px] mr-[10px]">
                      Grantable By Staff:
                    </span>

                    <Input<AddOfferValues>
                      type="checkbox"
                      fieldName="staffGrantable"
                      checked={values.staffGrantable}
                    />
                  </div>

                  <div className="flex items-center">
                    <span className="text-[13px] leading-[20px] mr-[10px]">
                      Standing Offer:
                    </span>

                    <Input<AddOfferValues>
                      type="checkbox"
                      fieldName="isStanding"
                      checked={values.isStanding}
                    />
                  </div>

                  <div className="flex items-center">
                    <span className="text-[13px] leading-[20px] mr-[10px]">
                      Send Expiration Reminder:
                    </span>

                    <Input<AddOfferValues>
                      type="checkbox"
                      fieldName="dontSendExpireReminder"
                      checked={!values.dontSendExpireReminder}
                      onChange={(e) => {
                        setFieldValue(
                          'dontSendExpireReminder',
                          !e.target.checked
                        );
                      }}
                    />
                  </div>
                </div>
              </Collapsible>
            </div>

            <div className="mt-0">
              <Collapsible
                open={isOfferOptionsOpen}
                trigger={<div className="w-full font-bold">Offer Options</div>}
                transitionTime={100}
                triggerSibling={siblingIcon(onToggleOfferOptions)}
                handleTriggerClick={onToggleOfferOptions}
              >
 <div className="flex justify-between items-center py-[10px] px-[15px] bg-[#F8F8F8] rounded-[10px]">
                  <div className="w-[150px] flex items-center">
                    <span className="w-[110px] text-[13px] leading-[20px] mr-[10px]">
                      Send Web Links With Offers:
                    </span>

                    <Input<AddOfferValues>
                      type="checkbox"
                      fieldName="isSendWebLinks"
                      checked={values.isSendWebLinks}
                    />
                  </div>

                  <div className="w-[170px] flex items-center">
                    <span className="w-[120px] text-[13px] leading-[20px] mr-[10px]">
                      Max Offer Redemptions per guest per day:
                    </span>

                    <div className="w-[40px]">
                      <Input<AddOfferValues>
                        placeholder="0"
                        type="number"
                        min={0}
                        max={50}
                        fieldName="couponRedeemLimit"
                      />
                    </div>
                  </div>

                  <div className="w-[150px] flex items-center">
                    <span className="w-[100px] text-[13px] leading-[20px] mr-[10px]">
                      Slack Time For Time Banded Offers:
                    </span>

                    <div className="w-[40px]">
                      <Input<AddOfferValues>
                        placeholder="0"
                        type="number"
                        min={0}
                        max={50}
                        fieldName="slackTime"
                      />
                    </div>
                  </div>

                  <div className="w-[150px] flex items-center">
                    <span className="w-[90px] text-[13px] leading-[20px] mx-[10px]">
                      Expiration Reminders:
                    </span>

                    <div className="w-[40px]">
                      <Input<AddOfferValues>
                        placeholder="0"
                        type="number"
                        min={0}
                        max={50}
                        fieldName="expirationReminders"
                      />
                    </div>
                  </div>
                </div>
              </Collapsible>
            </div> */}
            <div className="flex mt-0">
              <Button
                label="Save Offer"
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={!isValid}
                className="w-1/2 mr-1"
              />
              <Button
                // className="float-right"
                label="Offer Preview"
                onClick={() => setPreviewVisible(true)}
                loading={isSubmitting}
                disabled={!isValid}
                className="w-1/2 ml-1"
              />
            </div>
          </div>

          <OfferPreview
            isOpen={isPreviewVisible}
            onClose={() => setPreviewVisible(false)}
            image={values.imageUrl}
            title={values.title}
            description={values.description}
            expiration={expiration}
            selectedDays={values.everydayEnabled ? [] : values.selectDays}
            finePrint={values.finePrint ?? ''}
          />
        </LoaderContainer>
      </Modal>
    </FormikProvider>
  );
};
