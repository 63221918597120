import { z } from 'zod';

const socialLinkFields = z.object({
  name: z.string({ required_error: 'Required' }),
  link: z.string({ required_error: 'Required' }),
  icon: z.any().optional(),
});

const SocialLinkSchema = socialLinkFields;

export type SocialLinksValues = z.infer<typeof SocialLinkSchema>;

export { SocialLinkSchema };
