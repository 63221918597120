import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import {
  SocialLinkSchema,
  SocialLinksValues,
} from '../../helpers/socialLinkValidation';
import { FileInputContainer, Icon, Input } from '@ui';
import { Icons } from '@utils';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { SocialLink } from '../../config';

interface AddSocialLinkProps {
  onAdd: (value: SocialLink) => void;
}

export const AddSocialLink: FC<AddSocialLinkProps> = ({ onAdd }) => {
  const form = useFormik<SocialLinksValues>({
    initialValues: {
      name: '',
      link: '',
      icon: null,
    },
    validationSchema: toFormikValidationSchema(SocialLinkSchema),
    onSubmit: (values, { resetForm }) => {
      onAdd(values);
      resetForm();
    },
  });

  const { isValid, handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <div className="flex justify-between items-center gap-x-[13px] rounded-[10px] border border-borderColor p-[25px]">
        <button
          disabled={!isValid}
          type="button"
          onClick={() => handleSubmit()}
          className="h-full disabled:opacity-70 hover:opacity-70 active:opacity-100"
        >
          <Icon icon={Icons.Close} className="mt-5 rotate-45" size={24} />
        </button>

        <Input<SocialLinksValues> fieldName="name" label="Name" />

        <Input<SocialLinksValues> fieldName="link" label="Link" />

        <div>
          <span className="text-[11px] text-darkgrey mb-[7px]">Icon</span>
          <FileInputContainer<SocialLinksValues>
            fieldName="icon"
            className="flex justify-center items-center border border-borderColor rounded-[5px] w-10 h-10 mb-1"
          >
            <Icon icon={Icons.Upload} size={24} className="mt-[5px]" />
          </FileInputContainer>
        </div>
      </div>
    </FormikProvider>
  );
};
