export const css = `
.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  color: white;
  opacity: 1;
  background-color: #5a315d !important;
  border-radius: 5px !important;
}

.rdp-head_cell {
  font-size: 11px;
  text-transform: capitalize;
}
`;
