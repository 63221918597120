import React from 'react';
import {
  LineChart as Chart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
} from 'recharts';

import { CustomXAxisTick, CustomYAxisTick, CustomTooltip } from '../Custom';
import './style.css';

export interface LineChartProps<ChartElement> {
  data: ChartElement[];
  xDataKey: keyof ChartElement;
  yDataKey: keyof ChartElement;
  height?: number;
}

export const LineChart = <ChartElement extends object>({
  data,
  xDataKey,
  yDataKey,
  height,
}: LineChartProps<ChartElement>) => {
  return (
    <ResponsiveContainer width="100%" height={height || '100%'}>
      <Chart data={data}>
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey={xDataKey as string} tick={<CustomXAxisTick />} />
        <YAxis tick={<CustomYAxisTick />} />

        <Tooltip
          content={<CustomTooltip />}
          cursor={{ fill: 'transparent' }}
          animationDuration={300}
        />

        <Line
          type="monotone"
          dot={false}
          stroke="#EA8516"
          strokeWidth={3}
          dataKey={yDataKey as string}
        />
      </Chart>
    </ResponsiveContainer>
  );
};
