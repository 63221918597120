import { SelectOption } from '@ui';
import { Location, WifiSettings } from '@api';
import { Icons, formatBitsToMbps, pluralize } from '@utils';
import { emptyDashboardCards } from '../../config';

export const adaptAddressOptions = (locations: Location[]): SelectOption[] =>
  locations.map((location) => ({
    label: location?.jsonStreetAddress?.length
      ? location?.jsonStreetAddress?.[0]?.street
      : 'No address',
    value: location.id || null,
  }));

const HOUR = 60;

export const adaptDashboardCards = (settings: WifiSettings | null) => {
  if (!settings) {
    return emptyDashboardCards;
  }

  const durationInHours = (+settings.sessionDuration || 0) / HOUR;

  return [
    {
      value: 0,
      suffix: 'Users',
      label: 'Users Connected',
      icon: Icons.Users,
    },
    {
      value: settings?.downloadLimit || 0,
      suffix: 'Mb',
      label: 'Download Limit',
      icon: Icons.Users,
    },
    {
      value: formatBitsToMbps(+settings.bandwidth),
      suffix: 'Mbps',
      label: 'Bandwidth',
      icon: Icons.Users,
    },
    {
      value: durationInHours,
      suffix: pluralize(durationInHours, 'hour'),
      label: 'Session',
      icon: Icons.Users,
    },
  ];
};
