import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../baseQuery';
import { HTTPMethod } from '../config';

import { Location } from './models';
import {
  CreateLocationRequest,
  EditLocationRequest,
  GetLocationsRequest,
} from './config';

export const FETCH_LOCATIONS_LIMIT = 10;

export const locationApi = createApi({
  baseQuery,
  reducerPath: 'locationApi',
  tagTypes: ['Location', 'LocationCount'],
  endpoints: (builder) => ({
    getAllLocations: builder.query<Location[], number>({
      query: (id) => ({
        url: `/Enterprise/${id}/Location`,
        method: HTTPMethod.GET,
      }),
      providesTags: ['Location'],
    }),
    getLocations: builder.query<Location[], GetLocationsRequest>({
      query: ({ id, pageNum }) => ({
        url: `/Enterprise/${id}/Location/Paging`,
        params: { pageNum, fetchLimit: FETCH_LOCATIONS_LIMIT },
        method: HTTPMethod.GET,
      }),
      providesTags: ['Location'],
    }),
    getLocationCount: builder.query<number, number>({
      query: (id) => ({
        url: `/Enterprise/${id}/LocationCount`,
        method: HTTPMethod.GET,
      }),
      providesTags: ['LocationCount'],
    }),
    getLocationById: builder.query<Location, number>({
      query: (id) => ({
        url: `/Location/${id}`,
        method: HTTPMethod.GET,
      }),
      providesTags: ['Location'],
    }),
    createLocation: builder.mutation<void, CreateLocationRequest>({
      query: ({ id, location }) => ({
        url: `/Enterprise/${id}/Location`,
        body: location,
        method: HTTPMethod.POST,
      }),
      invalidatesTags: ['Location', 'LocationCount'],
    }),
    editLocation: builder.mutation<void, EditLocationRequest>({
      query: ({ id, location }) => ({
        url: `/Location/${id}`,
        body: location,
        method: HTTPMethod.PUT,
      }),
      invalidatesTags: ['Location'],
    }),
    toggleLocationState: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `/Location/${id}/toggleState`,
        method: HTTPMethod.GET,
      }),
      invalidatesTags: ['Location'],
    }),
    deleteLocation: builder.mutation<void, number>({
      query: (id) => ({
        url: `/Location/${id}`,
        method: HTTPMethod.DELETE,
      }),
      invalidatesTags: ['Location', 'LocationCount'],
    }),
  }),
});

export const {
  useGetAllLocationsQuery,
  useGetLocationsQuery,
  useGetLocationCountQuery,
  useGetLocationByIdQuery,
  useCreateLocationMutation,
  useEditLocationMutation,
  useDeleteLocationMutation,
  useToggleLocationStateMutation,
} = locationApi;
