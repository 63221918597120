/* eslint-disable react/no-unstable-nested-components */

import { SearchInput, Select, SubscriptionLabel, Table, Text } from '@ui';
import React, { FC, useMemo, useState } from 'react';
import {
  LocationBreakdownTableRow,
  locationBreakdownTableData,
  locationOptions,
} from '../config';
import { createColumnHelper } from '@tanstack/react-table';
import { formatNumber } from '@utils';
import { createPortal } from 'react-dom';

export const LocationBreakdown: FC = () => {
  const [searchBy, setSearchBy] = useState('title');
  const [searchValue, setSearchValue] = useState('');

  const columnHelper = createColumnHelper<LocationBreakdownTableRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        header: 'Location Name',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.subscribedApps, {
        header: '[Price]Subscribed Apps',
        cell: (cell) => (
          <div className="flex flex-wrap gap-x-[5px]">
            {cell.getValue().map(({ name, price }) => (
              <SubscriptionLabel key={name} variant={name} price={price} />
            ))}
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.total, {
        header: 'Total',
        cell: (cell) =>
          cell.getValue() ? (
            `$${formatNumber(cell.getValue())}`
          ) : (
            <span>
              <b>-</b>
            </span>
          ),
      }),
    ],
    []
  );

  const pageElem = document.querySelector('#page-container');

  return (
    <>
      <div className="flex items-center w-full py-5 border px-4 mb-4 border-[#DCDCDC] rounded-md">
        <div className=" mr-[10px]">
          <Select
            value={searchBy}
            options={locationOptions}
            onChange={setSearchBy}
            withoutBorder
          />
        </div>

        <div className="grow">
          <SearchInput
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      <div className="max-h-[240px] overflow-auto custom-scroll">
        <Table
          className="!m-0"
          data={locationBreakdownTableData || []}
          columns={columns}
        />
      </div>

      {createPortal(
        <section className="absolute flex justify-between items-center bottom-0 left-0 w-full h-[88px] bg-customerology px-5 py-[9px]">
          <div className="flex items-start justify-between gap-x-[73px]">
            <div>
              <Text variant="body2" className="!text-white !text-[13px]">
                Subscriptions Per Enterprise (Currently Subscribed Apps)
              </Text>

              <div className="flex flex-wrap gap-x-[5px] mt-[10px]">
                <SubscriptionLabel variant="zip-loyalty" price={99} />
                <SubscriptionLabel variant="fullfork" price={99} />
                <SubscriptionLabel variant="zip-marketing" price={99} />
              </div>
            </div>

            <div className="flex flex-col items-center justify-start gap-y-2">
              <Text variant="body2" className="!text-white !text-[13px]">
                Enterprise Cost
              </Text>
              <Text
                variant="body2"
                className="!text-white !text-[26px] !leading-10 !font-bold"
              >
                $297
              </Text>
            </div>
          </div>

          <div className="flex flex-col items-center justify-start gap-y-2 mr-[6%]">
            <Text variant="body2" className="!text-white !text-[13px]">
              Total Location Cost
            </Text>
            <Text
              variant="body2"
              className="!text-white !text-[26px] !leading-10 !font-bold"
            >
              $765
            </Text>
          </div>
        </section>,
        pageElem || document.body
      )}
    </>
  );
};
