export const Colors = {
  Customerology: '#2C679F',
  FullFork: '#1EA1F1',
  PlumWifi: '#5A315D',
  ZipLoyalty: '#D0A72A',
  ZipSurveillance: '#333333',
  ZipMarketing: '#009690',

  White: '#FFFFFF',
  Black: '#000000',

  Background: '#FBFBFB',
  BorderColor: '#E3E3E3',
  Text: '#333333',

  LightGrey: '#EFEFEF',
  DarkGrey: '#888888',

  Success: '#4ED753',
  Danger: '#C44040',
};
