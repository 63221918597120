import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import {
  Fullfork,
  Home,
  Root,
  Reports,
  LiftReport,
  LocationReport,
  RedemptionReport,
  ReportScheduler,
  PlumWifi,
  AppStore,
  Settings,
} from '@pages';

import { Routes } from './Routes';

export const router = createBrowserRouter([
  {
    path: Routes.ROOT,
    element: <Root />,
    children: [
      {
        path: Routes.HOME,
        element: <Home />,
      },
      {
        path: Routes.PRODUCTS,
        children: [
          {
            path: Routes.PLUM_WIFI,
            element: <PlumWifi />,
          },
          {
            path: Routes.FULLFORK,
            element: <Fullfork />,
          },
        ],
      },
      {
        path: Routes.REPORTING,
        children: [
          {
            index: true,
            element: <Reports />,
          },
          {
            path: Routes.LIFT_REPORT,
            element: <LiftReport />,
          },
          {
            path: Routes.LOCATION_REPORT,
            element: <LocationReport />,
          },
          {
            path: Routes.REDEMPTION_REPORT,
            element: <RedemptionReport />,
          },
          {
            path: Routes.REPORT_SCHEDULER,
            element: <ReportScheduler />,
          },
        ],
      },
      {
        path: Routes.APP_STORE,
        element: <AppStore />,
      },
      {
        path: Routes.SETTINGS,
        element: <Settings />,
      },
    ],
  },
]);
