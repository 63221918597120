import { useClickAway } from '@hooks';
import cn from 'classnames';
import { useField } from 'formik';
import React, { useRef, useState } from 'react';
import { Icon } from '../Icon';
import { Colors, Icons } from '@utils';

interface CheckboxSelectorProps<Form> {
  options: string[];
  fieldName: keyof Form;
  className?: string;
  label?: string;
}

export const CheckboxSelector = <Form extends object>({
  options,
  label,
  className,
  fieldName,
}: CheckboxSelectorProps<Form>) => {
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField<
    string[]
  >({
    name: fieldName as string,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setFocused] = useState(false);

  const dropdownRef = useClickAway(() => setFocused(false), [inputRef]);

  const onChange = (option: string) => {
    if (value.indexOf(option) === -1) {
      setValue([...value, option]);

      return;
    }

    const filteredValue = value.filter((el) => el !== option);
    setValue(filteredValue);
  };

  return (
    <div className={cn('relative w-min', className)}>
      {!!label && (
        <p className="block text-[11px] leading-[16px] text-darkgrey mb-[5px]">
          {label}
        </p>
      )}

      <Icon
        icon={Icons.CollapsibleArrow}
        size={9}
        className="absolute top-9 right-[13px]"
        color={Colors.DarkGrey}
      />

      <input
        ref={inputRef}
        name={fieldName as string}
        type="text"
        onClick={() => setFocused(!isFocused)}
        onBlur={onBlur}
        value={value.join(', ')}
        className={cn(
          'h-10 text-darkgrey border border-borderColor rounded-[5px] px-3 w-full focus:outline-none cursor-default',
          {
            '!border-danger': error && touched,
          }
        )}
      />

      {isFocused && (
        <ul
          ref={dropdownRef}
          className="absolute top-[73px] border border-borderColor rounded-[5px] bg-white w-full"
        >
          {options.map((option) => (
            <li key={option}>
              <label className="flex gap-x-[10px] items-center justify-start p-[10px] cursor-pointer hover:opacity-80">
                <input
                  type="checkbox"
                  onChange={() => onChange(option)}
                  checked={value.indexOf(option) !== -1}
                />
                <span className="text-[13px] leading-[19px]">{option}</span>
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
