import { SelectOption } from '@ui';
import { Routes } from '@navigation';
import { Icons } from '@utils';

interface ReportingItem {
  title: string;
  icon: string;
  description: string;
  route: string;
}

export const reportingItems: ReportingItem[] = [
  {
    title: 'Lift Report',
    description:
      'Get insights on restaurant performance across locations. Analyze customer satisfaction, revenue, and operations for informed decision-making.',
    icon: Icons.LiftReport,
    route: Routes.LIFT_REPORT,
  },
  {
    title: 'Location Report',
    description:
      'View key metrics like foot traffic, demographics, and sales trends for each restaurant location. Optimize marketing and expansion strategies.',
    icon: Icons.LocationReport,
    route: Routes.LOCATION_REPORT,
  },
  {
    title: 'Redemption Report',
    description:
      'Track loyalty program success and coupon redemptions across your restaurants. Enhance customer engagement and boost repeat business.',
    icon: Icons.RedemptionReport,
    route: Routes.REDEMPTION_REPORT,
  },
  {
    title: 'Report Schedule',
    description:
      'Automate report generation and delivery. Stay organized and keep stakeholders informed with scheduled reports.',
    icon: Icons.ReportSchedule,
    route: Routes.REPORT_SCHEDULER,
  },
];

export const dataDisplayedOptions: SelectOption[] = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
];

export const locationsOptions: SelectOption[] = [
  { label: 'Store Name', value: 'Store Name' },
  { label: 'Address', value: 'Address' },
  { label: 'Phone Number', value: 'Phone Number' },
  { label: 'Status', value: 'Status' },
];

export const redemptionTabs = ['Earned Rewards', 'Voucher Rewards'];

export interface LiftReportRow {
  name: string;
  locationId: number;
  storeNumber: number;
  totalReceipts: number;
  totalAmount: number;
  totalLoyaltyReceipts: number;
  totalAmountOfLoyalty: number;
  biggestAmount: number;
  biggestLoyality: number;
  participationRate: number;
  newGuest: number;
  percentOfNewGuest: number;
  returningGuest: number;
  percentOfReturningGuest: number;
  avgAnonymousCheck: number;
  avgLoyaltyCheck: number;
  lift: number;
}

export interface LocationReportRow {
  location: string;
  barCode: number;
  onlineOrders: number;
  spend: number;
  redemptions: number;
  uniqueRedeemers: number;
}

export interface RedemptionReportRow {
  title: string;
  description: string;
  honoredRedemption: number;
  honoredRedemptionPoints: number;
  honoredRedeemedAmount: number;
}

export interface ReportSchedulerRow {
  name: string;
  startDate: Date;
  timeZone: string;
  frequency: number;
  status: boolean;
}

export const liftReportTableData: LiftReportRow[] = [
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
  {
    name: 'Abilene, TX - 298',
    locationId: 32424,
    storeNumber: 342,
    totalReceipts: 1003,
    totalAmount: 22502.29,
    totalLoyaltyReceipts: 120,
    totalAmountOfLoyalty: 2451.93,
    biggestAmount: 131.81,
    biggestLoyality: 82.62,
    participationRate: 11.96,
    newGuest: 19,
    percentOfNewGuest: 17.92,
    returningGuest: 87,
    percentOfReturningGuest: 82.08,
    avgAnonymousCheck: 22.71,
    avgLoyaltyCheck: 20.43,
    lift: -10.04,
  },
];

export const locationReportTableData: LocationReportRow[] = [
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Austin, TX - 250',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Baton Rouge, LA - 184',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Baton Rouge, LA - 184',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Austin, TX - 250',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Baton Rouge, LA - 184',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Baton Rouge, LA - 184',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
  {
    location: 'Abilene, TX - 298',
    barCode: 55,
    onlineOrders: 64,
    spend: 2636.12,
    redemptions: 39,
    uniqueRedeemers: 39,
  },
];

export const redemptionReportTableData: RedemptionReportRow[] = [
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
  {
    title: 'Unlocked Reward: Free Regular Drink',
    description:
      'Good for (1) free regular drink. Not valid on large drinks, combo meals or bottled drinks. Limit one redemption per transaction and app account. Cannot be combined with any other offers or discounts.unlocked reward with spring 2023 app update',
    honoredRedemption: 154,
    honoredRedemptionPoints: 3850,
    honoredRedeemedAmount: 378.9,
  },
];

export const reportSchedulersTableData: ReportSchedulerRow[] = [
  {
    name: 'Weekly Reports',
    startDate: new Date(),
    timeZone: 'PST, UTC−08:00',
    frequency: 1,
    status: true,
  },
  {
    name: 'Weekly Reports',
    startDate: new Date(),
    timeZone: 'PST, UTC−08:00',
    frequency: 1,
    status: false,
  },
  {
    name: 'Weekly Reports',
    startDate: new Date(),
    timeZone: 'PST, UTC−08:00',
    frequency: 1,
    status: false,
  },
  {
    name: 'Weekly Reports',
    startDate: new Date(),
    timeZone: 'PST, UTC−08:00',
    frequency: 1,
    status: false,
  },
];
