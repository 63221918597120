import { z } from 'zod';

const addLocationFields = z.object({
  name: z.string({ required_error: 'Store required' }),
  street: z.string({ required_error: 'Street required' }),
  city: z.string({ required_error: 'City required' }),
  state: z.string({ required_error: 'State required' }).nullable(),
  zipcode: z.string({ required_error: 'Zip required' }),
  businessPhoneNumber: z.string({ required_error: 'Phone Number required' }),
  website: z.string({ required_error: 'Website required' }),
  enabled: z.boolean(),
  hours: z.any().array(),
});

const AddLocationSchema = addLocationFields;

export type AddLocationValues = z.infer<typeof AddLocationSchema>;

export { AddLocationSchema };
