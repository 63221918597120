import { SelectOption } from '@ui';

export const locationFieldsOptions: SelectOption[] = [
  { label: 'Location Name', value: 'name' },
  { label: 'Address', value: 'address' },
  { label: 'Phone Number', value: 'phone' },
  { label: 'Status', value: 'status' },
];

export const statusOptions: SelectOption[] = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export const locations = [
  {
    id: 3001001,
    enterprise_Id: 3001,
    category_Ids: null,
    enabled: true,
    name: "Famous Dave's Rancho Cucamonga",
    guid: 'fd65d3ff-c6e7-4c64-bd2b-092d18888bc8',
    imageUrl: null,
    assignedPhoneNumber: null,
    businessPhoneNumber: '+17144629179',
    businessFaxNumber: null,
    website: null,
    jsonStreetAddress: [
      {
        street: '11470 4th Street',
        city: 'Rancho Cucamonga',
        state: 'CA',
        zipcode: '91730',
        isDefault: null,
        addressType: 'Store Address',
      },
    ],
    latitude: 33.72954,
    longitude: -117.786688,
    timezone: 'US/Pacific',
    hours: null,
    place_Id: null,
    overall_Rating: null,
    jsonStoreHours: null,
  },
  {
    id: 3001002,
    enterprise_Id: 3001,
    category_Ids: null,
    enabled: true,
    name: "Famous Dave's Alameda",
    guid: '522ac44f-8e34-45cd-a6f7-cab8cfb202d5',
    imageUrl: null,
    assignedPhoneNumber: null,
    businessPhoneNumber: '+15107643786',
    businessFaxNumber: null,
    website: null,
    jsonStreetAddress: [
      {
        street: '2690 Fifth St. Suite A',
        city: 'Alameda',
        state: 'CA',
        zipcode: '94501',
        isDefault: null,
        addressType: 'Store Address',
      },
    ],
    latitude: 37.787331,
    longitude: -122.281181,
    timezone: 'US/Pacific',
    hours: null,
    place_Id: null,
    overall_Rating: null,
    jsonStoreHours: null,
  },
  {
    id: 3001003,
    enterprise_Id: 3001,
    category_Ids: null,
    enabled: true,
    name: "Famous Dave's Hayward",
    guid: 'fd49da9f-e27d-43be-9a7d-5717adb4d079',
    imageUrl: null,
    assignedPhoneNumber: null,
    businessPhoneNumber: '+15107822030',
    businessFaxNumber: null,
    website: null,
    jsonStreetAddress: [
      {
        street: '790 West Winton Avenue',
        city: 'Hayward',
        state: 'CA',
        zipcode: '94595',
        isDefault: null,
        addressType: 'Store Address',
      },
    ],
    latitude: 37.653915,
    longitude: -122.106667,
    timezone: 'US/Pacific',
    hours: null,
    place_Id: null,
    overall_Rating: null,
    jsonStoreHours: null,
  },
  {
    id: 3001004,
    enterprise_Id: 3001,
    category_Ids: null,
    enabled: true,
    name: "Famous Dave's San Jose",
    guid: '00638680-4db8-4cca-b276-99b1a2ed8c41',
    imageUrl: null,
    assignedPhoneNumber: null,
    businessPhoneNumber: '+14082927444',
    businessFaxNumber: null,
    website: null,
    jsonStreetAddress: [
      {
        street: '61 Curtner Avenue',
        city: 'San Jose',
        state: 'CA',
        zipcode: '95125',
        isDefault: null,
        addressType: 'Store Address',
      },
    ],
    latitude: 37.653915,
    longitude: -122.106667,
    timezone: 'US/Pacific',
    hours: null,
    place_Id: null,
    overall_Rating: null,
    jsonStoreHours: null,
  },
  {
    id: 3001005,
    enterprise_Id: 3001,
    category_Ids: null,
    enabled: true,
    name: "Famous Dave's Fresno",
    guid: 'e6994b45-a36e-4f0b-bea1-3213d032bf5a',
    imageUrl: null,
    assignedPhoneNumber: null,
    businessPhoneNumber: '+15592614227',
    businessFaxNumber: null,
    website: null,
    jsonStreetAddress: [
      {
        street: '7603 North Blackstone Avenue',
        city: 'Fresno',
        state: 'CA',
        zipcode: '93720',
        isDefault: null,
        addressType: 'Store Address',
      },
    ],
    latitude: 36.846519,
    longitude: -119.791115,
    timezone: 'US/Pacific',
    hours: null,
    place_Id: null,
    overall_Rating: null,
    jsonStoreHours: null,
  },
];
