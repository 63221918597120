import { Text } from '@ui';
import React, { FC } from 'react';

interface AccordionHeaderProps {
  title: string;
}

export const AccordionHeader: FC<AccordionHeaderProps> = ({ title }) => {
  return (
    <button
      type="button"
      className="bg-plumwifi rounded-[10px] py-5 pl-[30px] text-white w-full whitespace-nowrap pr-[106px]"
    >
      <Text variant="h5" className="!font-bold text-left">
        {title}
      </Text>
    </button>
  );
};
