import { Input, Select, Text } from '@ui';
import React, { FC } from 'react';
import { EnterpriseValues } from '../../helpers/enterpriseValidation';
import { useFormikContext } from 'formik';
import { categoriesOptions } from '../../config';

interface EnterpriseFormProps {
  className?: string;
}

export const EnterpriseForm: FC<EnterpriseFormProps> = ({ className }) => {
  const { values, setFieldValue } = useFormikContext<EnterpriseValues>();

  return (
    <div className={className}>
      <div className="flex flex-col gap-y-2 ">
        <Text variant="body2">General Details</Text>

        <div className="border border-borderColor rounded-[10px] p-[25px] grid grid-cols-2 grid-rows-2 gap-x-[13px] gap-y-[15px]">
          <Input<EnterpriseValues>
            fieldName="firstName"
            label="Account User First Name"
            inputClassName="mt-[2px] mb-[1px]"
          />

          <Input<EnterpriseValues>
            fieldName="lastName"
            label="Account User Last Name"
            inputClassName="mt-[2px] mb-[1px]"
          />

          <Input<EnterpriseValues>
            fieldName="userEmail"
            label="Account User Email"
            inputClassName="mt-[2px] mb-[1px]"
          />

          <Input<EnterpriseValues>
            fieldName="phone"
            label="Account User Phone Number"
            type="number"
            maxLength={12}
            inputClassName="mt-[2px] mb-[1px]"
          />
        </div>
      </div>

      <div className="flex flex-col gap-y-2 mt-[26px]">
        <Text variant="body2">Enterprise Details</Text>

        <div className="border border-borderColor rounded-[10px] p-[25px] grid grid-cols-2 grid-rows-3 gap-x-[13px] gap-y-[15px]">
          <Input<EnterpriseValues>
            fieldName="enterpriseName"
            label="Enterprise Name"
            inputClassName="mt-[2px] mb-[1px]"
          />

          <Input<EnterpriseValues>
            fieldName="address"
            label="Enterprise Address"
            inputClassName="mt-[2px] mb-[1px]"
          />

          <Input<EnterpriseValues>
            fieldName="enterpriseEmail"
            label="Enterprise Email"
            inputClassName="mt-[2px] mb-[2px]"
          />

          <Select
            value={values.category}
            onChange={(value) => setFieldValue('category', value)}
            options={categoriesOptions}
            label="Category"
            placeholder="Category"
          />

          <Input<EnterpriseValues>
            fieldName="website"
            label="Website"
            inputClassName="mt-[2px] mb-[2px]"
          />
        </div>
      </div>
    </div>
  );
};
