import React, { FC } from 'react';
import { Text } from '../Text';
import cn from 'classnames';

interface TabsProps {
  tabs: string[];
  value: number;
  className?: string;
  onChange: (newValue: number) => void;
}

export const Tabs: FC<TabsProps> = ({ tabs, value, className, onChange }) => {
  const handleTabClick = (index: number) => {
    onChange(index);
  };

  return (
    <div className={cn('flex flex-nowrap', className)}>
      {tabs.map((tab, index) => (
        <button
          className={cn(
            'h-[47px] leading-[47px] px-[30px] py-[10px] text-white bg-customerology first:rounded-l-md last:rounded-r-md whitespace-nowrap',
            {
              'opacity-20 hover:opacity-80': value !== index,
              'cursor-default': value === index,
            }
          )}
          type="button"
          key={index}
          onClick={() => handleTabClick(index)}
        >
          <Text variant="h5">{tab}</Text>
        </button>
      ))}
    </div>
  );
};
