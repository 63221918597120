import { Solution } from '@types';
import { Colors, Images } from '@utils';

interface ProductCard {
  variant: Solution;
  color: string;
  price: number;
  image: string;
}

export const productsCards: ProductCard[] = [
  {
    variant: 'zip-loyalty',
    color: Colors.ZipLoyalty,
    price: 99,
    image: Images.ZipLoyaltyCard,
  },
  {
    variant: 'zip-surveillance',
    color: Colors.ZipSurveillance,
    price: 99,
    image: Images.ZipSurveillanceCard,
  },
  {
    variant: 'fullfork',
    color: Colors.FullFork,
    price: 99,
    image: Images.FullforkCard,
  },
  {
    variant: 'plum-wifi',
    color: Colors.PlumWifi,
    price: 99,
    image: Images.PlumWifiCard,
  },
  {
    variant: 'zip-marketing',
    color: Colors.ZipMarketing,
    price: 99,
    image: Images.ZipMarketingCard,
  },
];
