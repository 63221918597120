import React, { FC } from 'react';
import { SocialLink as ISocialLink } from '../../config';
import { Images } from '@utils';
import { SocialLink } from './SocialLink';

interface SocialLinksProps {
  links: ISocialLink[];
  onChange: (value: ISocialLink[]) => void;
}

export const SocialLinks: FC<SocialLinksProps> = ({ links, onChange }) => {
  const onDelete = (name: string) => {
    const filteredLinks = links.filter((link) => link.name !== name);

    onChange(filteredLinks);
  };

  return (
    <div className="flex flex-wrap basis-1/2 gap-x-10 gap-y-4">
      {links.map(({ name, link, icon }: ISocialLink) => {
        const resultIcon = icon ? URL.createObjectURL(icon) : Images.NoImage;

        return (
          <SocialLink
            name={name}
            link={link}
            icon={resultIcon}
            onDelete={() => onDelete(name)}
          />
        );
      })}
    </div>
  );
};
