import React, { useEffect, useRef, useState } from 'react';
import { useClickAway } from '@hooks';
import { Colors, HOURS, Icons } from '@utils';
import cn from 'classnames';

import { Icon } from '../Icon';

import { Time } from '@features/DayToggler/types';

interface TimePickerProps {
  label?: string;
  withoutBorders?: boolean;
  withoutIcon?: boolean;
  value: Time;
  className?: string;
  onChange: (time: Time) => void;
}

export const TimePicker: React.FC<TimePickerProps> = ({
  label,
  withoutBorders = false,
  withoutIcon = false,
  value,
  className,
  onChange,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isPickerVisible, setPickerVisible] = useState(false);

  const ref = useClickAway(() => setPickerVisible(false), [buttonRef]);

  const [isAMSelected, setAMSelected] = useState(value.isAMSelected);
  const [selectedHour, setSelectedHour] = useState(value.selectedHour);

  useEffect(() => {
    onChange({ selectedHour, isAMSelected });
  }, [selectedHour, isAMSelected]);

  return (
    <div className={cn('relative', className)}>
      {!!label && (
        <p className="block text-[11px] leading-[16px] text-darkgrey mb-[5px]">
          {label}
        </p>
      )}

      <button
        ref={buttonRef}
        type="button"
        onClick={() => setPickerVisible(!isPickerVisible)}
        className={cn({
          'h-10 border border-borderColor pl-[5px] pr-[15px] rounded-[5px]':
            !withoutBorders,
        })}
      >
        <div
          className={cn('flex items-center ml-[10px]', {
            '!ml-0': withoutIcon,
          })}
        >
          {!withoutIcon && (
            <Icon
              icon={Icons.Clock}
              size={16}
              color={!withoutBorders ? Colors.DarkGrey : ''}
            />
          )}

          <span
            className={cn(
              'text-sm leading-[21px] ml-[5px] mr-[6px] translate-y-[0.5px] whitespace-nowrap',
              { 'text-darkgrey': !withoutBorders }
            )}
          >
            {`${selectedHour} ${isAMSelected ? 'AM' : 'PM'}`}
          </span>

          <Icon
            icon={Icons.CollapsibleArrow}
            size={8}
            color={!withoutBorders ? Colors.DarkGrey : ''}
          />
        </div>
      </button>

      {isPickerVisible && (
        <div
          ref={ref}
          className={cn(
            'absolute z-[10] top-[25px] left-[10px] flex overflow-y-scroll h-[180px] w-[100px] bg-white rounded-[5px] shadow-[0px_2px_4px_0_rgba(0,0,0,0.25)]',
            { 'left-[0px] top-[65px]': !!label }
          )}
        >
          <div className="w-[60px] flex flex-col">
            {HOURS.map((hour) => (
              <div key={hour}>
                <button
                  type="button"
                  className="h-[30px] pl-[10px]"
                  onClick={() => setSelectedHour(hour)}
                >
                  <span
                    className={cn('text-sm', {
                      'text-fullfork': selectedHour === hour,
                    })}
                  >
                    {hour}
                  </span>
                </button>
              </div>
            ))}
          </div>

          <div>
            {['AM', 'PM'].map((amPm) => (
              <div key={amPm}>
                <button
                  type="button"
                  className="h-[30px]"
                  onClick={() => setAMSelected(amPm === 'AM')}
                >
                  <span
                    className={cn('text-sm', {
                      'text-fullfork':
                        (amPm === 'AM' && isAMSelected) ||
                        (amPm !== 'AM' && !isAMSelected),
                    })}
                  >
                    {amPm}
                  </span>
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
