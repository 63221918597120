import { Page, Table } from '@ui';
import React, { useMemo, useState } from 'react';
import { ReportingFilters } from './ReportingFilters';
import { createColumnHelper, noop } from '@tanstack/react-table';
import { ReportSchedulerRow, reportSchedulersTableData } from './config';
import { ReportSchedulerModal } from './ReportSchedulerModal';
import { DateFormats, formatDate } from '@utils';

export const ReportScheduler: React.FC = () => {
  const [isShowModal, setShowModal] = useState(false);

  const columnHelper = createColumnHelper<ReportSchedulerRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        header: 'Scheduler Name',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.startDate, {
        header: 'Start Date and Time',
        cell: (cell) =>
          formatDate(cell.getValue(), DateFormats.LONG_FORMAT) || '-',
      }),
      columnHelper.accessor((row) => row.timeZone, {
        header: 'Timezone',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.frequency, {
        header: 'Frequency',
        cell: (cell) => cell.getValue() || '-',
      }),
    ],
    []
  );

  return (
    <Page title="Report Scheduler" childrenClassName="pt-14">
      <ReportingFilters
        schedulerVariant
        onOpenModal={() => setShowModal(true)}
      />

      <div className="mt-4 overflow-x-auto custom-scroll">
        <Table
          allowTextFull
          withControlPanel
          withStatusSwitch={false}
          withEdit={false}
          uniqueKey="name"
          statusKey="status"
          isError={false}
          onDelete={noop}
          onEdit={noop}
          onToggle={noop}
          theadClassName="!bg-transparent whitespace-nowrap"
          tdClassName="!px-10 !py-5"
          data={reportSchedulersTableData}
          columns={columns}
        />
      </div>

      <ReportSchedulerModal
        isOpen={isShowModal}
        onClose={() => setShowModal(false)}
      />
    </Page>
  );
};
