/* eslint-disable react/no-unstable-nested-components */

import { Page, Select, Table, Tabs, Text } from '@ui';
import React, { useMemo, useState } from 'react';
import { ReportingFilters } from './ReportingFilters';
import { createColumnHelper } from '@tanstack/react-table';
import {
  RedemptionReportRow,
  dataDisplayedOptions,
  redemptionReportTableData,
  redemptionTabs,
} from './config';
import { formatNumber } from '@utils';

export const RedemptionReport: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const [displayedDataCount, setDisplayedDataCount] = useState<number>(5);
  const columnHelper = createColumnHelper<RedemptionReportRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.title, {
        header: 'Redeemable Name',
        cell: (cell) => (
          <div>
            <span className="underline u">{cell.row.original.title}</span>
            <p className="mt-2 text-darkgrey">
              {cell.row.original.description}
            </p>
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.honoredRedemption, {
        header: 'Honored Redemption',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.honoredRedemptionPoints, {
        header: 'Honored Redemption Points',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.honoredRedeemedAmount, {
        header: 'Honored Redeemed Amount',
        cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
      }),
    ],
    []
  );

  const onChangeDisplayedDataCount = (newValue: number) => {
    setDisplayedDataCount(newValue);
  };

  return (
    <Page title="Redemption Report" childrenClassName="pt-14">
      <Tabs value={activeTab} onChange={setActiveTab} tabs={redemptionTabs} />

      <ReportingFilters className="mt-4" />

      <div className="mt-4 overflow-x-auto custom-scroll">
        <Table
          allowTextFull
          theadClassName="!bg-transparent whitespace-nowrap"
          tdClassName="!px-10 !py-5"
          // TODO pass data depend on activeTab
          data={redemptionReportTableData.slice(0, displayedDataCount)}
          columns={columns}
        />
      </div>

      <div className="flex items-center justify-start mt-7">
        <Select
          className="!w-15 mr-2"
          options={dataDisplayedOptions}
          placeholder="Location"
          value={displayedDataCount}
          onChange={onChangeDisplayedDataCount}
          menuPlacement="top"
        />
        <Text variant="body1">Data displayed</Text>
      </div>
    </Page>
  );
};
