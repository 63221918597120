import { SelectOption } from '@ui';
import { Role } from '../Users/config';

export interface RolesTableRow {
  role: Role;
  permissionSet: string;
}

export const permissionSetOptions: SelectOption[] = [
  {
    label: 'Level 1',
    value: 1,
  },
  {
    label: 'Level 2',
    value: 2,
  },
  {
    label: 'Level 3',
    value: 3,
  },
  {
    label: 'Level 4',
    value: 4,
  },
  {
    label: 'Level 5',
    value: 5,
  },
  {
    label: 'Level 6',
    value: 6,
  },
];

export const rolesTableData: RolesTableRow[] = [
  { role: 'account-holder', permissionSet: 'Level 1' },
  { role: 'regional-manager', permissionSet: 'Level 2' },
  { role: 'catering-manager', permissionSet: 'Level 3' },
  { role: 'general-manager', permissionSet: 'Level 4' },
  { role: 'assistant-manager', permissionSet: 'Level 5' },
  { role: 'cafe-manager', permissionSet: 'Level 6' },
];
