import { z } from 'zod';

const cardFields = z.object({
  holderName: z.string(),
  cardNumber: z.string(),
  expiryDate: z.string(),
  cvv: z.string(),
});

const CardSchema = cardFields;

export type CardValues = z.infer<typeof CardSchema>;

export { CardSchema };
