import { DayOfWeekNumber, LocationHours, Location } from '@api';
import {
  MOCKED_ENTERPRISE_ID,
  getOpenTimeInMinutes,
  getTimeInHMSFormat,
  uuid,
} from '@utils';

import { InitialDayValues } from '../../../config';

import { AddLocationValues } from './validation';

const adaptLocationHours = (
  applicableDays: InitialDayValues[]
): LocationHours[] => {
  const today = new Date();
  const oneYearFrom = new Date(today.setFullYear(today.getFullYear() + 1));

  return applicableDays
    .map(({ startTime, endTime }, index) => ({
      id: index,
      ltz_DayOfWeek: index as DayOfWeekNumber,
      ltz_Open_Time: getTimeInHMSFormat(startTime),
      open_Mins: getOpenTimeInMinutes(startTime, endTime),
      ltz_ValidFrom: new Date().toISOString(),
      ltz_ValidUntil: oneYearFrom.toISOString(),
      override_Desc: null,
      isOverride: false,
    }))
    .filter((_, index) => applicableDays[index].applicable);
};

export const adaptAddLocation = (values: AddLocationValues): Location => {
  const {
    name,
    enabled,
    businessPhoneNumber,
    website,
    street,
    state,
    city,
    zipcode,
    hours,
  } = values;

  const adaptedHours = adaptLocationHours(hours);

  return {
    enterprise_Id: MOCKED_ENTERPRISE_ID,
    enabled,
    name,
    guid: uuid(),
    businessPhoneNumber,
    website,
    jsonStreetAddress: [
      {
        street,
        city,
        state,
        zipcode,
        isDefault: true,
        addressType: 'Address name',
      },
    ],
    hours: adaptedHours?.length ? adaptedHours : null,
  };
};

export const adaptEditLocation = (
  values: AddLocationValues,
  location: Location
) => {
  const { name, enabled, businessPhoneNumber, website, hours } = values;

  const adaptedHours = adaptLocationHours(hours);

  return {
    ...location,
    name,
    businessPhoneNumber,
    website,
    enabled,
    hours: adaptedHours?.length ? adaptedHours : null,
  };
};
