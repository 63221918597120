import React from 'react';

export const Terms = () => (
  <>
    Thank you (&quot;You&quot;) for visiting our website. CSG Forte Payments,
    Inc.&apos;s (&quot;CSG Forte&quot;) Terms of Use governs the terms and
    conditions around your use of this website. These terms are a legal
    agreement between you and CSG Forte. By accessing our website, you are
    agreeing to be bound by these Terms of Use, as well as all applicable
    national, state and local laws and regulations. These Terms of Use govern
    your use of CSG Forte&apos;s services, including mobile applications,
    websites, software, hardware, other products and services and all data
    transmissions through any mode including but not limited to http request,
    API interface, email network or other unspecified system of data storage or
    transmission provided by CSG Forte (collectively, the &quot;Services&quot;).
    If you are using the Services on behalf of a business, you represent to us
    that you have authority to bind that business or entity to these terms, and
    that business accepts these terms. If you do not agree with any of these
    Terms of Use, you are prohibited from using or accessing this site. You
    should read all of our terms carefully.
  </>
);

export const Privacy = () => (
  <>
    CSG Forte Payments, Inc. (&quot;CSG Forte&quot;) offers payment solutions
    that assist organizations to accept and process their payments in a secure
    manner. CSG Forte is a leading provider of payment processing and risk
    management services to tens of thousands of organizations located throughout
    North America.
    <br />
    <br />
    <b>Scope and Consent</b>
    <br />
    <br />
    By using the CSG Forte services and/or submitting data to CSG Forte, either
    directly or via platform, software, or other entity that works with CSG
    Forte or uses CSG Forte&apos;s services (&quot;Partners&quot;), you
    expressly consent to our collection, use, disclosure and retention of your
    personal information as described by this Privacy Policy.
    <br />
    You acknowledge that CSG Forte&apos;s use of your data is necessary for us
    to comply with applicable law and to provide contracted services to
    facilitate payments. CSG Forte will use your data only in accordance with
    the terms of this Privacy Policy. Throughout this Privacy Policy, we use the
    term &quot;personal information&quot; to describe information that can be
    associated with a specific person and could be used to identify that person.
    We do not consider personal information to include information that has been
    made anonymous or aggregated so that it can no longer be used, whether in
    combination with other information or otherwise, to identify a specific
    person. We may share Personal and non-Personal Information with third
    parties for any lawful purpose. Collection of Personal Information CSG Forte
    will collect the personal information that you provide when you apply or
    sign up for an account or other Services, go through our identity or account
    verification process, authenticate your account, communicate with us, answer
    our surveys, upload content, or otherwise use CSG Forte&apos;s Services.
    Additionally, you can choose to voluntarily provide information to us.
    <br />
    <br />
    <b>Information collected may include but is not limited to:</b>
    <br />
    <br />
    - Identification Information. Your name; email address; mailing
    address; phone number; photograph; birthdate; passport, driver&apos;s
    license, Social Security, Taxpayer Identification, or other
    government-issued identification; or other historical, contact, and
    demographic
  </>
);

export const Security = () => (
  <>
    CSG Forte Payments, Inc., a company incorporated in the USA whose registered
    office is at 500 W. Drive., Suite 200, Allen TX 75002. CSG Forte Payments,
    Inc. (&apos;CSG Forte&apos;) offers payment solutions that assist
    organizations to accept and process their payments in a secure manner. CSG
    Forte is a leading provider of payment processing and risk management
    services to tens of thousands of organizations located throughout North
    America.
    <br />
    CSG Forte is committed to maintaining the privacy, security, and accuracy of
    your personal data. As a result, CSG Forte has developed this policy to
    inform you of the steps it has taken to protect your privacy. In addition,
    CSG Forte and its employees also adhere to strict internal information
    security policies and procedures to safeguard your information. CSG Forte
    complies with all applicable data protection laws, including the General
    Data Protection Regulation (“GDPR”).
    <br />
    <br />
    <b>Information Security </b>
    <br />
    <br />
    Information security is critical to our business. We work to protect the
    security of consumers&apos; information as well as our merchants through the
    use of best practices for physical, electronic and procedural safeguards as
    prescribed by the security standards set by the card brands and payment
    associations. Any information that is gathered and/or stored, is retained on
    servers located in a secure data center. The number of people with access to
    these servers is limited and controlled. Firewalls and other security
    technology are used to prevent system servers and computers from being
    accessed by unauthorized persons. It is the merchant&apos;s responsibility
    to protect against unauthorized access to the system through misuse of
    individual Merchant ID, User Name and Password.
    <br />
    To assure of no unauthorized access, please be certain you log off the
    system when finished with your session and protect your Merchant ID, User
    Name and Password used to access your merchant account. Also, please be sure
    to implement safeguards on your internal systems. Unfortunately, no data
    transmission or storage system can be guaranteed to be 100% secure. Although
    we will do our best to protect your personal data, we cannot guarantee the
    security of your data transmitted to CSG Forte and any transmission is taken
    at your own risk. Once we have received your information, we will use
    commercially reasonable procedures and security features to try to prevent
    unauthorized access.
  </>
);
