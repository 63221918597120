import { DAYS } from '@utils';

export const adaptSelectedDays = (input: string | undefined) => {
  if (!input) {
    return [];
  }

  const selectedDays = input.split('-').map((el) => !!el);

  return DAYS.filter((_, index) => selectedDays[index]);
};
