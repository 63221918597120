export const Images = {
  AppsComingSoon: require('../assets/images/app-coming-soon.png'),
  Background: require('../assets/images/background.svg').default,
  CustomerologyLogo: require('../assets/images/customerology-logo.png'),

  FullforkLogo: require('../assets/images/fullfork/fullfork-logo.png'),
  FullforkBackground: require('../assets/images/fullfork/fullfork-background.png'),
  FullforkCard: require('../assets/images/fullfork/fullfork-card.png'),
  FFDefaultOfferImage: require('../assets/images/fullfork/ff-default-offer-image.png'),
  FullforkDashboard: require('../assets/images/fullfork/fullfork-dashboard.png'),
  FullforkTable: require('../assets/images/fullfork/fullfork-table.png'),

  PlumWifiLogo: require('../assets/images/plum-wifi/plum-wifi-logo.png'),
  PlumWifiBackground: require('../assets/images/plum-wifi/plum-wifi-background.png'),
  PlumWifiCard: require('../assets/images/plum-wifi/plum-wifi-card.png'),
  PlumWifiDashboard: require('../assets/images/plum-wifi/plum-wifi-dashboard.png'),
  PlumWifiSettings: require('../assets/images/plum-wifi/plum-wifi-settings.png'),
  PlumWifiRouter: require('../assets/images/plum-wifi/plum-wifi-router.png'),

  ZipLoyaltyCard: require('../assets/images/zip-loyalty/zip-loyalty-card.png'),
  ZipLoyaltyLogo: require('../assets/images/zip-loyalty/zip-loyalty-logo.png'),
  ZipLoyaltyDashboard: require('../assets/images/zip-loyalty/zip-loyalty-dashboard.png'),
  ZipLoyaltyTable: require('../assets/images/zip-loyalty/zip-loyalty-table.png'),

  ZipSurveillanceCard: require('../assets/images/zip-suveillance/zip-surveillance-card.png'),
  ZipSurveillanceLogo: require('../assets/images/zip-suveillance/zip-survaillance-logo.png'),
  ZipSurveillanceDashboard: require('../assets/images/zip-suveillance/zip-surveillance-dashboard.png'),
  ZipSurveillanceTable: require('../assets/images/zip-suveillance/zip-surveillance-table.png'),

  ZipMarketingCard: require('../assets/images/zip-marketing/zip-marketing-card.png'),
  ZipMarketingLogo: require('../assets/images/zip-marketing/zip-marketing-logo.png'),
  ZipMarketingDashboard: require('../assets/images/zip-marketing/zip-marketing-dashboard.png'),
  ZipMarketingTable: require('../assets/images/zip-marketing/zip-marketing-table.png'),

  PhonePreview: require('../assets/images/phone-preview.png'),
  Food: require('../assets/images/food.png'),
  NoImage: require('../assets/images/no-image.png'),
  NoAvatar: require('../assets/images/no-avatar.png'),
  Toast: require('../assets/images/toast.png'),
};
