import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactModal from 'react-modal';
import { RoutesProvider } from '@navigation';
import { reportWebVitals } from '@app/analytics';
import { Provider } from 'react-redux';
import { store } from '@store';
import { SnackbarProvider } from 'notistack';

import '@styles/index.css';
import { Snackbar } from './shared/ui';
import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

ReactModal.setAppElement(rootElement);

root.render(
  <React.StrictMode>
    <SnackbarProvider
      Components={{
        error: Snackbar,
        success: Snackbar,
        info: Snackbar,
        default: Snackbar,
        warning: Snackbar,
      }}
    >
      <HelmetProvider>
        <Provider store={store}>
          <RoutesProvider />
        </Provider>
      </HelmetProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

reportWebVitals();
