import { z } from 'zod';

const addOfferFields = z.object({
  title: z.string({ required_error: 'Title required' }),
  offerCode: z.string({ required_error: 'Offer Code required' }),
  mustBuy: z.string().optional(),
  imageUrl: z.any().optional(),
  description: z.string({ required_error: 'Description required' }),
  enabled: z.boolean(),
  noExpiration: z.boolean().default(true),
  selectDays: z.string().array().optional(),
  validFrom: z.date({ required_error: 'Required' }),
  validUntil: z.date({ required_error: 'Required' }).nullable(),
  validTime: z.any({ required_error: 'Required' }).nullable(),
  enableNoTimeRestriction: z.boolean().default(true),
  cron_Validity: z.string().nullable().optional(),
  forHours: z.number({ required_error: 'Required' }),
  eligibleUseCount: z.number(),
  staffGrantable: z.boolean(),
  isStanding: z.boolean(),
  dontSendExpireReminder: z.boolean(),
  isSendWebLinks: z.boolean(),
  couponRedeemLimit: z.number(),
  slackTime: z.number(),
  expirationReminders: z.number(),
  availability: z.number(),
  everydayEnabled: z.boolean().default(false),
  restrictions: z.object({}).nullable().optional(),
  finePrint: z.string().nullable().optional(),
});
// .refine(
//   (data) => {
//     // If everydayEnabled is false, ensure selectDays is not empty
//     if (!data.everydayEnabled) {
//       return !!data.selectDays;
//     }
//     // If everydayEnabled is true, no additional check is needed
//     return true;
//   },
//   {
//     // Custom error message
//     message: "Select days must not be empty if 'everydayEnabled' is false",
//     path: ['selectDays'], // Specify the field this error is associated with
//   }
// );

const AddOfferSchema = addOfferFields;

export type AddOfferValues = z.infer<typeof AddOfferSchema>;

export { AddOfferSchema };
