import { Button, DatePicker, Select, Tabs } from '@ui';
import React, { FC, useState } from 'react';
import { BreakdownVariant, manageAppsOptions, tabs } from './config';
import { ManageApps, PaymentOptions, Statements } from './tabs';

export const Billing: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const [breakdownValue, setBreakdownValue] =
    useState<BreakdownVariant>('monthly');
  const [statementsDateValue, setStatementsDateValue] = useState(new Date());

  const [isStatementOpen, setStatementOpen] = useState(false);

  return (
    <div className="h-full">
      <div className="flex items-center justify-between gap-x-2">
        <Tabs value={tabIndex} onChange={setTabIndex} tabs={tabs} />

        {tabIndex === 0 && (
          <Select
            type="filter"
            options={manageAppsOptions}
            value={breakdownValue}
            onChange={setBreakdownValue}
          />
        )}

        {tabIndex === 2 && (
          <div className="flex items-center justify-between gap-x-[54px]">
            <DatePicker
              value={statementsDateValue}
              onChange={setStatementsDateValue}
              type="range"
            />

            <Button
              onClick={() => setStatementOpen(true)}
              label="View Statement"
              color="customerology"
              className="!h-10 whitespace-nowrap"
            />
          </div>
        )}
      </div>

      {tabIndex === 0 && <ManageApps variant={breakdownValue} />}
      {tabIndex === 1 && <PaymentOptions />}
      {tabIndex === 2 && (
        <Statements
          isModalOpen={isStatementOpen}
          onChangeModalVisibility={setStatementOpen}
        />
      )}
    </div>
  );
};
