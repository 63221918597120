import numeral from 'numeral';
import { Address } from '@api';

export const isDev = process.env.NODE_ENV === 'development';
const queryParameters = new URLSearchParams(window.location.search);
export const MOCKED_ENTERPRISE_ID =
  queryParameters.get('enterprise_id') == null
    ? 3001
    : Number(queryParameters.get('enterprise_id'));
export const SESSION_TOKEN =
  queryParameters.get('session_token') == null
    ? process.env.REACT_APP_SANDBOX_API_KEY
    : queryParameters.get('session_token');

export const uuid = () => {
  const chars = '0123456789abcdef'.split('');

  const uuids = [];
  const rnd = Math.random;

  let r;

  uuids[8] = uuids[13] = uuids[18] = uuids[23] = '-';
  uuids[14] = '4';

  for (let i = 0; i < 36; i++) {
    if (!uuids[i]) {
      r = 0 | (rnd() * 16);

      uuids[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r & 0xf];
    }
  }

  return uuids.join('');
};

export const formatAddress = (address: Address) => {
  if (!address || !address.street) {
    return '';
  }

  return `${address.street}, ${address.city}, ${address.state} ${address.zipcode}`;
};

export const pluralize = (count: number, noun: string, suffix = 's') => {
  const correctSuffix = noun.endsWith('s') ? 'es' : suffix;

  return `${noun}${count !== 1 && count !== 0 ? correctSuffix : ''}`;
};

export const formatNumber = (num: number) => numeral(num).format('0,0.00');

export const formatPhoneNumber = (input: number) => {
  if (!input || Number.isNaN(input)) {
    return null;
  }

  const phoneNumber = input.toString();

  const areaCode = phoneNumber.substring(0, 3);
  const prefix = phoneNumber.substring(3, 6);
  const lineNumber = phoneNumber.substring(6);

  return `(${areaCode}) ${prefix}-${lineNumber}`;
};

export const formatBitsToMbps = (
  bits: number,
  decimalPlaces: number = 0
): string => (bits / 1000000).toFixed(decimalPlaces);
