import { Location } from '@api';
import { SearchLocationBy } from '../../../config';

export const searchLocations = (
  locations: Location[] | undefined,
  searchValue: string,
  searchBy: SearchLocationBy
) => {
  if (!locations?.length) {
    return [];
  }

  const isMatch = (target: string | null) =>
    target ? target.toLowerCase().includes(searchValue.toLowerCase()) : false;

  return locations.filter((location) => {
    if (searchBy === 'address') {
      return location.jsonStreetAddress
        ? isMatch(Object.values(location.jsonStreetAddress[0]).join(''))
        : false;
    }

    return isMatch(location[searchBy]);
  });
};
