import { match } from 'ts-pattern';

export const getRangePages = (currentPage: number, pageCount: number) =>
  match({ currentPage, pageCount })
    .with({ pageCount: 1 }, () => [0])
    .with({ pageCount: 2 }, () => [0, 1])
    .with({ currentPage: 0 }, () => [
      currentPage,
      currentPage + 1,
      currentPage + 2,
    ])
    .with({ currentPage: pageCount - 1 }, () => [
      currentPage - 2,
      currentPage - 1,
      currentPage,
    ])
    .otherwise(() => [currentPage - 1, currentPage, currentPage + 1]);
