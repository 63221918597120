import { Button, Icon, Input, Switch } from '@ui';
import React, { FC } from 'react';
import { CustomizationValues } from '../../helpers/validation';
import { useFormikContext } from 'formik';
import { Colors } from '@utils';
import { socialLinksItems } from '../../config';

export const SocialLinks: FC = () => {
  const { values, setFieldValue } = useFormikContext<CustomizationValues>();

  return (
    <div className="flex flex-col gap-y-4 px-10 pt-[11px] pb-[13px] bg-white rounded-[10px]">
      {socialLinksItems.map(({ name, icon, fieldName, switchFieldName }) => (
        <div
          key={fieldName}
          className="flex justify-between items-center gap-x-[10px] "
        >
          <Icon
            icon={icon}
            color={Colors.PlumWifi}
            size={18}
            className="min-w-[18px]"
          />
          <Input<CustomizationValues>
            fieldName={fieldName}
            placeholder={`${name}.com`}
            inputClassName="!h-[26px]"
            wrapperClassName="!h-[28px]"
          />
          <Switch
            checked={values[switchFieldName]}
            onCheckedChange={(value) => setFieldValue(switchFieldName, value)}
          />
        </div>
      ))}

      <Button
        label="Connect"
        color="plum-wifi"
        className="!h-[33px] font-bold text-[18px]"
      />
    </div>
  );
};
