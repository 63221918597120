import { AnyAction, configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import { createLogger } from 'redux-logger';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { isDev } from '@utils';

import {
  locationApi,
  offerApi,
  overviewApi,
  plumWifiApi,
  sThreeApi,
} from '../api';

import { rootReducer } from './rootReducer';

const createStore = () => {
  const logger = createLogger({ collapsed: true });

  const defaultMiddleware = [
    thunk,
    locationApi.middleware,
    offerApi.middleware,
    overviewApi.middleware,
    plumWifiApi.middleware,
    sThreeApi.middleware,
  ];

  const store = configureStore({
    reducer: rootReducer,
    middleware: isDev ? [logger, ...defaultMiddleware] : [...defaultMiddleware],
  });

  return store;
};

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type ReduxState = ReturnType<typeof rootReducer>;
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  AnyAction
>;

export const useDispatch = () => useReduxDispatch<TypedDispatch>();
export const useSelector: TypedUseSelectorHook<ReduxState> = useReduxSelector;
