import React, { FocusEventHandler } from 'react';
import ReactSelect, { Props } from 'react-select';

import {
  Control,
  ControlWithError,
  ControlWithoutBorder,
  DropdownIndicator,
  DropdownIndicatorCloser,
  FilterValueContainer,
  Input,
  MultiValue,
  MultiValueContainer,
  ValueContainer,
} from './Custom';

import './style.css';
import { match } from 'ts-pattern';

type OptionValue = string | number | null;
export interface SelectOption {
  label: string | null;
  value: OptionValue;
}

type SelectType = 'default' | 'filter' | 'multi';

interface SelectProps extends Props {
  id?: string;
  type?: SelectType;
  placeholder?: string;
  withoutBorder?: boolean;
  options: SelectOption[];
  value: any | any[];
  disabled?: boolean;
  className?: string;
  wrapperClassName?: string;
  error?: boolean;
  label?: string;
  onChange: (newValue: any) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const getValueContainerByType = (type: SelectType) => {
  if (type === 'multi') {
    return MultiValueContainer;
  }

  if (type === 'filter') {
    return FilterValueContainer;
  }

  return ValueContainer;
};

export const Select: React.FC<SelectProps> = ({
  id,
  type = 'default',
  value,
  placeholder,
  withoutBorder = false,
  options,
  disabled,
  className,
  error,
  label,
  wrapperClassName,
  onBlur,
  onChange,
  ...rest
}) => {
  const getSelectValue = (newValue: any | any[]) => {
    if (Array.isArray(newValue)) {
      return options.filter((option) => newValue.indexOf(option.value) !== -1);
    }

    return options.find((option) => option.value === newValue) || null;
  };

  const handleChange = (newValue: SelectOption | SelectOption[]) => {
    if (Array.isArray(newValue)) {
      onChange(newValue.map((option) => option.value));

      return;
    }

    onChange(newValue?.value);
  };

  return (
    <div className={wrapperClassName}>
      {!!label && (
        <p className="block text-[11px] leading-[16px] text-darkgrey mb-[5px]">
          {label}
        </p>
      )}

      <ReactSelect
        inputId={id}
        className={className}
        isDisabled={disabled}
        value={disabled ? null : getSelectValue(value)}
        onChange={handleChange as (newValue: unknown) => void}
        onBlur={onBlur}
        placeholder={placeholder}
        isMulti={type === 'multi'}
        options={options}
        defaultValue={type !== 'multi' && options[0]}
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
          ValueContainer: getValueContainerByType(type),
          MultiValue,
          Control: match(withoutBorder)
            .with(true, () => ControlWithoutBorder)
            .with(false, () => (error ? ControlWithError : Control))
            .otherwise(() => Control),
          DropdownIndicator: withoutBorder
            ? DropdownIndicatorCloser
            : DropdownIndicator,
          Input,
        }}
        closeMenuOnSelect={rest.closeMenuOnSelect ?? true}
        {...rest}
      />
    </div>
  );
};
