import { format } from 'date-fns';

import { Hour, Time, WeekDay } from '@features/DayToggler/types';
import { TimeSpan } from '@api';

export type Day = 'M' | 'T' | 'W' | 'Th' | 'F' | 'Sa' | 'Su';

export const DAYS: Day[] = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su'];

export const getHoursFromTime = (time: Time): number =>
  +time.selectedHour.split(':')[0] + (time.isAMSelected ? 0 : 12);

export const getOpenTimeInMinutes = (from: Time, to: Time): number => {
  const fromHours = getHoursFromTime(from);
  const toHours = getHoursFromTime(to);

  return (toHours - fromHours) * 60;
};

export const formatTime = (
  time: string
): { isAMSelected: boolean; selectedHour: string } => {
  const [hours, minutes] = time.split(':').map(Number);
  const isAMSelected = hours < 12;
  const selectedHour = `${hours % 12 === 0 ? 12 : hours % 12}:${minutes
    .toString()
    .padStart(2, '0')}`;

  return { isAMSelected, selectedHour };
};

export const getTimeInHMSFormat = (time: Time) => {
  const date = new Date().setHours(getHoursFromTime(time), 0, 0);

  return format(date, 'HH:mm:ss');
};

export const mapTimeSpan = (time: Time): TimeSpan => {
  const hours = getHoursFromTime(time);

  return {
    ticks: 0,
    days: 0,
    hours,
    milliseconds: 0,
    minutes: 0,
    seconds: 0,
    totalDays: 0,
    totalHours: hours,
    totalMilliseconds: hours * 60 * 60 * 1000,
    totalMinutes: hours * 60,
    totalSeconds: hours * 60 * 60,
  };
};

export const hoursToTicks = (hours: number) => hours * 60 * 60 * 10 ** 6;

export const timeToTicks = (time: Time) => hoursToTicks(getHoursFromTime(time));

export const getFullDateFromDayAndTime = (date: Date, time: Time): Date => {
  const militaryTime = `${getHoursFromTime(time)}:00`;
  const hours = parseInt(militaryTime.split(':')[0], 10);
  const dateObj = new Date(date);
  dateObj.setUTCHours(hours);
  return dateObj;
};

export enum DateFormats {
  MONTH_YEAR = 'MMMM yyy',
  SHORT_FORMAT = 'dd/MMM/yyyy',
  LONG_FORMAT = 'dd-MMM-yy H:mm:ss',
  AMERICAN_FORMAT = 'MM/dd/yyyy',
}

export const formatDate = (
  date: Date | undefined,
  dateFormat: DateFormats = DateFormats.SHORT_FORMAT
) => format(date || new Date(), dateFormat);

export const WEEK_DAYS: WeekDay[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const HOURS: Hour[] = [
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
];
