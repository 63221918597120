import { Solution } from '@types';

export const backgroundEnum = (
  color: Solution | 'customerology' | 'white'
) => ({
  'bg-customerology': color === 'customerology',
  'bg-fullfork': color === 'fullfork',
  'bg-plumwifi': color === 'plum-wifi',
  'bg-ziployalty': color === 'zip-loyalty',
  'bg-zipmarketing': color === 'zip-marketing',
  'bg-zipsurveillance': color === 'zip-surveillance',
});
