import React, { FC } from 'react';
import { Icon } from '../../Icon';
import { Colors, Icons } from '@utils';

interface SortIconsProps {
  sort: 'asc' | 'desc' | false;
}

export const SortIcons: FC<SortIconsProps> = ({ sort }) => {
  return (
    <div className="flex flex-col items-center justify-center w-4 h-5 px-1 hover:brightness-125">
      <Icon
        icon={Icons.CollapsibleArrow}
        color={sort === 'asc' ? Colors.Text : Colors.DarkGrey}
        className="rotate-180 -mb-[1px]"
        size={8}
      />
      <Icon
        icon={Icons.CollapsibleArrow}
        color={sort === 'desc' ? Colors.Text : Colors.DarkGrey}
        className="-mt-[1px]"
        size={8}
      />
    </div>
  );
};
