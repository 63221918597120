import React, { FC, useMemo, useRef, useState } from 'react';
import { SelectOption } from '../Select';
import { Icon } from '../Icon';
import { Colors, Icons } from '@utils';
import cn from 'classnames';
import { useClickAway } from '@hooks';
import { Spinner } from '../Spinner';

interface AutocompleteProps {
  options: SelectOption[];
  value: any;
  loading?: boolean;
  onChange: (value: any) => void;
}

export const Autocomplete: FC<AutocompleteProps> = ({
  options,
  value,
  loading,
  onChange,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const getSelectedOption = (newValue: any | any[]) =>
    options.find((option) => option.value === newValue) || null;

  const handleChange = (newValue: any) => {
    onChange(newValue);

    setDropdownVisible(false);
  };

  const ref = useClickAway(() => setDropdownVisible(false), [buttonRef]);

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.label?.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [options]
  );

  return (
    <div className="relative max-w-[350px]">
      <button
        ref={buttonRef}
        type="button"
        onClick={() => setDropdownVisible(!isDropdownVisible)}
        className="text-[17px] leading-[25px] text-white flex justify-between items-center gap-x-[10px] whitespace-nowrap"
      >
        {loading ? (
          <Spinner className="mr-6" />
        ) : (
          <span className="font-light">{getSelectedOption(value)?.label}</span>
        )}

        <Icon
          icon={Icons.ArrowRight}
          className={cn('transition-all', {
            '-rotate-90': isDropdownVisible,
            'rotate-90': !isDropdownVisible,
          })}
        />
      </button>

      {isDropdownVisible && !loading && (
        <div
          ref={ref}
          className="absolute top-[45px] z-20 right-0 flex flex-col gap-y-2 "
        >
          <label htmlFor="search" className="relative">
            <input
              id="search"
              type="search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className="h-[36px] rounded-[5px] pl-7 pr-2 focus:outline-none placeholder:opacity-50 text-[13px]"
              placeholder="Search"
            />
            <Icon
              icon={Icons.SearchBold}
              className="absolute left-1 top-[9px] opacity-75"
              size={15}
              color={Colors.DarkGrey}
            />
          </label>

          <ul className="bg-white rounded-[5px] text-darkgrey text-[13px] leading-5 py-[6px] max-h-[150px] overflow-auto">
            {filteredOptions.map((option) => (
              <li key={option.value}>
                <button
                  onClick={() => handleChange(option.value)}
                  type="button"
                  className="py-[7px] px-[10px] hover:text-text whitespace-nowrap"
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
