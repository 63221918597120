import React, { FC } from 'react';
import Collapsible from 'react-collapsible';
import { Icon, Input } from '@ui';
import { Colors, Icons } from '@utils';
import { AccordionHeader } from './AccordionHeader';
import { AddImage } from '../AddImage';
import { CustomizationValues } from '../../helpers/validation';
import { SocialLinks } from '../SocialLinks';
import { CustomizationAccordionVariant } from '../../config';

interface CustomizationAccordionProps {
  title: string;
  variant: CustomizationAccordionVariant;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export const CustomizationAccordion: FC<CustomizationAccordionProps> = ({
  title,
  isOpen,
  onOpen,
  onClose,
  variant,
}) => {
  const icon = (
    <Icon
      icon={Icons.ArrowLeft}
      color={Colors.Text}
      className="-rotate-90 chevron !top-4"
    />
  );

  return (
    <Collapsible
      trigger={<AccordionHeader title={title} />}
      transitionTime={100}
      triggerSibling={icon}
      contentOuterClassName="absolute z-20 w-full shadow-lg rounded-[10px]"
      open={isOpen}
      handleTriggerClick={isOpen ? onClose : onOpen}
    >
      {variant === 'links' ? (
        <SocialLinks />
      ) : (
        <div className="bg-white flex flex-col rounded-[10px] px-6 pt-[10px] pb-4">
          <AddImage<CustomizationValues>
            fieldName={variant === 'logo' ? 'logo' : 'background'}
          />

          <div className="mt-[10px]">
            <Input<CustomizationValues>
              fieldName={variant === 'logo' ? 'logoAlt' : 'backgroundAlt'}
              placeholder="Alt Text"
            />
          </div>
        </div>
      )}
    </Collapsible>
  );
};
