/* eslint-disable react/no-unstable-nested-components */

import { Table } from '@ui';
import { formatNumber } from '@utils';
import { createColumnHelper } from '@tanstack/react-table';
import React, { FC, useMemo } from 'react';
import { MonthlyBreakdownTableRow, monthlyBreakdownTableData } from '../config';

export const MonthlyBreakdown: FC = () => {
  const columnHelper = createColumnHelper<MonthlyBreakdownTableRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        header: 'App Name',
        cell: (cell) => {
          if (cell.getValue() === 'Plum WiFi') {
            return (
              <span>
                <b>{cell.getValue()}</b>
              </span>
            );
          }

          const [firstWord, secondWord] = cell.getValue().split(' ');

          return (
            <span>
              <b>{firstWord}</b>
              {' '}
              {secondWord}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row.locations, {
        header: 'Locations',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.isSubscribed, {
        header: 'Status',
        cell: (cell) => (
          <span>
            <b>{cell.getValue() ? 'Subscribed' : 'Unsubscribed'}</b>
          </span>
        ),
      }),
      columnHelper.accessor((row) => row.price, {
        header: 'Price/Month',
        cell: (cell) =>
          cell.getValue() ? (
            `$${formatNumber(cell.getValue())}`
          ) : (
            <span>
              <b>-</b>
            </span>
          ),
      }),
      columnHelper.accessor((row) => row.total, {
        header: 'Total Month',
        cell: (cell) =>
          cell.getValue() ? (
            `$${formatNumber(cell.getValue())}`
          ) : (
            <span>
              <b>-</b>
            </span>
          ),
      }),
    ],
    []
  );

  return (
    <Table
      className="!m-0"
      data={monthlyBreakdownTableData || []}
      columns={columns}
    />
  );
};
