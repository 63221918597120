import { Button, Text } from '@ui';
import React, { FC } from 'react';

interface SessionSlideProps {
  logo: string;
  logoAlt: string | undefined;
}

export const SessionSlide: FC<SessionSlideProps> = ({ logo, logoAlt }) => {
  return (
    <div className="rounded-[10px] bg-white w-[350px] h-[420px] py-5 px-[14px] flex flex-col justify-between">
      <div>
        <div className="flex items-center justify-center">
          {logo ? (
            <img
              src={logo}
              alt={logoAlt || 'logo'}
              className="object-cover !h-[100px] !w-[100px] rounded-full"
            />
          ) : (
            <div className="h-[100px] w-[100px] bg-lightgrey rounded-full" />
          )}
        </div>

        <Text
          variant="body2"
          className="text-[11px] leading-4 mt-4 text-center"
        >
          You are now connected to Famous Dave’s Free WiFi Network. Enjoy
          {' '}
          <b>1 hour</b>
          {' '}
          of high speed internet access with
          <b> 500mb</b>
          {' '}
          of data limit.
        </Text>
      </div>
      <div>
        <div className="flex items-center justify-center gap-x-[14px]">
          <div className="flex flex-col items-center justify-center">
            <Text
              variant="h1"
              className="text-[35px] leading-[52px] !font-bold text-plumwifi"
            >
              59:00
            </Text>
            <span className="bg-[#EEEEEE] mt-[10px] rounded-[5px] text-text text-[12px] leading-[18px] py-[3px] px-[10px]">
              Time Left
            </span>
          </div>

          <div className="h-[74px] w-[1px] border-r border-[#D0D0D0]" />

          <div className="flex flex-col items-center justify-center">
            <Text
              variant="h1"
              className="text-[35px] leading-[52px] !font-bold text-plumwifi"
            >
              499 Mb
            </Text>
            <span className="bg-[#EEEEEE] mt-[10px] rounded-[5px] text-text text-[12px] leading-[18px] py-[3px] px-[10px]">
              Data Left
            </span>
          </div>
        </div>

        <Button
          disabled
          label="End Session"
          color="plum-wifi"
          className="mt-9 w-full !h-[49px] font-bold !rounded-[10px] disabled:!opacity-100"
        />
      </div>
    </div>
  );
};
