import React, { useState } from 'react';
import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { CustomXAxisTick, CustomYAxisTick, CustomTooltip } from '../Custom';
import './style.css';

export interface BarChartProps<ChartElement> {
  data: ChartElement[];
  xDataKey: keyof ChartElement;
  yDataKey: keyof ChartElement;
}

export const BarChart = <ChartElement extends object>({
  data,
  xDataKey,
  yDataKey,
}: BarChartProps<ChartElement>) => {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <Chart data={data} barSize={30}>
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey={xDataKey as string} tick={<CustomXAxisTick />} />
        <YAxis tick={<CustomYAxisTick />} />

        <Tooltip
          content={<CustomTooltip />}
          cursor={{ fill: 'transparent' }}
          position={{ x: tooltipPosition.x + 10, y: tooltipPosition.y - 6 }}
          animationDuration={300}
        />

        <Bar
          fill="#8ACCF4"
          dataKey={yDataKey as string}
          onMouseEnter={setTooltipPosition}
          radius={[6, 6, 0, 0]}
        />
      </Chart>
    </ResponsiveContainer>
  );
};
