export const Icons = {
  Applications: 'applications',
  ArrowBack: 'arrow-back',
  ArrowCollapse: 'arrow-collapse',
  ArrowExpand: 'arrow-expand',
  ArrowLeft: 'arrow-left',
  ArrowRightDown: 'arrow-right-down',
  ArrowRightUp: 'arrow-right-up',
  ArrowRight: 'arrow-right',
  ArrowRightLong: 'arrow-right-long',
  Bag: 'bag',
  Calendar: 'calendar',
  Camera: 'camera',
  Clock: 'clock',
  Close: 'close',
  CollapsibleArrow: 'collapsible-arrow',
  Done: 'done',
  DoubleArrowsLeft: 'double-arrows-left',
  DoubleArrowsRight: 'double-arrows-right',
  Facebook: 'facebook',
  Eye: 'eye',
  EyeMarked: 'eye-marked',
  Figures: 'figures',
  Filter: 'filter',
  FullFork: 'fullfork',
  GlobeStretched: 'globe-stretched',
  Restart: 'restart',
  ReportSchedule: 'report-schedule',
  ReportScheduleBold: 'report-schedule-bold',
  RedemptionReport: 'redemption-report',
  Handset: 'handset',
  Headphones: 'headphones',
  Home: 'home',
  Info: 'info',
  InfoSymbol: 'info-symbol',
  Instagram: 'instagram',
  Letter: 'letter',
  LocationStretched: 'location-stretched',
  LocationReport: 'location-report',
  LiftReport: 'lift-report',
  MapMarker: 'map-marker',
  MenuVertical: 'menu-vertical',
  MonitorCharts: 'monitor-charts',
  Multi: 'multi',
  Pencil: 'pencil',
  PencilCell: 'pencil-cell',
  PercentsStretched: 'percents-stretched',
  Printer: 'printer',
  Phone: 'phone',
  Profile: 'profile',
  Search: 'search',
  SearchBold: 'search-bold',
  SettingsStretched: 'settings-stretched',
  Settings: 'settings',
  SignOut: 'sign-out',
  Suitcase: 'suitcase',
  SortArrows: 'sort-arrows',
  Trash: 'trash',
  Tiles: 'tiles',
  Twitter: 'twitter',
  Users: 'users',
  Upload: 'upload',
  Wifi: 'wi-fi',
};
