import React, { useEffect, useState } from 'react';
import { Switch, TimePicker } from '@ui';

import { DayTogglerValues, Time } from './types';

interface DayTogglerProps {
  day: string;
  // checked: boolean;
  index: number;
  zIndex?: number;
  values: DayTogglerValues;
  onChange: (dayIndex: number, dayValues: DayTogglerValues) => void;
}

export const DayToggler: React.FC<DayTogglerProps> = ({
  day,
  // checked,
  zIndex = 0,
  index,
  values: _values,
  onChange,
}) => {
  const [values, setValues] = useState<DayTogglerValues>(_values);

  useEffect(() => {
    onChange(index, values);
  }, [values]);

  const handleChangeStatus = () => {
    setValues({ ...values, enabled: !values.enabled });
  };

  const handleChangeStartTime = (startTime: Time) => {
    setValues({ ...values, startTime });
  };

  const handleChangeEndTime = (endTime: Time) => {
    setValues({ ...values, endTime });
  };

  return (
    <div
      className="relative flex items-center justify-between py-[10px] px-[15px] bg-white rounded-[10px]"
      style={{ zIndex }}
    >
      <div className="flex items-center">
        <Switch checked={values.enabled} onCheckedChange={handleChangeStatus} />
        <span className="ml-4 text-sm font-bold">{day}</span>
      </div>

      <div className="flex items-center translate-y-[2px]">
        <div className="mr-[10px]">
          <TimePicker
            withoutBorders
            value={values.startTime}
            onChange={handleChangeStartTime}
          />
        </div>

        <TimePicker
          withoutBorders
          value={values.endTime}
          onChange={handleChangeEndTime}
        />
      </div>
    </div>
  );
};
