import { Button, Input, Modal, ModalProps, Select } from '@ui';
import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { Icons } from '@utils';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { EditUserSchema, EditUserValues } from '../helpers/validation';
import { userRolesOptions } from '../config';

type EditUserModalProps = ModalProps & { userId: number };

export const EditUserModal: FC<EditUserModalProps> = (props) => {
  const form = useFormik<EditUserValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      areaCode: '',
      phone: '',
      role: '',
      resetPassword: '',
    },
    validationSchema: toFormikValidationSchema(EditUserSchema),
    onSubmit: console.log,
  });

  const { values, setFieldValue, handleSubmit } = form;

  return (
    <Modal {...props} className="!px-[60px] !pb-[45px] !pt-6 max-w-[438px]">
      <FormikProvider value={form}>
        <div className="flex flex-col gap-y-[10px] ">
          <div className="flex items-center justify-between gap-x-2">
            <Input<EditUserValues>
              fieldName="firstName"
              label="First Name"
              placeholder="Enter Firstname"
              inputClassName="mt-[1px] "
            />

            <Input<EditUserValues>
              fieldName="lastName"
              label="Last Name"
              placeholder="Enter Lastname"
              inputClassName="mt-[1px]"
            />
          </div>

          <Input<EditUserValues>
            fieldName="email"
            label="Email Address"
            placeholder="Enter Email Address"
          />

          <div>
            <span className="text-[9px] leading-4 text-darkgrey">
              Phone Number
            </span>

            <div className="flex items-center justify-between mt-2 gap-x-4">
              <div className="basis-1/4">
                <Input<EditUserValues>
                  fieldName="areaCode"
                  label="Area Code"
                  placeholder="+000"
                  type="number"
                />
              </div>

              <div className="basis-3/4">
                <Input<EditUserValues>
                  fieldName="phone"
                  label="Phone Number"
                  placeholder="000-0000"
                  type="number"
                />
              </div>
            </div>
          </div>

          <Select
            label="Role"
            value={values.role}
            onChange={(value) => setFieldValue('role', value)}
            options={userRolesOptions}
            placeholder="Select"
          />

          <Input<EditUserValues>
            fieldName="resetPassword"
            label="Reset Password"
            placeholder="Enter Password"
            type="password"
            inputClassName="mb-[1px]"
          />
        </div>

        <Button
          onClick={handleSubmit}
          color="customerology"
          label="Save Location Details"
          iconRight={Icons.ArrowRight}
          className="mt-8"
        />
      </FormikProvider>
    </Modal>
  );
};
