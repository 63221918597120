import React, { FC, ReactNode } from 'react';
import { Text } from '../Text';
import { Helmet } from 'react-helmet-async';
import cn from 'classnames';
import { Icon } from '../Icon';
import { Colors, Icons } from '@utils';
import { useDispatch, useSelector } from '@store';
import { cartActions, cartSelectors } from '@store/entities';
import { CartDrawer } from '@features';
import { useOuterFrameParam } from '@hooks';

interface PageProps {
  title: string;
  children: ReactNode;
  childrenClassName?: string;
  withCart?: boolean;
}

export const Page: FC<PageProps> = ({
  title,
  children,
  childrenClassName,
  withCart,
}) => {
  const withOuterFrame = useOuterFrameParam();

  const dispatch = useDispatch();
  const cartItemsCount = useSelector(cartSelectors.itemsCount);
  const isCartOpen = useSelector(cartSelectors.isOpen);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="h-full w-full flex flex-col px-24 bg-[url('/src/shared/assets/images/background.svg')] bg-contain bg-no-repeat ">
        <div className="flex items-center justify-between">
          <Text variant="h1" className="mt-0 !font-bold text-white pb-0">
            {title}
          </Text>

          {withCart && (
            <button
              onClick={() => dispatch(cartActions.openCart())}
              type="button"
              className="relative rounded-full bg-white p-[13px] mt-2 hover:opacity-80"
            >
              <Icon icon={Icons.Bag} size={21} color={Colors.Customerology} />

              {cartItemsCount > 0 && (
                <span className="absolute -bottom-1 -left-1 flex justify-center items-center rounded-full font-bold bg-ziployalty text-[10px] h-[21px] w-[21px]">
                  {cartItemsCount}
                </span>
              )}
            </button>
          )}
        </div>

        <div
          id="page-container"
          className={cn(
            'relative w-full h-full flex flex-col flex-1 px-12 pb-12 bg-white shadow-xl rounded-xl overflow-auto custom-scroll',
            {
              'max-h-[74vh]': withOuterFrame,
              'max-h-[90vh]': !withOuterFrame,
            },
            childrenClassName
          )}
        >
          {children}
        </div>

        <CartDrawer
          isOpen={isCartOpen}
          onClose={() => dispatch(cartActions.closeCart())}
        />
      </div>
    </>
  );
};
