import { SelectOption } from '@ui';

export const bandWidthOptions: SelectOption[] = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '30',
    value: 30,
  },
];

export const durationOptions: SelectOption[] = [
  {
    label: '6',
    value: 6,
  },
  {
    label: '12',
    value: 12,
  },
  {
    label: '24',
    value: 24,
  },
  {
    label: '48',
    value: 48,
  },
  {
    label: '60',
    value: 60,
  },
];

export const downloadLimitOptions: SelectOption[] = [
  {
    label: '500',
    value: 500,
  },
  {
    label: '1000',
    value: 1000,
  },
  {
    label: '5000',
    value: 5000,
  },
];
