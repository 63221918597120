import { Colors, Icons } from '@utils';
import { Button, Icon, Input } from '@ui';
import React, { FC } from 'react';
import Collapsible from 'react-collapsible';
import { AccordionHeader } from './AccordionHeader';
import { FormikProvider, useFormik } from 'formik';
import {
  NotificationSettingsSchema,
  NotificationSettingsValues,
} from '../helpers/notificationValidation';
import { toFormikValidationSchema } from 'zod-formik-adapter';

export const NotificationSettings: FC = () => {
  const form = useFormik<NotificationSettingsValues>({
    initialValues: {
      email: '',
      phone: '',
    },
    validationSchema: toFormikValidationSchema(NotificationSettingsSchema),
    onSubmit: console.log,
  });

  const { isValid, handleSubmit } = form;

  const icon = (
    <Icon
      icon={Icons.ArrowLeft}
      color={Colors.White}
      className="-rotate-90 chevron !top-6 !right-6"
      size={16}
    />
  );

  return (
    <FormikProvider value={form}>
      <Collapsible
        trigger={<AccordionHeader title="Notification Settings" />}
        transitionTime={100}
        triggerSibling={icon}
        contentOuterClassName="absolute top-[44px] z-20 w-full rounded-[10px]"
      >
        <div className="bg-plumwifi rounded-[10px] py-5 px-[30px] mb-12">
          <Input<NotificationSettingsValues>
            fieldName="email"
            label="Email"
            labelClassName="!text-white"
          />

          <div className="mt-[10px]">
            <Input<NotificationSettingsValues>
              fieldName="phone"
              label="Phone Number"
              labelClassName="!text-white"
              type="number"
            />
          </div>

          <Button
            disabled={!isValid}
            onClick={handleSubmit}
            label="Save"
            className="!bg-white !text-plumwifi w-full font-bold mt-6 !h-[49px]"
          />
        </div>
      </Collapsible>
    </FormikProvider>
  );
};
