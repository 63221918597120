/* eslint-disable react/no-unstable-nested-components */

import { Icon, Page, Select, Table, Text } from '@ui';
import React, { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Colors, Icons, formatNumber } from '@utils';
import {
  LiftReportRow,
  dataDisplayedOptions,
  liftReportTableData,
} from './config';
import { ReportingFilters } from './ReportingFilters';

export const LiftReport: React.FC = () => {
  const [displayedDataCount, setDisplayedDataCount] = useState<number>(5);
  const columnHelper = createColumnHelper<LiftReportRow>();

  const withTooltip = (title: string) => (
    <div className="flex items-center">
      <span className="mr-1">{title}</span>
      <Icon color={Colors.Customerology} icon={Icons.Info} size={25} />
    </div>
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        header: 'Name',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.locationId || null, {
        header: 'Location ID',
        cell: (cell) => (cell.getValue() ? cell.getValue() : '-'),
      }),
      columnHelper.accessor((row) => row.storeNumber, {
        header: 'Store Number',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor('totalReceipts', {
        header: () => withTooltip('Total Receipts in POS'),
        cell: (cell) => `${formatNumber(cell.getValue())}` || '-',
      }),
      columnHelper.accessor('totalAmount', {
        header: () => withTooltip('Total Amount in POS'),
        cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
      }),
      columnHelper.accessor('totalLoyaltyReceipts', {
        header: () => withTooltip('Total Loyalty Receipts'),
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.totalAmountOfLoyalty, {
        header: 'Total Amounts of Loyalty Checkins',
        cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
      }),
      columnHelper.accessor((row) => row.biggestAmount, {
        header: 'Biggest Receipts Amount in POS',
        cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
      }),
      columnHelper.accessor((row) => row.biggestLoyality, {
        header: 'Biggest Loyalty Checkin',
        cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
      }),
      columnHelper.accessor((row) => row.participationRate, {
        header: 'Participation Rate',
        cell: (cell) => `${cell.getValue()}%` || '-',
      }),
      columnHelper.accessor((row) => row.newGuest, {
        header: 'New Guest',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.percentOfNewGuest, {
        header: '% of New Guest',
        cell: (cell) => `${cell.getValue()}%` || '-',
      }),
      columnHelper.accessor((row) => row.returningGuest, {
        header: 'Returning Guest',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.percentOfReturningGuest, {
        header: '% of Returning Guest',
        cell: (cell) => `${cell.getValue()}%` || '-',
      }),
      columnHelper.accessor((row) => row.avgAnonymousCheck, {
        header: 'Avg Anonymous Check',
        cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
      }),
      columnHelper.accessor((row) => row.avgLoyaltyCheck, {
        header: 'Avg Loyalty Guest Check',
        cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
      }),
      columnHelper.accessor((row) => row.lift, {
        header: 'Lift',
        cell: (cell) => `${cell.getValue()}%` || '-',
      }),
    ],
    []
  );

  const onChangeDisplayedDataCount = (newValue: number) => {
    setDisplayedDataCount(newValue);
  };

  return (
    <Page title="Lift Report" childrenClassName="relative pt-14 pb-9 px-[61px]">
      <span className="absolute top-[26px] right-[60px] text-[#333333] opacity-25 text-[15px] leading-[22px]">
        Please scroll right for more information
      </span>

      <ReportingFilters />

      <div className="mt-4 overflow-x-auto custom-scroll">
        <Table
          theadClassName="!bg-transparent whitespace-nowrap"
          tdClassName="first:bg-[#F9F9F9] first:border-r first:border-[#DCDCDC]"
          data={liftReportTableData.slice(0, displayedDataCount)}
          columns={columns}
        />
      </div>

      <div className="flex items-center justify-start mt-7">
        <Select
          className="!w-15 mr-2"
          options={dataDisplayedOptions}
          placeholder="Data Displayed"
          value={displayedDataCount}
          onChange={onChangeDisplayedDataCount}
          menuPlacement="top"
        />
        <Text variant="body1">Data displayed</Text>
      </div>
    </Page>
  );
};
