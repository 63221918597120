import cn from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { Icon } from '../Icon';
import { Colors, Icons } from '@utils';

interface RouterSwitchProps {
  className?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

export const RouterSwitch: FC<RouterSwitchProps> = ({
  className,
  checked,
  onChange,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);

    onChange(!checked);
  };

  return (
    <div className={cn('flex ', className)}>
      <label
        htmlFor="router-switch"
        className="inline-flex items-center cursor-pointer"
      >
        <input
          id="router-switch"
          name="theme-switch"
          type="checkbox"
          className="hidden"
          checked={checked}
          onChange={handleChange}
        />
        <span
          className={`relative w-[54px] h-[27px] p-[2px] rounded-full transition-colors
            ${checked ? 'bg-[#27AE60]' : 'bg-danger'} 
            transition-all ease-linear 
          `}
        >
          <Icon
            className={cn('absolute ', {
              'left-2 mt-[6px]': checked,
              'right-1 mt-[1px]': !checked,
            })}
            icon={checked ? Icons.Done : Icons.Close}
            color={Colors.White}
            size={checked ? 13 : 22}
          />
          <div
            className={`absolute mt-[0px] flex justify-center items-center w-[23px] h-[23px] rounded-full bg-white
              ${checked ? 'right-[2px]' : 'left-[2px]'} transition-all 
              duration-1000 ease-linear
            `}
          />
        </span>
      </label>
    </div>
  );
};
