/* eslint-disable react/jsx-wrap-multilines */

import { Modal, ModalProps, Text } from '@ui';
import { Images, formatNumber } from '@utils';
import React, { FC } from 'react';
import { DetailSummary, TotalSummary } from './ui';
import { statementDetails } from '../config';
import cn from 'classnames';

export type StatementModalVariant = 'detail' | 'total';

type StatementModalProps = {
  variant: StatementModalVariant;
} & ModalProps;

export const StatementModal: FC<StatementModalProps> = ({
  isModalOpen,
  variant,
  onModalClose,
}) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      className="!max-h-[90vh] overflow-x-hidden overflow-y-auto !p-0"
    >
      <section className="px-[92px] pt-6 pb-6">
        <img
          src={Images.Toast}
          alt="toast"
          className="w-[131px] h-[42px] mx-auto"
        />

        <div className="flex items-center justify-between gap-x-5 mt-[29px]">
          <div className="text-center opacity-30">
            <Text variant="body2">Last Month</Text>
            <Text variant="body2" className="!font-bold">
              $
              {statementDetails.lastMonth}
            </Text>
          </div>

          <div className="h-[1px] w-[326px] bg-customerology flex justify-between items-center">
            <div className="w-2 h-2 rounded-full bg-customerology" />
            {variant === 'detail' && (
              <Text
                variant="body2"
                className="!text-[10px] leading-4 relative bottom-2"
              >
                Thank you for your purchase!
              </Text>
            )}
            <div className="w-2 h-2 rounded-full bg-customerology" />
          </div>

          <div className="text-center">
            <Text variant="body2">Last Month</Text>
            <Text variant="body2" className="!font-bold">
              $
              {statementDetails.thisMonth}
            </Text>
          </div>
        </div>

        <div className="mt-4">
          <div className="flex items-center justify-between">
            <Text variant="body2" className="!text-[10px] leading-4">
              Your bill is due by
              {' '}
              {statementDetails.dueBy}
            </Text>
            <Text variant="body2" className="!text-[10px] leading-4">
              Your AutoPay is scheduled for
              {' '}
              {statementDetails.scheduledFor}
            </Text>
          </div>
          <div className="flex flex-col gap-y-[10px] border border-borderColor rounded-[10px] px-6 py-[18px] mt-[5px]">
            {Object.entries(statementDetails.billDetails).map(
              ([name, price]) => (
                <div key={name} className="flex items-center justify-between">
                  <Text
                    variant="body2"
                    className={cn('!text-[10px] leading-4', {
                      '!text-danger': name === 'Deductions',
                    })}
                  >
                    {name}
                  </Text>
                  <Text
                    variant="body2"
                    className={cn('!text-[10px] leading-4', {
                      '!text-danger': name === 'Deductions',
                      '!font-bold': name === 'Total Due',
                    })}
                  >
                    $
                    {formatNumber(price)}
                  </Text>
                </div>
              )
            )}
          </div>
        </div>

        {variant === 'total' ? (
          <TotalSummary className="mt-6" />
        ) : (
          <DetailSummary className="mt-6" />
        )}
      </section>

      <div className="sticky bottom-0 left-0 w-full bg-customerology px-[92px] h-[33px] flex justify-between items-center">
        {variant === 'total' ? (
          <Text variant="body2" className="!text-[10px] leading-4 text-white">
            <b> Account:</b>
            {' '}
            1900293
          </Text>
        ) : (
          <>
            <div className="flex items-center justify-between gap-x-8">
              <Text
                variant="body2"
                className="!text-[10px] leading-4 text-white"
              >
                <b> Invoice #:</b>
                {' '}
                9881029
              </Text>

              <Text
                variant="body2"
                className="!text-[10px] leading-4 text-white"
              >
                <b> Account:</b>
                {' '}
                1900293
              </Text>

              <Text
                variant="body2"
                className="!text-[10px] leading-4 text-white"
              >
                <b> Bill Issued:</b>
                {' '}
                May 07, 2023
              </Text>
            </div>
            <Text variant="body2" className="!text-[10px] leading-4 text-white">
              Page 1
            </Text>
          </>
        )}
      </div>
    </Modal>
  );
};
