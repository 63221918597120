import { z } from 'zod';

const enterpriseFields = z.object({
  firstName: z.string({ required_error: 'Required' }),
  lastName: z.string({ required_error: 'Required' }),
  userEmail: z
    .string({ required_error: 'Email is required' })
    .email('Invalid email.'),
  phone: z.string({ required_error: 'Required' }).or(z.number()),
  enterpriseName: z.string({ required_error: 'Required' }),
  address: z.string({ required_error: 'Required' }),
  enterpriseEmail: z
    .string({ required_error: 'Email is required' })
    .email('Invalid email.'),
  category: z.string({ required_error: 'Required' }),
  website: z.string({ required_error: 'Required' }),
  avatar: z.any().optional(),
  background: z.any().optional(),
  socialLinks: z.any().array().optional(),
});

const EnterpriseSchema = enterpriseFields;

export type EnterpriseValues = z.infer<typeof EnterpriseSchema>;

export { EnterpriseSchema };
