import { Icon, Page } from '@ui';
import React, { FC, useState } from 'react';
import { productsCards } from './config';
import { Colors, Icons, Images } from '@utils';
import { AppStoreModal } from './AppStoreModal';
import { Solution } from '@types';

export const AppStore: FC = () => {
  const [modalVariant, setModalVariant] = useState<Solution>('fullfork');
  const [isModalOpen, setModalOpen] = useState(false);

  const onClick = (variant: Solution) => {
    setModalVariant(variant);
    setModalOpen(true);
  };

  return (
    <Page title="App Store" childrenClassName="relative pt-12" withCart>
      <AppStoreModal
        variant={modalVariant}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />

      <div className="grid grid-cols-[repeat(3,_minmax(300px,_1fr))] grid-rows-2 gap-x-[30px] gap-y-[25px] items-stretch shrink-0">
        {productsCards.map(({ variant, color, image, price }) => (
          <div
            key={variant}
            className="rounded-[10px] shadow-accordion-shadow hover:opacity-90 transition-all"
          >
            <button
              className="w-full"
              type="button"
              onClick={() => onClick(variant)}
            >
              <img
                src={image}
                alt={variant}
                className="w-full cursor-pointer hover:opacity-80"
              />
            </button>

            <div className="flex justify-between items-center px-[22px] pt-[3px] pb-[10px]">
              <button
                type="button"
                onClick={() => onClick(variant)}
                className="flex items-center text-white gap-x-1 rounded-full py-[6px] px-[18px] h-[33px] whitespace-nowrap cursor-pointer hover:opacity-80 active:brightness-75"
                style={{
                  backgroundColor: color,
                }}
              >
                Get More Info
                <Icon icon={Icons.ArrowRight} size={18} color={Colors.White} />
              </button>

              <span className="h-10 px-[21px] py-[6px] text-[19px] leading-7 rounded-full font-bold shadow-card-shadow cursor-default">
                {`$${price}`}
              </span>
            </div>
          </div>
        ))}

        <img
          src={Images.AppsComingSoon}
          alt="app coming soon"
          className="brightness-150"
        />
      </div>
    </Page>
  );
};
