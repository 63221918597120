import React from 'react';
import { Icon } from '@ui';
import { Colors, Icons } from '@utils';

export const Header: React.FC = () => {
  return (
    <div className="w-full bg-white shadow-sm">
      <div className="h-[95px] flex justify-end items-center mr-16">
        <div className="h-[50px] w-[50px] flex justify-center items-center rounded-full bg-fullfork mr-6">
          <Icon icon={Icons.InfoSymbol} color={Colors.White} />
        </div>

        <div>
          <p className="font-bold mb-1">Restaurant</p>
          <p>Location</p>
        </div>
      </div>
    </div>
  );
};
