import { Text } from '@ui';
import React, { FC } from 'react';

interface AccordionHeaderProps {
  title: string;
}

export const AccordionHeader: FC<AccordionHeaderProps> = ({ title }) => {
  return (
    <button
      type="button"
      className="bg-white rounded-[10px] py-[15px] px-[19px] flex justify-between items-center w-full shadow-accordion-shadow"
    >
      <Text variant="h5" className="!font-bold">
        {title}
      </Text>
    </button>
  );
};
