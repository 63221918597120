import { Icon } from '@ui';
import { Colors, Icons } from '@utils';
import cn from 'classnames';
import React, { FC, useState } from 'react';

interface CarouselProps {
  images: string[];
  color: string;
}

export const Carousel: FC<CarouselProps> = ({ images, color }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const onPrev = () => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(images.length - 1);

      return;
    }

    setCurrentImageIndex(currentImageIndex - 1);
  };

  const onNext = () => {
    if (currentImageIndex === images.length - 1) {
      setCurrentImageIndex(0);

      return;
    }

    setCurrentImageIndex(currentImageIndex + 1);
  };

  return (
    <div className="flex flex-col gap-y-[13px] w-[275px]">
      <div
        className={cn(
          'relative flex justify-center items-center w-[273px] h-[235px] bg-lightgrey rounded-[10px]',
          {
            'px-8': currentImageIndex === 0,
          }
        )}
      >
        <img
          src={images[currentImageIndex]}
          alt="logo"
          className="object-cover"
        />

        <button
          type="button"
          onClick={onPrev}
          className="absolute -left-[15px] top-[calc(50%-15px)] w-[30px] h-[30px] flex justify-center items-center rounded-full bg-white shadow-lg hover:brightness-90 active:brightness-105"
        >
          <Icon
            icon={Icons.ArrowRightLong}
            className="rotate-180"
            color={Colors.Black}
            size={15}
          />
        </button>

        <button
          type="button"
          onClick={onNext}
          className="absolute -right-[15px] top-[calc(50%-15px)] w-[30px] h-[30px] flex justify-center items-center rounded-full bg-white shadow-lg hover:brightness-90 active:brightness-105"
        >
          <Icon icon={Icons.ArrowRightLong} color={Colors.Black} size={15} />
        </button>
      </div>

      <div className="flex justify-between items-center gap-x-[10px]">
        {images.map((image, index) => (
          <button
            type="button"
            onClick={() => setCurrentImageIndex(index)}
            className={cn(
              'rounded-[5px] border-[3px] border-borderColor w-[85px] h-[71px]',
              {
                'bg-lightgrey px-2': index === 0,
              }
            )}
            style={{
              borderColor:
                index === currentImageIndex ? color : Colors.BorderColor,
            }}
          >
            <img
              src={image}
              alt="screenshot"
              className="object-cover w-auto h-auto"
            />
          </button>
        ))}
      </div>
    </div>
  );
};
