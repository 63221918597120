import React, { FC } from 'react';
import { useHover } from '@hooks';
import cn from 'classnames';
import { Icon } from '@ui';
import { Colors, Icons } from '@utils';

interface SocialLinkProps {
  name: string;
  link: string;
  icon: string;
  onDelete: () => void;
}

export const SocialLink: FC<SocialLinkProps> = ({
  name,
  link,
  icon,
  onDelete,
}) => {
  const [ref, isHovered] = useHover();

  return (
    <span ref={ref} className="flex items-center justify-start gap-x-2 ">
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="flex items-center hover:underline"
      >
        <img src={icon} alt="social-icon" className="w-4 h-4 mr-[15px]" />

        {name}
      </a>

      <button
        type="button"
        onClick={onDelete}
        className={cn('hover:opacity-80 active:brightness-75', {
          'opacity-0': !isHovered,
        })}
      >
        <Icon
          icon={Icons.Close}
          color={Colors.Danger}
          size={16}
          className="mb-[1px]"
        />
      </button>
    </span>
  );
};
