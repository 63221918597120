import cn from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Icon } from '../Icon';
import { Colors, Icons, formatDate } from '@utils';
import { startOfMonth, startOfWeek, sub, subDays } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import { useClickAway } from '@hooks';

import { Caption, DayContent } from './Custom';
import { PeriodSelect } from './PeriodSelect';
import { PeriodSelectItem, periodsItems } from './PeriodSelect/config';

import { match } from 'ts-pattern';
import { css } from './styles';

const today = new Date();

const defaultSelected: DateRange = {
  from: sub(today, { days: 7 }),
  to: today,
};

const defaultPeriod = periodsItems.at(-1) as PeriodSelectItem;

interface PeriodPickerProps {
  className?: string;
  label?: string;
  value: DateRange;
  onChange: (value: DateRange) => void;
}

export const PeriodPicker: FC<PeriodPickerProps> = ({
  className,
  label,
  value,
  onChange,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isPickerVisible, setPickerVisible] = useState(false);

  const [dateFrom, setDateFrom] = useState(value.from || defaultSelected.from);
  const [dateTo, setDateTo] = useState(value.to || defaultSelected.to);
  const [period, setPeriod] = useState<PeriodSelectItem>(defaultPeriod);

  const ref = useClickAway(() => setPickerVisible(false), [buttonRef]);

  useEffect(() => {
    onChange({ from: dateFrom, to: dateTo });
  }, [dateFrom, dateTo]);

  useEffect(() => {
    match({ days: period.days, periodSize: period.currentPeriodSize })
      .with({ periodSize: false }, () => {
        setDateFrom(subDays(today, period.days as number));
      })
      .with({ periodSize: 'week' }, () => {
        setDateFrom(startOfWeek(today));
      })
      .with({ periodSize: 'month' }, () => {
        setDateFrom(startOfMonth(today));
      });
  }, [period]);

  return (
    <div className="relative">
      <style>{css}</style>
      <button
        ref={buttonRef}
        type="button"
        onClick={() => setPickerVisible(!isPickerVisible)}
        className={cn(
          'relative w-min bg-white border border-borderColor rounded-[5px]',
          className
        )}
      >
        <div className="h-[40px] flex flex-row justify-between items-center px-3">
          <div className="flex">
            <div>
              <Icon icon={Icons.Calendar} color={Colors.DarkGrey} size={18} />
            </div>

            <span className="text-[13px] text-darkgrey uppercase ml-2 pt-1">
              {formatDate(dateFrom)}
            </span>
          </div>

          <span className="text-[13px] text-darkgrey mx-9 pt-1">to</span>

          <Icon icon={Icons.Calendar} color={Colors.DarkGrey} size={18} />

          <span className="text-[13px] text-darkgrey uppercase ml-2 pt-1">
            {formatDate(dateTo)}
          </span>
        </div>
      </button>

      {isPickerVisible && (
        <div
          ref={ref}
          className={cn(
            'absolute z-10 top-[48px] right-0 py-5 px-[30px] rounded-[10px] bg-white shadow-md flex shad justify-between items-center',
            { 'top-[65px]': !!label }
          )}
          style={{
            boxShadow:
              '0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <PeriodSelect value={period} onChange={setPeriod} />

          <DayPicker
            className="ml-5"
            mode="single"
            onSelect={setDateFrom}
            selected={dateFrom}
            components={{ Caption, DayContent }}
            month={dateFrom}
            showOutsideDays
          />

          <DayPicker
            disabled={{ after: today }}
            mode="single"
            onSelect={setDateTo}
            selected={dateTo}
            components={{ Caption, DayContent }}
            defaultMonth={today}
            showOutsideDays
          />
        </div>
      )}
    </div>
  );
};
