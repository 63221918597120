import { Images } from '@utils';
import React, { FC, useState } from 'react';
import { Dashboard } from './Dashboard';
import { PeriodPicker, Select } from '@ui';
import { chartData, chartOptions } from '../config';
import { noop } from '@tanstack/react-table';
import { LineChart, RoutersList } from '@features';
import { DateRange } from 'react-day-picker';
import { sub } from 'date-fns';

const today = new Date();

const defaultSelected: DateRange = {
  from: sub(today, { days: 7 }),
  to: today,
};

export const Overview: FC = () => {
  const [period, setPeriod] = useState<DateRange>(defaultSelected);

  return (
    <main className="flex items-start gap-x-[50px] justify-between">
      <section className="w-[294px] shrink-0">
        <img src={Images.PlumWifiLogo} alt="plum-wifi" />

        <RoutersList className="mt-10" />
      </section>
      <section className="w-full h-auto basis-[75%]">
        <Dashboard />

        <div className="flex flex-col max-h-[500px] mt-[50px] flex-1 bg-white border border-lightgrey rounded-[10px] shadow-panel-shadow py-8 pl-4 pr-14">
          <div className="flex justify-between w-full mb-5">
            <Select
              className="ml-9"
              type="filter"
              options={chartOptions}
              value={0}
              onChange={noop}
            />

            <PeriodPicker value={period} onChange={setPeriod} />
          </div>

          <div className="h-400">
            <LineChart
              data={chartData || []}
              xDataKey="date"
              yDataKey="counter"
              height={300}
            />
          </div>
        </div>
      </section>
    </main>
  );
};
