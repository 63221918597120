/* eslint-disable consistent-return */

import { useState, useEffect, useRef, MutableRefObject } from 'react';

export const useHover = (): [MutableRefObject<null>, boolean] => {
  const [hovering, setHovering] = useState(false);
  const targetRef = useRef<any>(null);

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  useEffect(() => {
    const targetElement = targetRef.current;

    if (targetElement) {
      targetElement.addEventListener('mouseenter', handleMouseEnter);
      targetElement.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        targetElement.removeEventListener('mouseenter', handleMouseEnter);
        targetElement.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);

  return [targetRef, hovering];
};
