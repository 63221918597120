import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import {
  EnterpriseSchema,
  EnterpriseValues,
} from './helpers/enterpriseValidation';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FileInputContainer, Icon, Text } from '@ui';
import { SocialLink } from './config';
import { Colors, Icons, Images, formatPhoneNumber } from '@utils';
import {
  AddSocialLink,
  EditAvatar,
  EnterpriseForm,
  FieldInfo,
  SocialLinks,
} from './ui';

export const Enterprise: FC = () => {
  const form = useFormik<EnterpriseValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      userEmail: '',
      phone: '',
      enterpriseName: '',
      address: '',
      enterpriseEmail: '',
      category: '',
      website: '',
      avatar: null,
      background: null,
      socialLinks: [],
    },
    validationSchema: toFormikValidationSchema(EnterpriseSchema),
    onSubmit: console.log,
  });

  const { values, setFieldValue } = form;

  const background = values.background
    ? URL.createObjectURL(values.background)
    : Images.NoImage;

  const avatar = values.avatar
    ? URL.createObjectURL(values.avatar)
    : Images.NoAvatar;

  return (
    <FormikProvider value={form}>
      <section className="flex items-start justify-between gap-x-[92px] pb-[57px]">
        <div className="basis-[47%] flex flex-col items-start gap-y-[26px] px-3">
          <EnterpriseForm className="mt-[28px] w-full" />

          <div className="w-full">
            <Text variant="body2" className="mb-2">
              Socials
            </Text>

            <AddSocialLink
              onAdd={(link) => {
                setFieldValue('socialLinks', values.socialLinks?.concat(link));
              }}
            />
          </div>
        </div>

        <div className="relative basis-[53%] flex flex-col items-center rounded-[10px] shadow-panel-shadow bg-no-repeat -mt-[47px] bg-contain w-full px-[52px] pb-9 overflow-hidden">
          <img
            src={background}
            alt="background"
            className="absolute top-0 left-0 w-full max-h-[35%] object-cover"
          />

          <div className="relative ml-auto -mr-[52px] mt-[11px]">
            <Icon
              icon={Icons.Info}
              color={Colors.Customerology}
              size={24}
              className="absolute top-[1px] -left-7"
            />
            <FileInputContainer<EnterpriseValues>
              fieldName="background"
              className="relative z-10"
            >
              <span className="flex justify-between items-center  gap-x-3 w-min  rounded-l-[5px] whitespace-nowrap bg-white bg-opacity-50 px-[9px] py-[5px] text-[11px] leading-4">
                <Icon icon={Icons.Camera} size={14} color={Colors.Black} />
                Update Cover Image
              </span>
            </FileInputContainer>
          </div>

          <div className="relative z-10 flex items-center gap-x-4 p-[10px] w-3/4 h-[115px] rounded-l-[60px] rounded-r-[10px] bg-white shadow-lg mt-[18%] ">
            <Icon
              icon={Icons.Info}
              color={Colors.Customerology}
              size={24}
              className="absolute -left-7 top-[48px]"
            />

            <EditAvatar<EnterpriseValues> fieldName="avatar" image={avatar} />

            <div className="w-[60%]">
              <Text
                variant="h3"
                className="w-full !text-[25px] leading-[37px] !font-bold overflow-hidden whitespace-nowrap text-ellipsis"
              >
                {values.enterpriseName || 'Enterpise Name'}
              </Text>

              <Text
                variant="body1"
                className="text-[14px] leading-[21px] overflow-hidden line-clamp-2 -webkit-line-clamp-2 -webkit-box-orient-vertical"
              >
                Chilled-out eatery showcasing California-inspired brunch, New
                American plates & vegan options.
              </Text>
            </div>
          </div>

          <div className="flex items-start justify-center gap-x-10 w-full mt-4 pt-4 pb-[25px] border-b border-borderColor">
            <FieldInfo title="Enterprise Address" icon={Icons.MapMarker}>
              {values.address || 'There is no address'}
            </FieldInfo>

            <FieldInfo title="Phone Number" icon={Icons.Handset}>
              {formatPhoneNumber(+values.phone) || 'There is no phone number'}
            </FieldInfo>
          </div>

          <div className="flex items-start justify-center gap-x-10 w-full mt-4 pt-4 pb-[25px] border-b border-borderColor">
            <FieldInfo title="Email Address" icon={Icons.Letter}>
              {values.enterpriseEmail || 'There is no email'}
            </FieldInfo>

            <FieldInfo title="Category" icon={Icons.Multi}>
              {values.category || 'There is no category'}
            </FieldInfo>
          </div>

          <div className="flex items-start justify-center gap-x-10 w-full mt-4 pt-4 pb-[25px]">
            <FieldInfo title="Websites" icon={Icons.GlobeStretched}>
              {values.website || 'There is no website'}
            </FieldInfo>

            <SocialLinks
              links={values.socialLinks as SocialLink[]}
              onChange={(value) => setFieldValue('socialLinks', value)}
            />
          </div>
        </div>
      </section>
    </FormikProvider>
  );
};
