import React, { TextareaHTMLAttributes } from 'react';
import { useField } from 'formik';
import cn from 'classnames';

interface TextareaProps<Form>
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  fieldName?: keyof Form;
  label?: string;
  limit?: number;
}

export const Textarea = <Form extends object>({
  fieldName,
  label,
  limit,
  ...rest
}: TextareaProps<Form>) => {
  const [{ value, onChange }, { error, touched }] = useField({
    name: fieldName as string,
  });

  return (
    <div>
      {!!label && (
        <p className="block text-[11px] leading-[16px] text-darkgrey mb-[5px]">
          {label}
        </p>
      )}

      <textarea
        name={fieldName as string}
        value={value}
        onChange={onChange}
        maxLength={limit}
        className={cn(
          'resize-none w-full h-[80px] flex items-center px-[10px] pt-[8px] text-[13px] outline-none border border-borderColor rounded-[5px] placeholder:text-darkgrey',
          {
            'border-danger': error && touched,
          },
          rest.className
        )}
        {...rest}
      />

      {!!limit && (
        <span className="italic text-[11px] leading-[16px] text-darkgrey mt-[6px] pl-[1px]">
          {`Limit ${limit} characters.`}
        </span>
      )}
    </div>
  );
};
