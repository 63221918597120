import { Icon, LoaderContainer, Table, Text } from '@ui';
import React, { FC, useMemo, useState } from 'react';
import { EditUserModal } from '../Users/EditUserModal';
import { createColumnHelper, noop } from '@tanstack/react-table';
import { RolesTableRow, rolesTableData } from './config';
import { Icons } from '@utils';
import { AddRoleModal } from './AddRoleModal';

export const Roles: FC = () => {
  const [isUserModalOpen, setUserModalOpen] = useState(false);
  const [isRoleModalOpen, setRoleModalOpen] = useState(false);

  const [roleId, setRoleId] = useState<number>(0);

  const columnHelper = createColumnHelper<RolesTableRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.role, {
        header: 'Role',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.permissionSet, {
        header: 'Permission Set',
        cell: (cell) => cell.getValue() || '-',
      }),
    ],
    []
  );

  const onEdit = (id: number) => {
    setRoleId(id);
    setUserModalOpen(true);
  };

  return (
    <section className="h-full pt-11">
      <div className="flex flex-col justify-start h-full">
        <div className="flex items-center justify-between mb-5">
          <Text variant="h4">List of Roles</Text>

          <button
            type="button"
            onClick={() => setRoleModalOpen(true)}
            className="flex items-center justify-start hover:opacity-80 active:opacity-100"
          >
            <Text variant="h4">Add</Text>
            <Icon icon={Icons.Close} className="ml-2 rotate-45" size={19} />
          </button>
        </div>

        <LoaderContainer loading={false}>
          <div className="overflow-auto custom-scroll max-h-[350px] h-auto">
            {rolesTableData?.length ? (
              <Table
                className="!m-0"
                withEdit
                withStatusSwitch={false}
                withControlPanel={false}
                data={rolesTableData || []}
                columns={columns}
                uniqueKey="role"
                statusKey="role"
                isError={false}
                onEdit={onEdit}
                onDelete={noop}
                onToggle={noop}
              />
            ) : (
              <div className="flex items-center justify-center w-full pt-10 pb-14">
                There is no data
              </div>
            )}
          </div>
        </LoaderContainer>
      </div>

      <AddRoleModal
        withTitle
        isModalOpen={isRoleModalOpen}
        onModalClose={() => setRoleModalOpen(false)}
        title="Add a New Role"
      />

      <EditUserModal
        userId={roleId}
        withTitle
        isModalOpen={isUserModalOpen}
        onModalClose={() => setUserModalOpen(false)}
        title="Edit User Details"
      />
    </section>
  );
};
