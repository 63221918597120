import React from 'react';
import ReactModal from 'react-modal';

import './style.css';
import { ModalCloseButton } from './Custom';
import cn from 'classnames';
import { AllOrNothing } from '@types';
import { Text } from '../Text';

interface BaseModalProps {
  isModalOpen: boolean;
  className?: string;
  withNoCloseButton?: boolean;
  onModalClose: () => void;
}

export type ModalProps = BaseModalProps &
  AllOrNothing<{
    withTitle: boolean;
    title?: string;
  }>;

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  isModalOpen,
  className,
  withNoCloseButton,
  withTitle,
  title,
  onModalClose,
  children,
}) => {
  return (
    <ReactModal
      isOpen={isModalOpen}
      className={cn(
        'react-modal-content custom-scroll',
        !withTitle ? className : '',
        {
          '!p-0': withTitle,
        }
      )}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick
      onRequestClose={onModalClose}
    >
      {!withNoCloseButton && <ModalCloseButton onModalClose={onModalClose} />}

      {withTitle && (
        <div className="py-2 px-8 text-left text-white rounded-t-[8px] bg-customerology">
          <Text variant="h4">{title}</Text>
        </div>
      )}

      <div
        className={cn(
          { 'p-[30px] overflow-auto': withTitle },
          withTitle ? className : ''
        )}
      >
        {children}
      </div>
    </ReactModal>
  );
};
