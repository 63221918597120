import React, { useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  FETCH_OFFERS_LIMIT,
  Offer,
  useDeleteOfferMutation,
  useGetOfferCountQuery,
  useGetOffersQuery,
  useToggleOfferStateMutation,
} from '@api';
import {
  Button,
  Counter,
  Loader,
  LoaderContainer,
  SearchInput,
  Select,
  Table,
} from '@ui';
import { match } from 'ts-pattern';
import { MOCKED_ENTERPRISE_ID } from '@utils';
import { Pagination } from '@features';
import cn from 'classnames';

import { SearchOfferBy, offersOptions } from '../config';

import { OfferModal } from './modals';
import { useToast } from '@hooks';
import { searchOffers } from './modals/helpers';

export const Offers: React.FC = () => {
  const { showErrorToast } = useToast();

  const [searchBy, setSearchBy] = useState<SearchOfferBy>('title');
  const [searchValue, setSearchValue] = useState('');

  const [isAddOfferModalOpen, setAddOfferModalOpen] = useState(false);
  const [offerId, setOfferId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [isActiveOffers, setActiveOffers] = useState<boolean | null>(null);

  const { data: offersCount, isLoading: isLoadingOffersCount } =
    useGetOfferCountQuery({
      id: MOCKED_ENTERPRISE_ID,
      active: null,
    });

  const { data: activeOffersCount, isLoading: isLoadingActiveOffersCount } =
    useGetOfferCountQuery({
      id: MOCKED_ENTERPRISE_ID,
      active: true,
    });

  const [deleteOffer, { isLoading: isDeleting, isError: isErrorDeleting }] =
    useDeleteOfferMutation();
  const [
    toggleOfferStatus,
    { isLoading: isToggling, isError: isErrorToggling },
  ] = useToggleOfferStateMutation();

  const {
    data: offers,
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetOffersQuery(
    {
      id: MOCKED_ENTERPRISE_ID,
      pageNum: currentPage,
      active: isActiveOffers,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [isActiveOffers]);

  const inActiveOffersCount =
    offersCount && activeOffersCount ? offersCount - activeOffersCount : 0;

  const getPageCount = () =>
    match(isActiveOffers)
      .with(null, () =>
        offersCount ? Math.ceil(offersCount / FETCH_OFFERS_LIMIT) : 1
      )
      .with(true, () =>
        activeOffersCount
          ? Math.ceil(activeOffersCount / FETCH_OFFERS_LIMIT)
          : 1
      )
      .with(false, () =>
        inActiveOffersCount
          ? Math.ceil(inActiveOffersCount / FETCH_OFFERS_LIMIT)
          : 1
      )
      .otherwise(() => 0);

  useEffect(() => {
    setPageCount(getPageCount());
  }, [isActiveOffers, offersCount, activeOffersCount]);

  useEffect(() => {
    if (currentPage >= pageCount) {
      setCurrentPage(pageCount - 1);
    }
  }, [pageCount]);

  const columnHelper = createColumnHelper<Offer>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.title, {
        header: 'Title',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.description, {
        header: 'Description',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.offerCode, {
        header: 'Offer code',
        cell: (cell) => cell.getValue() || '-',
      }),
    ],
    []
  );

  const onEdit = (id: number) => {
    setOfferId(id);
    setAddOfferModalOpen(true);
  };

  const onAddClick = () => {
    setOfferId(null);
    setAddOfferModalOpen(true);
  };

  const isTableLoading = isFetching || isToggling || isDeleting;

  useEffect(() => {
    if (isErrorToggling) {
      showErrorToast();
    }
  }, [isErrorToggling]);

  useEffect(() => {
    if (isErrorDeleting) {
      showErrorToast();
    }
  }, [isErrorDeleting]);

  const resultOffers = useMemo(
    () => searchOffers(offers, searchValue, searchBy),
    [offers, searchValue, searchBy]
  );

  return match({
    isLoading: isLoading || isLoadingActiveOffersCount || isLoadingOffersCount,
    isOffersRequested: !isUninitialized,
  })
    .with({ isLoading: true }, () => <Loader centered withTopIndent />)
    .with({ isOffersRequested: true }, () => (
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="flex flex-row items-center justify-between mx-3 mb-6">
            <div className="flex flex-row">
              <Counter
                className={cn('mr-7 opacity-50 hover:opacity-75', {
                  '!opacity-100': isActiveOffers === null,
                })}
                onClick={() => setActiveOffers(null)}
                count={offersCount || 0}
                label="Total Offers"
              />

              <Counter
                className={cn('mr-7 opacity-50 hover:opacity-75', {
                  '!opacity-100': isActiveOffers,
                })}
                onClick={() => setActiveOffers(true)}
                count={activeOffersCount || 0}
                label="Active Offers"
              />

              <Counter
                className={cn('opacity-50 hover:opacity-75', {
                  '!opacity-100': isActiveOffers === false,
                })}
                onClick={() => setActiveOffers(false)}
                count={inActiveOffersCount}
                label="Inactive Offers"
              />
            </div>

            <Button type="add" label="Add Offer" onClick={onAddClick} />
          </div>

          <div className="flex items-center w-full py-5 border px-4 mb-4 border-[#DCDCDC] rounded-md">
            <div className="basis-1/6 mr-[10px]">
              <Select
                value={searchBy}
                options={offersOptions}
                onChange={setSearchBy}
                withoutBorder
              />
            </div>

            <div className="grow">
              <SearchInput
                placeholder="Search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>

          <LoaderContainer loading={isTableLoading}>
            <div className="overflow-auto custom-scroll max-h-[275px] h-auto">
              {resultOffers?.length ? (
                <Table
                  withControlPanel
                  className="!m-0"
                  withStatusSwitch={false}
                  withEdit={false}
                  data={resultOffers || []}
                  columns={columns}
                  uniqueKey="id"
                  statusKey="enabled"
                  isError={isErrorDeleting || isErrorToggling}
                  onEdit={onEdit}
                  onDelete={deleteOffer}
                  onToggle={toggleOfferStatus}
                />
              ) : (
                <div className="flex items-center justify-center w-full pt-10 pb-14">
                  There is no data
                </div>
              )}
            </div>
          </LoaderContainer>
        </div>

        <Pagination
          className="mx-auto mt-12"
          page={currentPage}
          setPage={setCurrentPage}
          pageCount={pageCount}
        />

        <OfferModal
          offerId={offerId}
          isModalOpen={isAddOfferModalOpen}
          setOfferId={setOfferId}
          onModalClose={() => setAddOfferModalOpen(false)}
        />
      </div>
    ))
    .otherwise(() => null);
};
