import { combineReducers } from '@reduxjs/toolkit';

import {
  locationApi,
  offerApi,
  overviewApi,
  plumWifiApi,
  sThreeApi,
} from '../api';
import { cartSlice, plumWifiSlice } from './entities';

const rootReducer = combineReducers({
  cart: cartSlice.reducer,
  plumWifi: plumWifiSlice.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [offerApi.reducerPath]: offerApi.reducer,
  [overviewApi.reducerPath]: overviewApi.reducer,
  [plumWifiApi.reducerPath]: plumWifiApi.reducer,
  [sThreeApi.reducerPath]: sThreeApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export { rootReducer };
