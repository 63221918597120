import { cartActions } from '@store/entities';
import { useDispatch } from '@store';
import { Button, Text } from '@ui';
import { Icons, Images } from '@utils';
import React, { FC, useState } from 'react';

interface BuyRouterProps {
  onPurchase: () => void;
}

export const BuyRouter: FC<BuyRouterProps> = ({ onPurchase }) => {
  const dispatch = useDispatch();

  const [count, setCount] = useState(1);

  const addToCart = () => {
    dispatch(
      cartActions.addItem({
        name: 'Plum WiFi Router',
        price: 99,
        count,
        image: Images.PlumWifiRouter,
      })
    );

    onPurchase();
  };

  return (
    <section className="flex justify-between items-center gap-x-[34px]">
      <div className="bg-lightgrey px-[10px] py-[62px] rounded-[10px] w-[249px] h-[317px]">
        <img src={Images.PlumWifiRouter} alt="router" />
      </div>

      <div>
        <div className="flex items-center justify-between">
          <Text variant="h1" className="!text-black !font-bold">
            Plum WiFi
            <br />
            {' '}
            Router
          </Text>

          <div className="bg-lightgrey rounded-[5px] px-[18px] py-[10px] text-center text-plumwifi">
            <span className="block text-[12px] leading-[18px]">Pricing</span>
            <span className="text-[45px] leading-[67px] font-bold">$99</span>
          </div>
        </div>

        <p className="text-base mt-[36px] max-w-[465px]">
          Lorem ipsum dolor sit amet consectetur. Sagittis nisl viverra netus
          orci non erat malesuada. A id tortor ullamcorper congue. Iaculis
          ultrices vitae tincidunt nulla nullam laoreet quam sed lobortis.
          Viverra tortor justo sagittis enim. A nunc vel quam purus
        </p>

        <div className="flex items-center justify-between mt-[69px]">
          <div className="flex justify-between items-center gap-x-[9px]">
            <button
              onClick={() => setCount(count > 1 ? count - 1 : 1)}
              type="button"
              className="w-5 h-5 text-white rounded-full bg-plumwifi hover:opacity-80 active:opacity-100"
            >
              -
            </button>

            <span className="w-[28px] h-[28px] border border-[#696868] rounded-full flex justify-center items-center">
              {count}
            </span>

            <button
              onClick={() => setCount(count < 100 ? count + 1 : 100)}
              type="button"
              className="w-5 h-5 text-white rounded-full bg-plumwifi hover:opacity-80 active:opacity-100"
            >
              +
            </button>
          </div>

          <Button
            onClick={addToCart}
            label="Purchase Now"
            color="plum-wifi"
            iconRight={Icons.ArrowRightLong}
            className="ml-auto !rounded-full"
          />
        </div>
      </div>
    </section>
  );
};
