import { z } from 'zod';

const billingDetailsFields = z.object({
  company: z.string(),
  fullName: z.string(),
  addressLine1: z.string(),
  addressLine2: z.string(),
  city: z.string(),
  state: z.string(),
  postCode: z.string(),
  phone: z.string(),
  email: z.string(),
});

const BillingDetailsSchema = billingDetailsFields;

export type BillingDetailsValues = z.infer<typeof BillingDetailsSchema>;

export { BillingDetailsSchema };
