import { Page, Select, Table, Text } from '@ui';
import React, { useMemo, useState } from 'react';
import { ReportingFilters } from './ReportingFilters';
import {
  LocationReportRow,
  dataDisplayedOptions,
  locationReportTableData,
} from './config';
import { createColumnHelper } from '@tanstack/react-table';
import { formatNumber } from '@utils';

export const LocationReport: React.FC = () => {
  const [displayedDataCount, setDisplayedDataCount] = useState<number>(5);
  const columnHelper = createColumnHelper<LocationReportRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.location, {
        header: 'Location',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.barCode, {
        header: 'Bar Code',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.onlineOrders, {
        header: 'Online Orders',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.spend, {
        header: 'Spend',
        cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
      }),
      columnHelper.accessor((row) => row.redemptions, {
        header: 'Redemptions',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.uniqueRedeemers, {
        header: 'Unique Redeems',
        cell: (cell) => cell.getValue() || '-',
      }),
    ],
    []
  );

  const onChangeDisplayedDataCount = (newValue: number) => {
    setDisplayedDataCount(newValue);
  };

  return (
    <Page title="Location Report" childrenClassName="pt-14">
      <ReportingFilters />

      <div className="mt-4 overflow-x-auto custom-scroll">
        <Table
          theadClassName="!bg-transparent whitespace-nowrap"
          tdClassName="!px-10 !py-5"
          data={locationReportTableData.slice(0, displayedDataCount)}
          columns={columns}
        />
      </div>

      <div className="flex items-center justify-start mt-7">
        <Select
          className="!w-15 mr-2"
          options={dataDisplayedOptions}
          placeholder="Location"
          value={displayedDataCount}
          onChange={onChangeDisplayedDataCount}
          menuPlacement="top"
        />
        <Text variant="body1">Data displayed</Text>
      </div>
    </Page>
  );
};
