import { useSelector } from '@store';
import { cartSelectors } from '@store/entities';
import { Drawer, Text } from '@ui';
import React, { FC, useState } from 'react';
import { CartItem } from './CartItem';

interface CartDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CartDrawer: FC<CartDrawerProps> = ({ isOpen, onClose }) => {
  const [couponCode, setCouponCode] = useState('');

  const { items, total } = useSelector(cartSelectors.cart);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      childrenClassName="pl-11 py-[46px]"
    >
      <Text variant="h1" className="!font-bold">
        Shopping Cart
      </Text>

      <ul className="mt-9 h-[60%] overflow-y-auto pr-11">
        {items.map((item) => (
          <CartItem key={item.id} {...item} />
        ))}
      </ul>

      <div className="pr-11">
        <div className="flex items-center justify-between px-[15px] mt-[27px]">
          <span className="text-[17px]">Coupon Code</span>
          <input
            type="text"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className="h-[46px] border border-borderColor rounded-[5px] px-4 outline-none w-[146px]"
          />
        </div>

        <div className="flex items-center justify-between px-[15px] mt-[27px]">
          <span className="text-[17px]">Subtotal</span>
          <span className="text-[17px] font-bold">{`$${total}`}</span>
        </div>

        <button
          type="button"
          className="flex items-center justify-center h-[47px] w-full mt-8 rounded-full bg-success active:brightness-75 hover:brightness-110"
        >
          <Text variant="h5" className="!text-white">
            Checkout
          </Text>
        </button>

        <button
          type="button"
          className="flex items-center justify-center h-[47px] w-full mt-4 rounded-full bg-customerology active:brightness-75 hover:brightness-110"
        >
          <Text variant="h5" className="!text-white">
            Continue Shopping
          </Text>
        </button>
      </div>
    </Drawer>
  );
};
