import React, { ReactNode } from 'react';
import { Icon } from '@ui';
import { Colors, Icons, Images } from '@utils';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import Collapsible from 'react-collapsible';

import { SidebarItem as ISidebarItem, sidebarItems } from './config';
import { SidebarItem } from './SidebarItem';

export const Sidebar: React.FC = () => {
  const { pathname } = useLocation();

  const renderMenuItems = (
    items: ISidebarItem[],
    nested?: boolean
  ): ReactNode[] =>
    items.map((item) => {
      const isActive = pathname.includes(item.route as string);

      if (item.children) {
        const icon = (
          <Icon
            icon={Icons.ArrowLeft}
            color={Colors.White}
            className="-rotate-90 cursor-pointer chevron"
          />
        );

        return (
          <Collapsible
            key={item.icon}
            trigger={<SidebarItem {...item} isActive={isActive} />}
            transitionTime={100}
            triggerSibling={icon}
          >
            {renderMenuItems(item.children, true)}
          </Collapsible>
        );
      }

      return (
        <SidebarItem
          key={item.icon}
          className={cn({ 'pl-8': nested })}
          {...item}
          isActive={isActive}
        />
      );
    });

  return (
    <div className="w-[250px] bg-customerology h-screen">
      <div className="h-[95px] flex justify-center items-center">
        <img
          src={Images.CustomerologyLogo}
          alt="Logo"
          width={204}
          height={34}
        />
      </div>

      <div>{renderMenuItems(sidebarItems)}</div>
    </div>
  );
};
