import React, { InputHTMLAttributes } from 'react';
import { Icons } from '@utils';
import { useField } from 'formik';
import cn from 'classnames';

import { Icon } from '../Icon';

import './style.css';

interface InputProps<Form> extends InputHTMLAttributes<HTMLInputElement> {
  fieldName?: keyof Form;
  label?: string;
  search?: boolean;
  inputClassName?: string;
  wrapperClassName?: string;
  labelClassName?: string;
}

export const Input = <Form extends object>({
  fieldName,
  label,
  search = false,
  inputClassName,
  wrapperClassName,
  labelClassName,
  ...rest
}: InputProps<Form>) => {
  const [{ value, onChange, onBlur }, { error, touched }] = useField({
    name: fieldName as string,
  });

  return (
    <div className={rest.className}>
      {!!label && (
        <p
          className={cn(
            'block text-[11px] leading-[16px] text-darkgrey mb-[5px]',
            labelClassName
          )}
        >
          {label}
        </p>
      )}

      <div
        className={cn(
          'w-full flex items-center border border-borderColor rounded-[5px]',
          {
            'h-[14px]': rest.type === 'checkbox',
            'h-10': rest.type !== 'checkbox',
            '!border-danger': error && touched,
          },
          wrapperClassName
        )}
      >
        {search && (
          <div className="pl-[10px] -translate-y-[1px]">
            <Icon icon={Icons.Search} size={15} />
          </div>
        )}

        <input
          name={fieldName as string}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
          className={cn(
            'w-full flex items-center px-[10px] text-[13px] outline-none rounded-[5px] placeholder:text-darkgrey overflow-hidden text-ellipsis',
            {
              'h-[14px]': rest.type === 'checkbox',
              'h-[38px]': rest.type !== 'checkbox',
              'placeholder:!text-danger': error && touched,
            },
            inputClassName
          )}
          placeholder={error && touched ? '*Required' : rest.placeholder}
        />
      </div>
    </div>
  );
};
