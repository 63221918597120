import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Sidebar } from '@widgets';
import { useOuterFrameParam } from '@hooks';

export const Root: React.FC = () => {
  const withOuterFrame = useOuterFrameParam();

  return (
    <div className="flex h-screen overflow-hidden">
      {withOuterFrame && (
        <aside>
          <Sidebar />
        </aside>
      )}

      <main className="flex flex-col flex-1 pb-16 bg-background w-[82%]">
        {withOuterFrame && (
          <header>
            <Header />
          </header>
        )}

        <div className="flex-1">
          <Outlet />
        </div>
      </main>
    </div>
  );
};
