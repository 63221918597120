import { SelectOption } from '@/shared/ui';

export const frequencyOptions: SelectOption[] = [
  {
    label: 'Once',
    value: 'once',
  },
  {
    label: 'Per week',
    value: 'per-week',
  },
];

export type TagsInputField = 'from' | 'to' | 'cc' | 'bcc';

interface TagsInputTab {
  title: string;
  fieldName: TagsInputField;
}

export const tagsInputTabs: TagsInputTab[] = [
  {
    title: 'From',
    fieldName: 'from',
  },
  {
    title: 'To',
    fieldName: 'to',
  },
  {
    title: 'Cc',
    fieldName: 'cc',
  },
  {
    title: 'Bcc',
    fieldName: 'bcc',
  },
];
