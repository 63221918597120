import { Icon } from '@ui';
import { Icons } from '@utils';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { getRangePages } from './helpers/getRangePages';

interface PaginationProps {
  pageCount: number;
  className?: string;
  page: number;
  setPage: (page: number) => void;
  onPageChange?: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  className,
  page,
  setPage,
  onPageChange,
}) => {
  const [rangePages, setRangePages] = useState<number[]>([]);

  useEffect(() => {
    setRangePages(getRangePages(page, pageCount));

    if (onPageChange) {
      onPageChange(page);
    }
  }, [page, pageCount]);

  return (
    <div className={cn('flex items-center justify-start w-fit', className)}>
      <button
        type="button"
        className="disabled:opacity-50 hover:opacity-75"
        onClick={() => setPage(0)}
        disabled={page === 0}
      >
        <Icon icon={Icons.DoubleArrowsLeft} />
      </button>
      <button
        type="button"
        className="disabled:opacity-50 hover:opacity-75"
        onClick={() => setPage(page - 1)}
        disabled={page === 0}
      >
        <Icon icon={Icons.ArrowLeft} />
      </button>
      <div className="flex mx-3 gap-x-3">
        {rangePages.map((pageNumber, index) => (
          <button
            type="button"
            key={index}
            onClick={() => setPage(pageNumber)}
            className={cn(
              'h-[25px] w-[25px] leading-[25px] text-white flex justify-center items-center bg-fullfork rounded-full',
              { 'opacity-50 hover:opacity-70': pageNumber !== page }
            )}
          >
            <span>{pageNumber + 1}</span>
          </button>
        ))}
      </div>
      <button
        type="button"
        className="disabled:opacity-50 hover:opacity-75 "
        onClick={() => setPage(page + 1)}
        disabled={page === pageCount - 1}
      >
        <Icon icon={Icons.ArrowRight} />
      </button>
      <button
        type="button"
        className="disabled:opacity-50 hover:opacity-75"
        onClick={() => setPage(pageCount - 1)}
        disabled={page === pageCount - 1}
      >
        <Icon icon={Icons.DoubleArrowsRight} />
      </button>
    </div>
  );
};
