import { Offer } from '@api';
import { SearchOfferBy } from '../../../config';

export const searchOffers = (
  offers: Offer[] | undefined,
  searchValue: string,
  searchBy: SearchOfferBy
) => {
  if (!offers?.length) {
    return [];
  }

  const isMatch = (target: string | null | undefined) =>
    target ? target.toLowerCase().includes(searchValue.toLowerCase()) : false;

  return offers.filter((offer) => isMatch(offer[searchBy]));
};
