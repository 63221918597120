import { z } from 'zod';

const addRoleFields = z.object({
  role: z.string({ required_error: 'Required' }),
  permissionSet: z.number({ required_error: 'Required' }),
});

const AddRoleSchema = addRoleFields;

export type AddRoleValues = z.infer<typeof AddRoleSchema>;

export { AddRoleSchema };
