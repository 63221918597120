export const STATES_OPTIONS = [
  { label: 'AK', value: 'AK' },
  { label: 'AL', value: 'AL' },
  { label: 'AR', value: 'AR' },
  { label: 'AZ', value: 'AZ' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CT', value: 'CT' },
  { label: 'DE', value: 'DE' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'HI', value: 'HI' },
  { label: 'IA', value: 'IA' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'LA', value: 'LA' },
  { label: 'MA', value: 'MA' },
  { label: 'MD', value: 'MD' },
  { label: 'ME', value: 'ME' },
  { label: 'MI', value: 'MI' },
  { label: 'MN', value: 'MN' },
  { label: 'MO', value: 'MO' },
  { label: 'MS', value: 'MS' },
  { label: 'MT', value: 'MT' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'NE', value: 'NE' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NV', value: 'NV' },
  { label: 'NY', value: 'NY' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PA', value: 'PA' },
  { label: 'RI', value: 'RI' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UT', value: 'UT' },
  { label: 'VA', value: 'VA' },
  { label: 'VT', value: 'VT' },
  { label: 'WA', value: 'WA' },
  { label: 'WI', value: 'WI' },
  { label: 'WV', value: 'WV' },
  { label: 'WY', value: 'WY' },
];

export const TIME_ZONES: { label: string; value: number }[] = [
  { label: 'UTC-12:00 - Baker Island', value: -12 },
  { label: 'UTC-11:00 - Pago Pago, American Samoa', value: -11 },
  { label: 'UTC-10:00 - Honolulu, Hawaii', value: -10 },
  { label: 'UTC-09:00 - Anchorage, Alaska', value: -9 },
  { label: 'UTC-08:00 - Los Angeles, California', value: -8 },
  { label: 'UTC-07:00 - Denver, Colorado', value: -7 },
  { label: 'UTC-06:00 - Mexico City, Mexico', value: -6 },
  { label: 'UTC-05:00 - New York City, USA', value: -5 },
  { label: 'UTC-04:00 - Caracas, Venezuela', value: -4 },
  { label: 'UTC-03:00 - Buenos Aires, Argentina', value: -3 },
  {
    label: 'UTC-02:00 - South Georgia and the South Sandwich Islands',
    value: -2,
  },
  { label: 'UTC-01:00 - Azores, Portugal', value: -1 },
  { label: 'UTC±00:00 - London, United Kingdom', value: 0 },
  { label: 'UTC+01:00 - Berlin, Germany', value: 1 },
  { label: 'UTC+02:00 - Athens, Greece', value: 2 },
  { label: 'UTC+03:00 - Istanbul, Turkey', value: 3 },
  { label: 'UTC+04:00 - Dubai, United Arab Emirates', value: 4 },
  { label: 'UTC+05:00 - Islamabad, Pakistan', value: 5 },
  { label: 'UTC+06:00 - Almaty, Kazakhstan', value: 6 },
  { label: 'UTC+07:00 - Bangkok, Thailand', value: 7 },
  { label: 'UTC+08:00 - Beijing, China', value: 8 },
  { label: 'UTC+09:00 - Tokyo, Japan', value: 9 },
  { label: 'UTC+10:00 - Sydney, Australia', value: 10 },
  { label: 'UTC+11:00 - Honiara, Solomon Islands', value: 11 },
  { label: 'UTC+12:00 - Suva, Fiji', value: 12 },
];
