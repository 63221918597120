import cn from 'classnames';
import { useField } from 'formik';
import React from 'react';
import ReactQuill from 'react-quill';
import { EditorToolbar, formats, modules } from './EditorToolbar';

interface TextEditorProps<Form> {
  className?: string;
  fieldName: keyof Form;
}

export const TextEditor = <Form extends object>({
  className,
  fieldName,
}: TextEditorProps<Form>) => {
  const [{ value }, , { setValue }] = useField<string>({
    name: fieldName as string,
  });

  const onChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className={cn(className)}>
      <EditorToolbar />
      <ReactQuill
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};
