import React from 'react';
import { Icons } from '@utils';

import { Icon } from '../Icon';

interface ModalCloseButtonProps {
  onModalClose: () => void;
}

export const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({
  onModalClose,
}) => (
  <button
    type="button"
    onClick={onModalClose}
    className="absolute -top-5 -right-5 h-[21px] w-[21px] flex justify-center items-center bg-white rounded-full"
  >
    <Icon icon={Icons.Close} size={16} />
  </button>
);
