import { Routes } from '@navigation';
import { Icons } from '@utils';

export interface SidebarItem {
  icon: string;
  iconSize?: number;
  label: string;
  route: Routes | null;
  children?: SidebarItem[];
}

export const sidebarItems: SidebarItem[] = [
  { icon: Icons.Home, label: 'Home', route: Routes.HOME },
  {
    icon: Icons.Figures,
    label: 'Products',
    route: null,
    children: [
      {
        icon: Icons.Wifi,
        label: 'Plum Wifi',
        iconSize: 20,
        route: Routes.PLUM_WIFI,
      },
      {
        icon: Icons.FullFork,
        label: 'FullFork',
        iconSize: 20,
        route: Routes.FULLFORK,
      },
    ],
  },
  {
    icon: Icons.ReportScheduleBold,
    label: 'Reporting',
    route: Routes.REPORTING,
  },
  {
    icon: Icons.Tiles,
    label: 'App Store',
    route: Routes.APP_STORE,
  },
  {
    icon: Icons.Settings,
    label: 'Settings',
    route: Routes.SETTINGS,
  },
];
