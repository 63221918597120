import cn from 'classnames';
import { useField } from 'formik';
import React, { ChangeEvent, ReactNode, useRef, useState } from 'react';

interface FileInputContainerProps<Form> {
  fieldName: keyof Form;
  className?: string;
  children: ReactNode;
}

export const FileInputContainer = <Form extends object>({
  fieldName,
  className,
  children,
}: FileInputContainerProps<Form>) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [, , { setValue: setFormikValue }] = useField<File | string | null>({
    name: fieldName as string,
  });

  const [filePath, setFilePath] = useState('');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilePath(e.target.value);

    if (e.target?.files?.length) {
      setFormikValue(e.target.files[0]);

      return;
    }

    setFormikValue(null);
  };

  const onClickInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <button
      type="button"
      onClick={onClickInput}
      className={cn('hover:opacity-80', className)}
    >
      {children}

      <input
        ref={fileInputRef}
        className="hidden"
        name={fieldName as string}
        value={filePath}
        onChange={onChange}
        type="file"
        accept="image/*"
      />
    </button>
  );
};
