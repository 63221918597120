/* eslint-disable quote-props */

export interface TransactionHistoryTableRow {
  date: Date;
  invoice: number;
  account: number;
  totalDue: number;
}

export const transactionHistoryTableData: TransactionHistoryTableRow[] = [
  {
    date: new Date(),
    invoice: 9881029,
    account: 1900293,
    totalDue: 1500,
  },
  {
    date: new Date(),
    invoice: 9881029,
    account: 1900293,
    totalDue: 1500,
  },
  {
    date: new Date(),
    invoice: 9881029,
    account: 1900293,
    totalDue: 1500,
  },
  {
    date: new Date(),
    invoice: 9881029,
    account: 1900293,
    totalDue: 1500,
  },
];

export interface BillDetails {
  'Subscriptions': number;
  'Add-ons': number;
  'Deductions': number;
  'Total Due': number;
}

export interface StatementDetails {
  lastMonth: number;
  thisMonth: number;
  dueBy: string;
  scheduledFor: string;
  billDetails: BillDetails;
}

interface SubItem {
  name: string;
  price: number;
  count: number;
}

export interface SubscriptionDetails {
  name: string;
  subItems: SubItem[];
}

export interface DetailSummaryItem {
  name: string;
  price: number;
  details?: SubscriptionDetails[];
}

interface MonthDetails {
  month: string;
  price: number;
}

export interface MonthYearDetails {
  year: number;
  total: number;
  perMonthDetails: MonthDetails[];
}

export const statementDetails: StatementDetails = {
  lastMonth: 1500,
  thisMonth: 1500,
  dueBy: 'May 15, 2023',
  scheduledFor: 'May 13, 2023',
  billDetails: {
    'Subscriptions': 1099,
    'Add-ons': 500,
    'Deductions': 99,
    'Total Due': 1500,
  },
};

export const monthYearDetails: MonthYearDetails[] = [
  {
    year: 2023,
    total: 3000,
    perMonthDetails: [
      {
        month: 'Feb 2023',
        price: 1500,
      },
      {
        month: 'Jan 2023',
        price: 1500,
      },
    ],
  },
  {
    year: 2022,
    total: 3000,
    perMonthDetails: [
      {
        month: 'Feb 2023',
        price: 1500,
      },
      {
        month: 'Jan 2023',
        price: 1500,
      },
    ],
  },
];

export const summaryDetails: DetailSummaryItem[] = [
  {
    name: 'Zip Loyalty',
    price: 99,
  },
  {
    name: 'Fullfork',
    price: 99,
  },
  {
    name: 'Plum Wifi',
    price: 297,
    details: [
      {
        name: 'Locations',
        subItems: [
          {
            name: '190 S. State College Blvd, Brea, CA 92821',
            price: 99,
            count: 1,
          },
          {
            name: 'Location 2 College Blvd, Brea, CA 92821',
            price: -99,
            count: 1,
          },
        ],
      },
      {
        name: 'Add-ons',
        subItems: [
          {
            name: 'Plum WiFi Router',
            price: 99,
            count: 2,
          },
        ],
      },
    ],
  },
  {
    name: 'Zip Marketing +',
    price: 198,
    details: [
      {
        name: 'Locations',
        subItems: [
          {
            name: '190 S. State College Blvd, Brea, CA 92821',
            price: 99,
            count: 1,
          },
          {
            name: 'Location 2 College Blvd, Brea, CA 92821',
            price: 99,
            count: 1,
          },
        ],
      },
    ],
  },
];
