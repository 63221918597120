import { z } from 'zod';

const editUserFields = z.object({
  firstName: z.string({ required_error: 'Required' }),
  lastName: z.string({ required_error: 'Required' }),
  email: z
    .string({ required_error: 'Email is required' })
    .email('Invalid email.'),
  areaCode: z.string({ required_error: 'Required' }).or(z.number()),
  phone: z.string({ required_error: 'Required' }).or(z.number()),
  role: z.string({ required_error: 'Required' }),
  resetPassword: z.string({ required_error: 'Required' }),
});

const EditUserSchema = editUserFields;

export type EditUserValues = z.infer<typeof EditUserSchema>;

export { EditUserSchema };
