import React from 'react';
import { components } from 'react-select';
import { Colors, Icons } from '@utils';

import { Icon } from '../Icon';

export const Control = (props: any) =>
  components.Control && (
    <components.Control
      {...props}
      className="flex react-select-control !flex-nowrap "
    />
  );

export const ControlWithError = (props: any) =>
  components.Control && (
    <components.Control
      {...props}
      className="flex react-select-control !flex-nowrap !border !border-danger"
    />
  );

export const ControlWithoutBorder = (props: any) =>
  components.Control && (
    <components.Control
      {...props}
      className="react-select-control-without-border"
    />
  );

export const ValueContainer = (props: any) =>
  components.ValueContainer && (
    <div className="py-[3.5px]">
      <components.ValueContainer {...props} className="text-[13px]" />
    </div>
  );

export const FilterValueContainer = (props: any) =>
  components.ValueContainer && (
    <div className="flex flex-row items-center py-1 pl-3">
      <Icon icon={Icons.Filter} color={Colors.DarkGrey} />
      <components.ValueContainer {...props} className="text-[13px]" />
    </div>
  );

export const MultiValueContainer = (props: any) =>
  components.ValueContainer && (
    <div className="flex flex-row items-center py-1 pl-3 cursor-pointer">
      <Icon icon={Icons.Multi} color={Colors.DarkGrey} size={18} />
      <components.ValueContainer {...props} className="text-[13px] ml-[2px] " />
    </div>
  );

export const MultiValue = (props: any) =>
  components.MultiValue && (
    <components.MultiValue
      {...props}
      className="react-select-multivalue bg-lightgrey rounded-[10px] cursor-pointer !w-auto"
    />
  );

export const DropdownIndicator = (props: any) =>
  components.DropdownIndicator && (
    <div className="!pr-3">
      <components.DropdownIndicator {...props} className="cursor-pointer">
        <Icon icon={Icons.CollapsibleArrow} size={9} color={Colors.DarkGrey} />
      </components.DropdownIndicator>
    </div>
  );

export const DropdownIndicatorCloser = (props: any) =>
  components.DropdownIndicator && (
    <div className="!pr-3 -ml-3">
      <components.DropdownIndicator {...props}>
        <Icon icon={Icons.CollapsibleArrow} size={9} color={Colors.DarkGrey} />
      </components.DropdownIndicator>
    </div>
  );

export const Input = ({ id, ...props }: any) =>
  components.Input && <components.Input {...props} id={id} />;
