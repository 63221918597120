import { Button, Icon, Input, Text } from '@ui';
import { Colors, Icons } from '@utils';
import React, { FC, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { CardValues } from './helpers/validation';
import Cards, { Focused } from 'react-credit-cards';

import 'react-credit-cards/es/styles-compiled.css';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';
import { SwiperSlide, Swiper, SwiperClass } from 'swiper/react';
import { cards } from './config';
import { AddCardModal } from './AddCardModal';

export const PaymentOptions: FC = () => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const [focusedInput, setFocusedInput] = useState<Focused>('name');
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [isModalOpen, setModalOpen] = useState(false);

  const form = useFormik<CardValues>({
    initialValues: {
      holderName: cards[currentCardIndex].name,
      cardNumber: cards[currentCardIndex].number,
      expiryDate: cards[currentCardIndex].expiry,
      cvv: cards[currentCardIndex].cvv,
    },
    enableReinitialize: true,
    onSubmit: console.log,
  });

  const { values } = form;

  const onSlideChange = (slide: SwiperClass) => {
    setCurrentCardIndex(slide.activeIndex);
    setFocusedInput('name');
  };

  const isSingleSlide = swiper ? swiper?.slides?.length <= 1 : true;

  const slides = new Array(cards?.length).fill(null);

  return (
    <FormikProvider value={form}>
      <section className="flex items-start justify-between mt-11 gap-x-[113px]">
        <div className="basis-1/2 h-[337px] max-w-[587px] min-w-[390px] ">
          <div className="flex items-center justify-between mb-[22px]">
            <Text variant="h4">My Cards</Text>

            <button type="button" onClick={() => setModalOpen(true)}>
              <Icon icon={Icons.Close} size={24} className="rotate-45" />
            </button>
          </div>

          <div className="relative">
            {!isSingleSlide && (
              <>
                <button
                  type="button"
                  onClick={() => swiper?.slidePrev()}
                  className="absolute flex justify-center items-center z-20 -left-[19px] top-[37%] bg-customerology h-[38px] w-[38px] rounded-full disabled:bg-[#D2DEE9] hover:brightness-110 active:brightness-75 disabled:filter-none"
                  disabled={currentCardIndex === 0}
                >
                  <Icon icon={Icons.ArrowLeft} color={Colors.White} size={18} />
                </button>

                <button
                  type="button"
                  onClick={() => swiper?.slideNext()}
                  className="absolute flex justify-center items-center z-20 -right-[19px] top-[37%] bg-customerology h-[38px] w-[38px] rounded-full disabled:bg-[#D2DEE9] hover:brightness-110 active:brightness-75 disabled:filter-none"
                  disabled={currentCardIndex === cards.length - 1}
                >
                  <Icon
                    icon={Icons.ArrowRight}
                    color={Colors.White}
                    size={18}
                  />
                </button>
              </>
            )}

            {true ? (
              <Swiper
                slidesPerView={1}
                spaceBetween={40}
                pagination={{
                  bulletClass:
                    'inline-block !h-3 !w-3 bg-lightgrey rounded-full mx-1 cursor-pointer',
                  bulletActiveClass: '!bg-customerology',
                  clickable: true,
                }}
                modules={[Pagination]}
                className="w-full h-[393px]"
                direction="horizontal"
                onSlideChange={onSlideChange}
                onSwiper={(slide) => {
                  setSwiper(slide);
                }}
              >
                {slides.map(() => (
                  <SwiperSlide className="pb-14">
                    <Cards
                      cvc={values.cvv}
                      expiry={values.expiryDate}
                      focused={focusedInput}
                      name={values.holderName}
                      number={values.cardNumber}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className="bg-lightgrey border border-dashed mt-6 border-text rounded-[10px] flex justify-center items-center w-full h-[337px]">
                <Text variant="h4" className="text-darkgrey">
                  No Available Cards
                </Text>
              </div>
            )}
          </div>
        </div>

        <div className="relative basis-1/2 max-w-[587px]">
          <Text variant="h4">Card Details</Text>

          <div className="relative mt-5 bg-white z-20 flex flex-col items-start gap-y-[23px] px-[60px] pt-[43px] pb-[65px] rounded-[10px] shadow-card-shadow  min-w-[390px]">
            <Input<CardValues>
              fieldName="holderName"
              label="Card Holder Name"
              className="w-full"
              onFocus={() => setFocusedInput('name')}
            />

            <Input<CardValues>
              fieldName="cardNumber"
              label="Card Number"
              className="w-full"
              onFocus={() => setFocusedInput('number')}
              maxLength={16}
            />

            <div className="flex w-[70%] gap-x-10">
              <Input<CardValues>
                fieldName="expiryDate"
                label="Expiry Date"
                onFocus={() => setFocusedInput('expiry')}
                maxLength={4}
              />

              <Input<CardValues>
                fieldName="cvv"
                label="CVV Code"
                onFocus={() => setFocusedInput('cvc')}
                maxLength={3}
              />
            </div>
          </div>

          <div className="relative z-20 flex items-center justify-center gap-x-6 mt-7">
            <Button label="Edit Card" color="customerology" className="!h-9" />
            <Button label="Remove Card" color="white" className="!h-9" />
          </div>

          <div className="absolute z-10 top-[103px] -left-[3.5%] w-[107%]  bg-[#F2F2F2] rounded-[10px] h-[381px]" />
        </div>
      </section>

      <AddCardModal
        isModalOpen={isModalOpen}
        onModalClose={() => setModalOpen(false)}
      />
    </FormikProvider>
  );
};
