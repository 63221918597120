import { Page, PageTabs } from '@ui';
import React, { useState } from 'react';
import classNames from 'classnames';
import { Customization, Overview, Settings, tabs } from './tabs';
import { useSelector } from '@store';
import { plumWifiSelectors } from '@store/entities';
import { useGetWifiSettingsByLocationQuery } from '@api';
import { skipToken } from '@reduxjs/toolkit/dist/query';

export const PlumWifi: React.FC = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const locationId = useSelector(plumWifiSelectors.locationId);

  useGetWifiSettingsByLocationQuery(locationId ?? skipToken, {
    refetchOnMountOrArgChange: true,
    skip: !locationId,
  });

  return (
    <Page title={tabs[activeTabIndex].label}>
      <PageTabs
        value={activeTabIndex}
        onChange={setActiveTabIndex}
        tabs={tabs}
        variant="plum-wifi"
      />

      <div
        className={classNames('pt-[50px] px-15', {
          'h-full': activeTabIndex === 1,
        })}
      >
        {activeTabIndex === 0 && <Overview />}
        {activeTabIndex === 1 && <Customization />}
        {activeTabIndex === 2 && <Settings />}
      </div>
    </Page>
  );
};
