import { FileInputContainer, Icon } from '@ui';
import React from 'react';
import { Colors, Icons } from '@utils';
import { useHover } from '@hooks';
import cn from 'classnames';

interface EditAvatarProps<Form> {
  fieldName: keyof Form;
  image: string;
}

export const EditAvatar = <Form extends object>({
  fieldName,
  image,
}: EditAvatarProps<Form>) => {
  const [avatarRef, isAvatarHovered] = useHover();

  return (
    <div
      ref={avatarRef}
      className="relative rounded-full h-[95px] min-w-[95px] w-[95px] overflow-hidden"
    >
      <img src={image} alt="avatar" className="w-full h-full" />
      <FileInputContainer<Form>
        fieldName={fieldName}
        className={cn(
          'absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 transition-all',
          {
            '!opacity-0': !isAvatarHovered,
          }
        )}
      >
        <span className="flex flex-col justify-center items-center  whitespace-nowrap  px-[9px] py-[5px] text-[11px] leading-4">
          <Icon icon={Icons.Camera} size={14} color={Colors.Black} />
          Upload Image
        </span>
      </FileInputContainer>
    </div>
  );
};
