import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import {
  BillingDetailsSchema,
  BillingDetailsValues,
} from '../../helpers/billingDetailsValidation';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Button, Input } from '@ui';
import { Icons } from '@utils';

interface BillingDetailsFormProps {
  onSubmit: () => void;
}

export const BillingDetailsForm: FC<BillingDetailsFormProps> = ({
  onSubmit,
}) => {
  const form = useFormik<BillingDetailsValues>({
    initialValues: {
      company: '',
      fullName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postCode: '',
      phone: '',
      email: '',
    },
    validationSchema: toFormikValidationSchema(BillingDetailsSchema),
    onSubmit: () => {
      onSubmit();
    },
  });

  const { handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <div className="flex flex-col gap-y-[10px] max-w-[472px]">
        <Input<BillingDetailsValues> fieldName="company" label="Company Name" />

        <Input<BillingDetailsValues> fieldName="fullName" label="Full Name" />

        <div className="flex items-center justify-between gap-x-6">
          <Input<BillingDetailsValues>
            fieldName="addressLine1"
            label="Address Line 1"
          />

          <Input<BillingDetailsValues>
            fieldName="addressLine2"
            label="Address Line 2"
          />
        </div>

        <div className="flex items-center justify-between gap-x-6">
          <Input<BillingDetailsValues>
            fieldName="city"
            label="City"
            className="basis-[36%]"
          />

          <Input<BillingDetailsValues>
            fieldName="state"
            label="State"
            className="basis-[32%]"
          />

          <Input<BillingDetailsValues>
            fieldName="postCode"
            label="Postal Code"
            className="basis-[21%]"
          />
        </div>

        <div className="flex items-center justify-between gap-x-6">
          <Input<BillingDetailsValues> fieldName="phone" label="Phone Number" />

          <Input<BillingDetailsValues>
            fieldName="email"
            label="Email Address"
          />
        </div>
      </div>

      <Button
        onClick={handleSubmit}
        label="Save Payment Details"
        iconRight={Icons.ArrowRight}
        color="customerology"
        className="mt-9"
      />
    </FormikProvider>
  );
};
