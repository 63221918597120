import { SelectOption } from '@/shared/ui';

export const tabs = ['Manage My Apps', 'Payment Options', 'Statements'];

export type BreakdownVariant = 'monthly' | 'location';

export const manageAppsOptions: SelectOption[] = [
  {
    label: 'Monthly Breakdown',
    value: 'monthly',
  },
  {
    label: 'Location Breakdown',
    value: 'location',
  },
];
