import React, { useState } from 'react';
import { Page, PageTabs } from '@ui';

import { Locations, Offers, Overview, tabItems } from './tabs';

export const Fullfork: React.FC = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <Page title={tabItems[activeTabIndex].label}>
      <PageTabs
        className="mb-6"
        value={activeTabIndex}
        onChange={setActiveTabIndex}
        tabs={tabItems}
        variant="fullfork"
      />

      {activeTabIndex === 0 && <Overview />}
      {activeTabIndex === 1 && <Locations />}
      {activeTabIndex === 2 && <Offers />}
    </Page>
  );
};
