import { Day, DAYS } from '@utils';
import cn from 'classnames';
import React, { FC } from 'react';

interface SelectDayProps {
  className?: string;
  label?: string;
  value: Day[];
  onChange: (value: Day[]) => void;
}

export const SelectDay: FC<SelectDayProps> = ({
  className,
  value,
  label,
  onChange,
}) => {
  const handleChange = (day: Day) => {
    if (value.indexOf(day) !== -1) {
      const newValue = value.filter((el) => el !== day);

      onChange(newValue);

      return;
    }

    onChange([...value, day]);
  };

  return (
    <div className={className}>
      {!!label && (
        <p className="block text-[11px] leading-[16px] text-darkgrey mb-[5px]">
          {label}
        </p>
      )}
      <div className="flex justify-between items-center gap-x-[6px]">
        {DAYS.map((day) => (
          <button
            key={day}
            onClick={() => handleChange(day)}
            type="button"
            className={cn(
              'border border-borderColor w-8 text-center rounded-[5px] py-[10px] text-darkgrey text-[13px] leading-5 hover:brightness-90',
              {
                'bg-plumwifi !text-white': value.indexOf(day) !== -1,
              }
            )}
          >
            {day}
          </button>
        ))}
      </div>
    </div>
  );
};
