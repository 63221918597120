import React, { useEffect, useMemo, useState } from 'react';
import {
  FETCH_LOCATIONS_LIMIT,
  Location,
  useDeleteLocationMutation,
  useGetLocationCountQuery,
  useGetLocationsQuery,
  useToggleLocationStateMutation,
} from '@api';
import {
  Button,
  Counter,
  Loader,
  LoaderContainer,
  SearchInput,
  Select,
  Table,
} from '@ui';
import { createColumnHelper } from '@tanstack/react-table';
import { MOCKED_ENTERPRISE_ID, formatAddress } from '@utils';
import { match } from 'ts-pattern';
import { Pagination } from '@features';

import { SearchLocationBy, locationsOptions } from '../config';

import { LocationModal } from './modals';
import { useToast } from '@hooks';
import { searchLocations } from './modals/helpers';

export const Locations: React.FC = () => {
  const { showErrorToast } = useToast();

  const [searchBy, setSearchBy] = useState<SearchLocationBy>('name');
  const [searchValue, setSearchValue] = useState('');

  const [isAddLocationModalOpen, setAddLocationModalOpen] = useState(false);
  const [locationId, setLocationId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(0);

  const { data: locationsCount, isLoading: isLoadingPageCount } =
    useGetLocationCountQuery(MOCKED_ENTERPRISE_ID);

  const [deleteLocation, { isLoading: isDeleting, isError: isErrorDeleting }] =
    useDeleteLocationMutation();
  const [
    toggleLocationState,
    { isLoading: isToggling, isError: isErrorToggling },
  ] = useToggleLocationStateMutation();

  const {
    data: locations,
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetLocationsQuery(
    { id: MOCKED_ENTERPRISE_ID, pageNum: currentPage },
    { refetchOnMountOrArgChange: true }
  );

  const columnHelper = createColumnHelper<Location>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        header: 'Store Name',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.jsonStreetAddress?.[0] || null, {
        header: 'Address',
        cell: (cell) =>
          cell.getValue()?.street ? formatAddress(cell.getValue()!) : '-',
      }),
      columnHelper.accessor((row) => row.businessPhoneNumber, {
        header: 'Phone Number',
        cell: (cell) => cell.getValue() || '-',
      }),
    ],
    []
  );

  const onEdit = (id: number) => {
    setLocationId(id);
    setAddLocationModalOpen(true);
  };

  const onAddClick = () => {
    setLocationId(null);
    setAddLocationModalOpen(true);
  };

  const pageCount = locationsCount
    ? Math.ceil(locationsCount / FETCH_LOCATIONS_LIMIT)
    : 1;

  useEffect(() => {
    if (currentPage >= pageCount) {
      setCurrentPage(pageCount - 1);
    }
  }, [pageCount]);

  const isTableLoading = isFetching || isToggling || isDeleting;

  useEffect(() => {
    if (isErrorToggling) {
      showErrorToast();
    }
  }, [isErrorToggling]);

  useEffect(() => {
    if (isErrorDeleting) {
      showErrorToast();
    }
  }, [isErrorDeleting]);

  const resultLocations = useMemo(
    () => searchLocations(locations, searchValue, searchBy),
    [locations, searchValue, searchBy]
  );

  return match({
    isLoading: isLoadingPageCount || isLoading,
    isLocationRequested: !isUninitialized,
  })
    .with({ isLoading: true }, () => <Loader centered withTopIndent />)
    .with({ isLocationRequested: true }, () => (
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="flex flex-row items-center justify-between mx-3 mb-6">
            <Counter count={locationsCount || 0} label="Locations" />

            <Button type="add" label="Add Location" onClick={onAddClick} />
          </div>

          <div className="flex items-center w-full py-5 px-4 border border-[#DCDCDC] rounded-md mb-4">
            <div className="basis-1/6 mr-[10px]">
              <Select
                value={searchBy}
                options={locationsOptions}
                onChange={setSearchBy}
                withoutBorder
              />
            </div>

            <div className="grow">
              <SearchInput
                placeholder="Search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>

          <LoaderContainer loading={isTableLoading}>
            <div className="overflow-auto custom-scroll max-h-[275px] h-auto">
              {resultLocations?.length ? (
                <Table
                  withControlPanel
                  withStatusSwitch={false}
                  withEdit={false}
                  className="!m-0"
                  data={locations ? resultLocations : []}
                  columns={columns}
                  uniqueKey="id"
                  statusKey="enabled"
                  isError={isErrorDeleting || isErrorToggling}
                  onEdit={onEdit}
                  onDelete={deleteLocation}
                  onToggle={toggleLocationState}
                />
              ) : (
                <div className="flex items-center justify-center w-full pt-10 pb-14">
                  There is no data
                </div>
              )}
            </div>
          </LoaderContainer>
        </div>
        <Pagination
          className="mx-auto mt-12"
          page={currentPage}
          setPage={setCurrentPage}
          pageCount={pageCount}
        />

        <LocationModal
          setLocationId={setLocationId}
          locationId={locationId}
          isModalOpen={isAddLocationModalOpen}
          onModalClose={() => setAddLocationModalOpen(false)}
        />
      </div>
    ))
    .otherwise(() => null);
};
