import { Page, PageTabs } from '@ui';
import React, { FC, useState } from 'react';
import { tabs } from './config';
import { Billing, EnterpriseSettings, Support } from './tabs';

export const Settings: FC = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <Page title="Settings" withCart>
      <PageTabs
        value={activeTabIndex}
        onChange={setActiveTabIndex}
        tabs={tabs}
        variant="customerology"
      />
      <section className="pl-6 pr-[10px] py-[52px] h-full">
        {activeTabIndex === 0 && <EnterpriseSettings />}
        {activeTabIndex === 1 && <Billing />}
        {activeTabIndex === 2 && <Support />}
      </section>
    </Page>
  );
};
