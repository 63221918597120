import React from 'react';
import * as ReactSwitch from '@radix-ui/react-switch';
import cn from 'classnames';

interface SwitchProps {
  checked: boolean;
  onCheckedChange?: (checked: boolean) => void;
  disabled?: boolean;
  wrapperStyle?: string;
  thumbStyle?: string;
}

export const Switch: React.FC<SwitchProps> = ({
  checked,
  onCheckedChange,
  disabled = false,
  wrapperStyle,
  thumbStyle,
}) => (
  <ReactSwitch.Root
    checked={checked}
    onCheckedChange={onCheckedChange}
    disabled={disabled}
    className={cn(
      'w-[28px] cursor-pointer h-[15px] bg-[#d9d9d9] rounded-full outline-none data-[state=checked]:bg-fullfork data-[state=disabled]:opacity-50',
      wrapperStyle
    )}
  >
    <ReactSwitch.Thumb
      className={cn(
        'block w-[13px] h-[13px] bg-white rounded-full transition-transform duration-150 translate-x-[1px] will-change-transform data-[state=checked]:translate-x-[14px]',
        thumbStyle
      )}
    />
  </ReactSwitch.Root>
);
