/* eslint-disable react/jsx-wrap-multilines */

import { Icon, Text } from '@ui';
import cn from 'classnames';
import React, { FC } from 'react';
import Collapsible from 'react-collapsible';
import { AccordionHeader } from '../AccordionHeader';
import { Colors, Icons } from '@utils';
import { monthYearDetails } from '../../../config';

interface TotalSummaryProps {
  className?: string;
}

export const TotalSummary: FC<TotalSummaryProps> = ({ className }) => {
  const icon = (
    <Icon
      icon={Icons.ArrowLeft}
      color={Colors.DarkGrey}
      className="-rotate-90 chevron left-0 !top-1"
    />
  );

  return (
    <div className={cn('', className)}>
      <div className="flex items-center justify-between border-b border-borderColor">
        <Text
          variant="body2"
          className="!text-[10px] leading-4 !text-borderColor"
        >
          MONTH/YEAR
        </Text>
        <Text
          variant="body2"
          className="!text-[10px] leading-4 !text-borderColor"
        >
          PRICE
        </Text>
      </div>

      <div className="mt-[18px] flex flex-col gap-y-[18px]">
        {monthYearDetails.map(({ year, total, perMonthDetails }) => (
          <Collapsible
            key={year}
            trigger={
              <AccordionHeader
                name={`${year}`}
                price={total}
                className="!pl-[27px]"
              />
            }
            transitionTime={100}
            triggerSibling={icon}
            contentOuterClassName="w-full "
          >
            <div className="flex flex-col gap-y-5 pl-[54px] py-[18px]">
              {perMonthDetails.map(({ month, price }) => (
                <div key={month} className="flex items-center justify-between">
                  <Text
                    variant="body2"
                    className="!text-[10px] leading-4 !text-darkgrey"
                  >
                    {month}
                  </Text>
                  <Text
                    variant="body2"
                    className="!text-[14px] leading-5 !text-darkgrey"
                  >
                    $
                    {price}
                  </Text>
                </div>
              ))}
            </div>
          </Collapsible>
        ))}
      </div>
    </div>
  );
};
