/* eslint-disable react/no-unstable-nested-components */

import { createColumnHelper } from '@tanstack/react-table';
import { Icon, Table, Text } from '@ui';
import React, { FC, useMemo, useState } from 'react';
import {
  TransactionHistoryTableRow,
  transactionHistoryTableData,
} from './config';
import { DateFormats, Icons, formatDate } from '@utils';
import { StatementModal, StatementModalVariant } from './StatementModal';

interface StatementsProps {
  isModalOpen: boolean;
  onChangeModalVisibility: (value: boolean) => void;
}

export const Statements: FC<StatementsProps> = ({
  isModalOpen,
  onChangeModalVisibility,
}) => {
  const [modalVariant, setModalVariant] =
    useState<StatementModalVariant>('total');

  const onClickRow = () => {
    setModalVariant('detail');
    onChangeModalVisibility(true);
  };

  const onCloseModal = () => {
    onChangeModalVisibility(false);
    setModalVariant('total');
  };

  const columnHelper = createColumnHelper<TransactionHistoryTableRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.date, {
        header: 'Date',
        cell: (cell) =>
          formatDate(cell.getValue(), DateFormats.AMERICAN_FORMAT) || '-',
      }),
      columnHelper.accessor((row) => row.invoice, {
        header: 'Invoice#',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.account, {
        header: 'Account',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.totalDue, {
        header: 'Total Due',
        cell: (cell) => <b>{`$${cell.getValue()}`}</b>,
      }),
      columnHelper.accessor((row) => row.totalDue, {
        header: 'Action',
        cell: () => (
          <div className="flex items-center justify-start gap-x-5">
            <button
              type="button"
              onClick={onClickRow}
              className="hover:opacity-70 active:opacity-100"
            >
              <Icon icon={Icons.EyeMarked} size={21} />
            </button>

            <Icon icon={Icons.Printer} size={21} />
          </div>
        ),
      }),
    ],
    []
  );

  return (
    <section className="pt-12">
      <Text variant="h4">Transaction History</Text>

      <Table
        className="!mt-[27px]"
        data={transactionHistoryTableData || []}
        columns={columns}
      />

      <StatementModal
        isModalOpen={isModalOpen}
        onModalClose={onCloseModal}
        variant={modalVariant}
      />
    </section>
  );
};
