import { PlumWifiState } from './types';

const all = (state: PlumWifiState) => state.plumWifi;

const locationId = (state: PlumWifiState) => state.plumWifi.currentLocationId;

const router = (state: PlumWifiState) => state.plumWifi.currentRouter;

const settings = (state: PlumWifiState) => state.plumWifi.settings;

const isSettingsLoading = (state: PlumWifiState) =>
  state.plumWifi.isSettingsLoading;

export const plumWifiSelectors = {
  router,
  locationId,
  settings,
  isSettingsLoading,
  all,
};
