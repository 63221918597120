import React, { FC, useEffect, useState } from 'react';
import { Switch } from '../Switch';
import { Icon } from '../Icon';
import { Icons } from '@utils';
import cn from 'classnames';

interface ControlPanelProps {
  className?: string;
  checked: boolean;
  isError: boolean;
  onToggle: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export const ControlPanel: FC<ControlPanelProps> = ({
  checked: _checked,
  className,
  isError,
  onToggle,
  onEdit,
  onDelete,
}) => {
  const [checked, setChecked] = useState(_checked);

  useEffect(() => {
    setChecked(_checked);
  }, [_checked]);

  useEffect(() => {
    if (isError) {
      setChecked(_checked);
    }
  }, [isError]);

  const handleSwitch = () => {
    setChecked(!checked);

    onToggle();
  };

  return (
    <div
      className={cn(
        'flex justify-between items-center w-fit gap-x-4 py-1 px-[10px] rounded-[10px] bg-[#F5F5F5]',
        className
      )}
    >
      <Switch checked={checked} onCheckedChange={handleSwitch} />

      <button
        onClick={onEdit}
        type="button"
        className="hover:opacity-75 active:opacity-100"
      >
        <Icon icon={Icons.PencilCell} size="21px" />
      </button>

      <button
        onClick={onDelete}
        type="button"
        className="hover:text-danger active:brightness-75"
      >
        <Icon icon={Icons.Trash} size="21px" />
      </button>
    </div>
  );
};
