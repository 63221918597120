import { CartState } from './types';

const cart = (state: CartState) => state.cart;

const isOpen = (state: CartState) => state.cart.isOpen;

const itemsCount = (state: CartState) => state?.cart?.items?.length;

export const cartSelectors = {
  isOpen,
  itemsCount,
  cart,
};
