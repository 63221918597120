import { Text } from '@ui';
import cn from 'classnames';
import React, { FC } from 'react';

interface AccordionHeaderProps {
  className?: string;
  name: string;
  price: number;
}

export const AccordionHeader: FC<AccordionHeaderProps> = ({
  className,
  name,
  price,
}) => {
  return (
    <div
      className={cn(
        'bg-[#F9F9F9] rounded-[5px] flex justify-between items-center px-[5px] h-[31px]',
        className
      )}
    >
      <Text variant="body1" className="!text-[14px] leading-5 !text-darkgrey">
        {name}
      </Text>

      <Text
        variant="body1"
        className={cn('!text-[14px] leading-5 text-darkgrey', {
          '!text-danger': price < 0,
        })}
      >
        {price < 0 && '-'}
        $
        {price}
      </Text>
    </div>
  );
};
