interface CardDetails {
  name: string;
  number: string;
  expiry: string;
  cvv: string;
}

export const cards: CardDetails[] = [
  {
    name: 'Test First',
    number: '4242424444444444',
    expiry: '2211',
    cvv: '333',
  },
  {
    name: 'Test Second',
    number: '374424424444444',
    expiry: '2211',
    cvv: '333',
  },
  {
    name: 'Test Third',
    number: '6234693535353535',
    expiry: '2211',
    cvv: '333',
  },
];
