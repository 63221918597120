import { useToast } from '@hooks';
import { useField } from 'formik';
import React, { ChangeEvent, useRef, useState } from 'react';

interface AddImageProps<Form> {
  fieldName: keyof Form;
}

export const AddImage = <Form extends object>({
  fieldName,
}: AddImageProps<Form>) => {
  const { showErrorToast } = useToast();

  const [{ value }, , { setValue: setLogo }] = useField({
    name: fieldName as string,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filePath, setFilePath] = useState<string>('');

  const onClickInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilePath(e.target.value);

    if (!e.target?.files?.length) {
      setLogo(null);

      return;
    }

    const selectedImage = e.target.files[0];

    const img = new Image();

    img.onload = function handleLoad() {
      if (img.width < 50 || img.height < 50) {
        showErrorToast('Image dimensions must be at least 50x50 pixels');
        setLogo(null);

        return;
      }

      setLogo(selectedImage);
    };

    img.src = URL.createObjectURL(selectedImage);
  };

  return (
    <button
      type="button"
      onClick={onClickInput}
      className="text-center w-full bg-[#FAFAFA] border border-dashed border-[#CEC9C9] rounded-[5px] py-7 px-10 hover:brightness-90 cursor-pointer"
    >
      <input
        ref={fileInputRef}
        className="hidden"
        value={filePath}
        onChange={handleChange}
        type="file"
        accept="image/*"
      />

      {value?.name ? (
        <span className="block text-[13px] leading-5 text-darkgrey whitespace-nowrap overflow-hidden text-ellipsis">
          {value.name}
        </span>
      ) : (
        <>
          <span className="block text-[13px] leading-5 text-darkgrey whitespace-nowrap">
            +Add Image
          </span>
          <span className="mt-[10px] block text-[13px] leading-5 text-danger opacity-60 whitespace-nowrap">
            At least 50x50 pixels
          </span>
        </>
      )}
    </button>
  );
};
