import { Offer } from '@api';
import { MOCKED_ENTERPRISE_ID, getFullDateFromDayAndTime } from '@utils';
import { differenceInDays, getHours } from 'date-fns';

import { AddOfferValues } from './validation';
import { Time } from '@/features';

const today = new Date();

export const adaptAddOffer = (values: AddOfferValues): Offer => {
  const {
    title,
    description,
    offerCode,
    validFrom,
    validUntil,
    dontSendExpireReminder,
    couponRedeemLimit,
    eligibleUseCount,
    validTime,
    enableNoTimeRestriction,
    enabled,
    noExpiration,
    forHours,
    staffGrantable,
    isStanding,
    finePrint,
    /* eslint-disable  */
    cron_Validity,
  } = values;

  const validAfterDate = getFullDateFromDayAndTime(
    validFrom,
    validTime
  ).toISOString();
  console.log(validAfterDate);

  return {
    enterprise_Id: MOCKED_ENTERPRISE_ID,
    title,
    description,
    offerCode,
    validFrom: validFrom.toISOString(),
    validUntil: validUntil ? validUntil.toISOString() : null,
    enabled,
    noExpiration,
    finePrint: finePrint || null,
    cron_Validity,
    createdOn: today.toISOString(),
    lastModifiedAt: today.toISOString(),
    expiresOn: validUntil?.toISOString() ?? null,
    deleted: false,
    restrictions: {
      lifeSpanDays: !validUntil
        ? null
        : differenceInDays(validUntil, validFrom ?? new Date()),
      lifeSpanNotExceed: null,
      validAfterDays: differenceInDays(validFrom, today),
      validAfterDate,
      dontSendExpireReminder,
      couponRedeemLimit,
      eligibleUseCount,
      validStartTime1: validAfterDate,
      validTimeDuration1: enableNoTimeRestriction
        ? null
        : `${forHours > 9 ? forHours : `0${forHours}`}:00:00`,
      shareable: true,
      validForDineIn: true,
      validForDelivery: true,
      validForTakeOut: true,
      validForCatering: true,
      validForDriveThru: true,
      staffGrantable,
      isStanding,
    },
    offer_Type: 0,
    discount: null,
    purchase_Requirements: null,
    availability: 0,
  };
};

export const adaptEditOffer = (values: AddOfferValues, offer: Offer) => {
  const {
    title,
    offerCode,
    description,
    validFrom,
    validUntil,
    enabled,
    dontSendExpireReminder,
    couponRedeemLimit,
    eligibleUseCount,
    forHours,
    validTime,
    isStanding,
    enableNoTimeRestriction,
    staffGrantable,
    finePrint,
    /* eslint-disable  */
    cron_Validity,
  } = values;
  const validAfterDate = getFullDateFromDayAndTime(
    validFrom,
    validTime
  ).toISOString();
  return {
    ...offer,
    enabled,
    title,
    offerCode,
    description,
    validFrom: validFrom.toISOString(),
    validUntil: validUntil?.toISOString() ?? null,
    lastModifiedAt: today.toISOString(),
    cron_Validity: cron_Validity,
    expiresOn: validUntil?.toISOString() ?? null,
    finePrint: finePrint || null,
    restrictions: {
      ...offer.restrictions,
      lifeSpanDays: !validUntil
        ? null
        : differenceInDays(validUntil, validFrom ?? new Date()),
      lifeSpanNotExceed: null,
      validAfterDays: differenceInDays(validFrom, today),
      validAfterDate,
      validStartTime1: validAfterDate,
      validTimeDuration1: enableNoTimeRestriction
        ? null
        : `${forHours > 9 ? forHours : `0${forHours}`}:00:00`,
      dontSendExpireReminder,
      couponRedeemLimit,
      eligibleUseCount,
      isStanding,
      staffGrantable,
    },
  };
};
const abbreviationMap: {
  [key: string]: string;
} = {
  Su: 'Sun',
  M: 'Mon',
  T: 'Tue',
  W: 'Wed',
  Th: 'Thu',
  F: 'Fri',
  Sa: 'Sat',
};
export function generateFinePrint(
  selectedDays: string[] | undefined,
  validTime: Time,
  duration: number,
  enableNoTimeRestriction: boolean
) {
  let res = '';
  // Day of Week restriction
  if (selectedDays && selectedDays.length) {
    res += generateRestrictedDayMessage(selectedDays) + '. ';
  }
  // time restriction
  if (duration && !enableNoTimeRestriction) {
    res += generateRestrictedTimeMessage(validTime, duration);
  }
  return res;
}

function generateRestrictedTimeMessage(validTime: Time, duration: number) {
  const { isAMSelected, selectedHour } = validTime;
  const formatStartTime = `${selectedHour} ${isAMSelected ? 'am' : 'pm'}`;
  const startHour = Number(selectedHour.split(':')[0]);
  const calculateEndTime = startHour + duration;
  let isPM = !isAMSelected;
  const cycles = Math.floor(duration / 12) || 1;
  if (calculateEndTime >= 12) {
    for (let i = 0; i < cycles; i++) {
      // isPM = isAMSelected;
      isPM = !isPM;
    }
  }
  console.log(isPM);
  const convertTo12hrFormat =
    calculateEndTime > 12
      ? `${calculateEndTime - 12 * cycles}:00 ${isPM ? 'pm' : 'am'}`
      : `${calculateEndTime}:00 ${isPM ? 'pm' : 'am'}`;

  return `Only valid between ${formatStartTime}-${convertTo12hrFormat}`;
}

function generateRestrictedDayMessage(selectedDays: string[]) {
  if (!selectedDays?.length) return;
  const restrictedAbbreviations = selectedDays.map(
    (day: string) => abbreviationMap[day]
  );
  return `Only valid on ${restrictedAbbreviations.join(', ')}`;
}
