import { Page } from '@ui';
import React from 'react';

export const Home: React.FC = () => {
  return (
    <Page title="Home">
      <div className="p-5">Home</div>
    </Page>
  );
};
