/* eslint-disable react/jsx-wrap-multilines */

import { Icon, Text } from '@ui';
import React, { FC, Fragment } from 'react';
import { summaryDetails } from '../../../config';
import Collapsible from 'react-collapsible';
import { AccordionHeader } from '../AccordionHeader';
import { Colors, Icons, formatNumber } from '@utils';
import cn from 'classnames';

interface DetailSummaryProps {
  className?: string;
}

export const DetailSummary: FC<DetailSummaryProps> = ({ className }) => {
  const icon = (
    <Icon
      icon={Icons.ArrowLeft}
      color={Colors.DarkGrey}
      className="-rotate-90 chevron left-0 !top-1"
    />
  );
  return (
    <div className={className}>
      <div className="flex items-center justify-between border-b border-borderColor">
        <Text
          variant="body2"
          className="!text-[10px] leading-4 !text-borderColor"
        >
          DETAIL SUMMARY
        </Text>
        <Text
          variant="body2"
          className="!text-[10px] leading-4 !text-borderColor"
        >
          PRICE
        </Text>
      </div>

      <div className="mt-[10px] flex flex-col gap-y-4">
        {summaryDetails.map(({ name, price, details }) =>
          details?.length ? (
            <Collapsible
              key={name}
              trigger={
                <AccordionHeader
                  name={name}
                  price={price}
                  className="!pl-[27px]"
                />
              }
              transitionTime={100}
              triggerSibling={icon}
              contentOuterClassName="w-full "
            >
              <div className="py-4 pl-4">
                {details.map((item) => (
                  <Fragment key={item.name}>
                    <Text
                      variant="body2"
                      className="!text-[10px] leading-4 !text-darkgrey underline"
                    >
                      {item.name}
                    </Text>

                    <ul className="flex flex-col py-4 gap-y-4 pl-7">
                      {item.subItems.map((subItem) => (
                        <li
                          key={subItem.name}
                          className="flex items-center justify-between"
                        >
                          <Text
                            variant="body2"
                            className="!text-[10px] leading-4 !text-darkgrey max-w-[160px]"
                          >
                            {subItem.count > 1 && <b>{`${subItem.count}x`}</b>}
                            {subItem.name}
                          </Text>

                          <Text
                            variant="body2"
                            className={cn(
                              '!text-[10px] leading-4 text-darkgrey max-w-[160px]',
                              {
                                '!text-danger': subItem.price < 0,
                              }
                            )}
                          >
                            {subItem.price < 0 && '-'}
                            {' '}
                            $
                            {formatNumber(Math.abs(subItem.price))}
                          </Text>
                        </li>
                      ))}
                    </ul>
                  </Fragment>
                ))}
              </div>
            </Collapsible>
          ) : (
            <AccordionHeader key={name} name={name} price={price} />
          )
        )}
      </div>
    </div>
  );
};
