import { formatNumber } from '@utils';
import { useDispatch } from '@store';
import { CartItem as ICartItem, cartActions } from '@store/entities';
import React, { FC, useEffect, useState } from 'react';
import { SelectCount } from './SelectCount';

const countOptions = [1, 2, 3, 4, 5];

export const CartItem: FC<ICartItem> = (item) => {
  const dispatch = useDispatch();

  const { id, name, price, count, image } = item;
  const [countValue, setCountValue] = useState(count);

  const onDelete = () => {
    dispatch(cartActions.deleteItem(id));
  };

  useEffect(() => {
    dispatch(cartActions.editItem({ ...item, count: countValue }));
  }, [countValue]);

  return (
    <li className="flex items-center justify-between py-6 border-b border-borderColor">
      <div className="flex justify-start items-center gap-x-[18px]">
        <div className="flex items-center justify-center w-[114px] h-[84px] bg-lightgrey rounded-[5px] px-[17px]">
          <img src={image} alt="logo" />
        </div>

        <div>
          <span className="block text-[17px] leading-[25px] font-bold">
            {name}
          </span>
          <div className="flex justify-start items-center gap-x-[10px] mt-[10px]">
            <span className="block text-[13px] leading-5 w-[68px] text-left">
              $
              {formatNumber(price * count)}
            </span>

            <SelectCount
              value={countValue}
              onChange={setCountValue}
              options={countOptions}
            />
          </div>
        </div>
      </div>

      <button
        onClick={onDelete}
        type="button"
        className="text-[13px] leading-5 text-danger hover:opacity-75 active:brightness-75"
      >
        Remove
      </button>
    </li>
  );
};
