import cn from 'classnames';
import React, { FC, ReactNode } from 'react';

import { Loader } from '../Loader/Loader';

interface LoaderContainerProps {
  loading: boolean;
  children: ReactNode;
  className?: string;
  color?: string;
}

export const LoaderContainer: FC<LoaderContainerProps> = ({
  loading,
  className,
  children,
  color,
}) => {
  return (
    <div className={cn('relative', className)}>
      <div className={cn({ 'opacity-40 pointer-events-none': loading })}>
        {children}
      </div>
      {loading && (
        <Loader
          className="absolute top-1/3"
          centered
          withTopIndent
          color={color}
        />
      )}
    </div>
  );
};
