import { Icon, Text } from '@ui';
import React, { FC, ReactNode } from 'react';

interface FieldInfoProps {
  title: string;
  icon: string;
  children: ReactNode;
}

export const FieldInfo: FC<FieldInfoProps> = ({ title, icon, children }) => {
  return (
    <div className="flex items-start justify-start basis-1/2 gap-x-[15px] overflow-hidden">
      <Icon icon={icon} size={16} />

      <div className="-mt-1">
        <Text variant="body1" className="!text-darkgrey">
          {title}
        </Text>

        <Text variant="body1" className="mt-[10px">
          {children}
        </Text>
      </div>
    </div>
  );
};
