/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import { Quill } from 'react-quill';

const Font = Quill.import('formats/font');
Font.whitelist = ['poppins', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

export const modules = {
  toolbar: {
    container: '#toolbar',
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
];

export const EditorToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <button type="button" className="ql-bold" />
      <button type="button" className="ql-italic" />
      <button type="button" className="ql-underline" />
      <button type="button" className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button type="button" className="ql-list" value="ordered" />
      <button type="button" className="ql-list" value="bullet" />
      <button type="button" className="ql-indent" value="-1" />
      <button type="button" className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button type="button" className="ql-script" value="super" />
      <button type="button" className="ql-script" value="sub" />
      <button type="button" className="ql-blockquote" />
      <button type="button" className="ql-direction" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button type="button" className="ql-link" />
      <button type="button" className="ql-image" />
      <button type="button" className="ql-video" />
    </span>
    <span className="ql-formats">
      <button type="button" className="ql-formula" />
      <button type="button" className="ql-code-block" />
      <button type="button" className="ql-clean" />
    </span>
  </div>
);
