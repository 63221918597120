import {
  Button,
  CheckboxSelector,
  DatePicker,
  Icon,
  Input,
  Modal,
  Select,
  Text,
  TextEditor,
  TimePicker,
} from '@ui';
import { TagsInput } from 'react-tag-input-component';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import {
  ReportSchedulerSchema,
  ReportSchedulerValues,
} from './helpers/validation';
import { Time } from '@features';
import {
  TagsInputField,
  frequencyOptions,
  tagsInputTabs,
} from './helpers/constants';
import { Icons, TIME_ZONES } from '@utils';
import cn from 'classnames';

interface ReportSchedulerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ReportSchedulerModal: FC<ReportSchedulerModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [activeInputTab, setActiveTagInputTab] =
    useState<TagsInputField>('from');

  const [isShowMessageEditor, setShowMessageEditor] = useState(false);

  const form = useFormik<ReportSchedulerValues>({
    initialValues: {
      name: '',
      startDate: new Date(),
      startTime: {
        isAMSelected: true,
        selectedHour: '08:00',
      },
      frequency: null as any,
      timezone: 2,
      mapping: [] as any,
      from: [] as any,
      to: [] as any,
      cc: [],
      bcc: [],
      subject: '',
      message: '',
    },
    validationSchema: toFormikValidationSchema(ReportSchedulerSchema),
    onSubmit: console.log,
  });

  const {
    values,
    touched,
    errors,
    isValid,
    setFieldValue,
    handleBlur,
    handleSubmit,
  } = form;

  return (
    <FormikProvider value={form}>
      <Modal
        className="!pt-4"
        withTitle
        withNoCloseButton
        title="Report Scheduler"
        isModalOpen={isOpen}
        onModalClose={onClose}
      >
        <div className="w-[734px] ">
          <div className="w-full">
            <Input<ReportSchedulerValues>
              label="Scheduler Name"
              fieldName="name"
              placeholder="Enter Scheduler Name"
            />
          </div>

          <div className="flex mt-[17px]">
            <DatePicker
              className="!w-[200px] mr-3"
              value={values.startDate}
              label="Start Date & Time"
              onChange={(date: Date) => setFieldValue('startDate', date)}
              disableDaysBefore={values.startDate}
            />

            <TimePicker
              className="mt-[22px]"
              value={values.startTime}
              onChange={(time: Time) => setFieldValue('startTime', time)}
            />
          </div>

          <div className="flex mt-4">
            <Select
              id="frequency"
              className="mr-3 min-w-[120px]"
              label="Frequency"
              value={values.frequency}
              placeholder="Frequency"
              options={frequencyOptions}
              onChange={(newValue) => setFieldValue('frequency', newValue)}
              onBlur={handleBlur}
              error={!!errors.frequency && !!touched?.frequency}
            />

            <Select
              label="Timezone"
              wrapperClassName="w-full"
              value={values.timezone}
              placeholder="(UTC+8:00) Kuala Lumpur, Singapore "
              options={TIME_ZONES}
              onChange={(newValue) => setFieldValue('timezone', newValue)}
            />
          </div>

          <Text variant="body2" className="mt-4">
            Mapping
          </Text>

          <CheckboxSelector<ReportSchedulerValues>
            fieldName="mapping"
            className="mt-3 !w-full"
            label="Reports Mapping"
            options={['Lift Report', 'Location Report', 'Redemption']}
          />

          <Text variant="body2" className="mt-4">
            Message
          </Text>

          <div className="mt-5">
            {tagsInputTabs.map((tab) => (
              <button
                key={tab.fieldName}
                type="button"
                onClick={() => setActiveTagInputTab(tab.fieldName)}
                className={cn(
                  'py-[5px] box-border px-[15px] text-[13px] border-2 border-transparent bg-lightgrey first:rounded-tl-[5px] last:rounded-tr-[5px] cursor-pointer hover:bg-customerology hover:opacity-75 hover:text-white',
                  {
                    '!bg-customerology text-white hover:opacity-100 cursor-default':
                      tab.fieldName === activeInputTab,
                    '!border-danger':
                      touched[tab.fieldName] && errors[tab.fieldName],
                  }
                )}
              >
                {tab.title}
              </button>
            ))}
          </div>

          <TagsInput
            name={activeInputTab}
            value={values[activeInputTab]}
            onChange={(tags) => setFieldValue(activeInputTab, tags)}
            onBlur={handleBlur}
          />

          <div className="w-full mt-4">
            <Input<ReportSchedulerValues>
              label="Subject"
              fieldName="subject"
              placeholder="Weekly Report"
            />
          </div>

          <button
            onClick={() => setShowMessageEditor(!isShowMessageEditor)}
            type="button"
            className={cn('mt-4 hover:opacity-75', {
              underline: !isShowMessageEditor,
            })}
          >
            <Text variant="body2" className="!leading-[18px] !font-bold">
              {isShowMessageEditor ? (
                <div className="flex items-center justify-start">
                  <span className="mr-1">Cancel</span>
                  <Icon icon={Icons.Close} size={14} />
                </div>
              ) : (
                'Add Message'
              )}
            </Text>
          </button>

          {isShowMessageEditor && (
            <TextEditor<ReportSchedulerValues>
              className="mt-5"
              fieldName="message"
            />
          )}

          <div className="flex mt-8">
            <Button
              onClick={handleSubmit}
              label="Save User Details"
              iconRight={Icons.ArrowRight}
              className="!bg-customerology mr-[10px]"
              iconClassName="!ml-2"
              disabled={!isValid}
            />

            <button
              type="button"
              onClick={onClose}
              className="px-4 hover:opacity-75"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </FormikProvider>
  );
};
