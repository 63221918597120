import React from 'react';

export const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload?.length) {
    return (
      <>
        <div className="relative h-[13px] w-[13px] border border-white bg-success rounded-full" />
        <div className="absolute flex justify-center items-center bg-white py-1 px-3 left-5 top-[-50%] bottom-[-50%] rounded-[5px] shadow text-[13px]">
          <strong>{'Data: '}</strong>
          <span>{payload[0].value}</span>
        </div>
      </>
    );
  }

  return null;
};

export const CustomYAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor="end"
        fill="#696868"
        fontFamily="Poppins, system-ui, sans-serif"
        fontSize={10}
        fontWeight={400}
      >
        {payload.value}
      </text>
    </g>
  );
};

export const CustomXAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        dx={8}
        textAnchor="end"
        fill="rgba(0, 0, 0, 0.4)"
        fontFamily="Poppins, system-ui, sans-serif"
        fontSize={10}
        fontWeight={400}
      >
        {payload.value.slice(0, 3)}
      </text>

      <text
        x={0}
        y={15}
        dy={8}
        dx={5}
        textAnchor="end"
        fill="rgba(0, 0, 0, 0.4)"
        fontFamily="Poppins, system-ui, sans-serif"
        fontSize={10}
        fontWeight={400}
      >
        {payload.value.slice(4, 6)}
      </text>
    </g>
  );
};
