import { Icon, LoaderContainer, SearchInput, Select, Table, Text } from '@ui';
import React, { FC, useMemo, useState } from 'react';
import { locationFieldsOptions, statusOptions } from './config';
import { createColumnHelper, noop } from '@tanstack/react-table';
import { Icons, MOCKED_ENTERPRISE_ID, formatAddress } from '@utils';
import {
  FETCH_LOCATIONS_LIMIT,
  Location,
  useGetLocationCountQuery,
  useGetLocationsQuery,
  useToggleLocationStateMutation,
} from '@api';
import { Pagination } from '@features';
import { AddLocationModal } from './AddLocationModal';

export const Locations: FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const { data: locationsCount, isLoading: isLoadingPageCount } =
    useGetLocationCountQuery(MOCKED_ENTERPRISE_ID);

  const [
    toggleLocationState,
    { isLoading: isToggling, isError: isErrorToggling },
  ] = useToggleLocationStateMutation();

  const {
    data: locations,
    isLoading,
    isFetching,
  } = useGetLocationsQuery(
    { id: MOCKED_ENTERPRISE_ID, pageNum: currentPage },
    { refetchOnMountOrArgChange: true }
  );

  const columnHelper = createColumnHelper<Location>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        header: 'Location Name',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.jsonStreetAddress?.[0] || null, {
        header: 'Location Address',
        cell: (cell) =>
          cell.getValue()?.street ? formatAddress(cell.getValue()!) : '-',
      }),
      columnHelper.accessor((row) => row.businessPhoneNumber, {
        header: 'Location Phone Number',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.enabled, {
        header: 'Status',
        cell: (cell) => cell.getValue() || '-',
      }),
    ],
    []
  );

  const pageCount = locationsCount
    ? Math.ceil(locationsCount / FETCH_LOCATIONS_LIMIT)
    : 1;

  const isTableLoading =
    isLoading || isFetching || isLoadingPageCount || isToggling;

  return (
    <section className="h-full pt-11">
      <div className="flex flex-col justify-start h-full">
        <div className="flex items-center justify-between">
          <Text variant="h4">List of locations</Text>

          <button
            type="button"
            onClick={() => setModalOpen(true)}
            className="flex items-center justify-start hover:opacity-80 active:opacity-100"
          >
            <Text variant="h4">Add</Text>
            <Icon icon={Icons.Close} className="ml-2 rotate-45" size={19} />
          </button>
        </div>

        <div className="flex items-center w-full py-5 px-4 mt-[10px] border border-[#DCDCDC] rounded-md mb-4">
          <div className="mr-[10px]">
            <Select
              value="name"
              options={locationFieldsOptions}
              onChange={noop}
              withoutBorder
            />
          </div>

          <div className="mr-[10px]">
            <Select
              value="active"
              options={statusOptions}
              onChange={noop}
              withoutBorder
            />
          </div>

          <div className="grow">
            <SearchInput placeholder="Search" />
          </div>
        </div>

        <LoaderContainer loading={isTableLoading}>
          <div className="overflow-auto custom-scroll max-h-[350px] h-auto">
            {locations?.length ? (
              <Table
                className="!m-0"
                withStatusSwitch
                withControlPanel={false}
                withEdit={false}
                data={locations || []}
                columns={columns}
                uniqueKey="id"
                statusKey="enabled"
                isError={isErrorToggling}
                onEdit={noop}
                onDelete={noop}
                onToggle={toggleLocationState}
              />
            ) : (
              <div className="flex items-center justify-center w-full pt-10 pb-14">
                There is no data
              </div>
            )}
          </div>
        </LoaderContainer>
      </div>

      <Pagination
        className="mx-auto"
        page={currentPage}
        setPage={setCurrentPage}
        pageCount={pageCount}
      />

      <AddLocationModal
        withTitle
        title="Add a New Location"
        isModalOpen={isModalOpen}
        onModalClose={() => setModalOpen(false)}
      />
    </section>
  );
};
