import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../baseQuery';
import { HTTPMethod } from '../config';

import { ApplicationDashboardOverview } from './models';
import { GetOverviewRequest } from './config';

export const overviewApi = createApi({
  baseQuery,
  reducerPath: 'overviewApi',
  endpoints: (builder) => ({
    getOverview: builder.query<
      ApplicationDashboardOverview,
      GetOverviewRequest
    >({
      query: ({ Enterprise_Id, Location_Id }) => ({
        url: `/Analytics/Dashboard/Overview/Application/${process.env.REACT_APP_APP_UUID}`,
        params: { Enterprise_Id, Location_Id },
        method: HTTPMethod.GET,
      }),
    }),
  }),
});

export const { useGetOverviewQuery } = overviewApi;
