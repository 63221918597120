import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { SESSION_TOKEN } from '@utils';

const initialQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    headers.set('X-Auth', `${SESSION_TOKEN}`);

    return headers;
  },
});

const mutex = new Mutex();

type BaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

const baseQuery: BaseQuery = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await initialQuery(args, api, extraOptions);

  const error =
    result.error &&
    (result.error.status === 401 || result.meta?.response?.status === 401);

  if (error) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        result = await initialQuery(args, api, extraOptions);
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await initialQuery(args, api, extraOptions);
    }
  }

  return result;
};

export { baseQuery };
