export interface PeriodSelectItem {
  label: string;
  days: number | null;
  currentPeriodSize: 'week' | 'month' | false;
}

export const periodsItems: PeriodSelectItem[] = [
  {
    label: 'Today',
    days: 0,
    currentPeriodSize: false,
  },
  {
    label: 'Yesterday',
    days: 1,
    currentPeriodSize: false,
  },
  {
    label: 'Current Week',
    days: null,
    currentPeriodSize: 'week',
  },
  {
    label: 'Last Week',
    days: 7,
    currentPeriodSize: false,
  },
  {
    label: 'This Month',
    days: null,
    currentPeriodSize: 'month',
  },
  {
    label: 'Last Month',
    days: 31,
    currentPeriodSize: false,
  },
  {
    label: 'Last 90 Days',
    days: 90,
    currentPeriodSize: false,
  },
  {
    label: 'Last 180 Days',
    days: 180,
    currentPeriodSize: false,
  },
  {
    label: 'Last 365 Days',
    days: 365,
    currentPeriodSize: false,
  },
];
