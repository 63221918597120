import { Text } from '@ui';
import React, { FC } from 'react';
import { match } from 'ts-pattern';
import { Privacy, Security, Terms } from './LegalInformationContent';

export type LegalInformationVariant = 'security' | 'privacy' | 'terms';

interface LegalInformationProps {
  variant: LegalInformationVariant;
}

export const LegalInformation: FC<LegalInformationProps> = ({ variant }) => {
  return (
    <main className="h-full">
      <Text variant="h4" className="!font-bold text-center">
        {variant === 'privacy' && 'Privacy'}
        {variant === 'security' && 'Security'}
        {variant === 'terms' && 'Terms'}
      </Text>

      <section className="rounded-[5px] mt-[10px] max-h-[537px] w-full bg-[#F9F9F9] px-[25px] overflow-auto py-[21px] custom-scroll custom-scroll-plumwifi">
        <p className="text-[10px] leading-[15px]">
          {match(variant)
            .with('security', () => <Security />)
            .with('privacy', () => <Privacy />)
            .with('terms', () => <Terms />)
            .exhaustive()}
        </p>
      </section>
    </main>
  );
};
