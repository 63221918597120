import { Icons } from '@utils';
import { PageTab, SelectOption } from '@ui';

import { Hour } from '@features';

export interface IndicatorItem {
  label: string;
  counter: number;
  rising?: boolean;
}

export interface InitialDayValues {
  applicable: boolean;
  startTime: {
    isAMSelected: boolean;
    selectedHour: Hour;
  };
  endTime: {
    isAMSelected: boolean;
    selectedHour: Hour;
  };
}

export const tabItems: PageTab[] = [
  { icon: Icons.GlobeStretched, label: 'Overview' },
  { icon: Icons.LocationStretched, label: 'Locations' },
  { icon: Icons.PercentsStretched, label: 'Offers' },
];

export const indicatorItems: IndicatorItem[] = [
  { label: 'Coupon Used', counter: 31, rising: false },
  { label: 'Active Coupons', counter: 113, rising: true },
  { label: 'Guest Within Range', counter: 98, rising: true },
  { label: 'Notification Read', counter: 217, rising: true },
];

export const chartData = [
  { date: 'Feb 22', counter: 15 },
  { date: 'Mar 22', counter: 5 },
  { date: 'Apr 22', counter: 12 },
  { date: 'May 22', counter: 11 },
  { date: 'Jun 22', counter: 6 },
  { date: 'Jul 22', counter: 15 },
  { date: 'Aug 22', counter: 5 },
  { date: 'Sep 22', counter: 18 },
  { date: 'Oct 22', counter: 22 },
  { date: 'Nov 22', counter: 13 },
  { date: 'Dec 22', counter: 35 },
  { date: 'Jan 23', counter: 45 },
  { date: 'Feb 23', counter: 45 },
];

export const chartOptions: SelectOption[] = [
  { label: 'Page Views', value: 'Page Views' },
  { label: 'Coupons Views', value: 'Coupons Views' },
  { label: 'Coupon Used', value: 'Coupon Used' },
  { label: 'Active Coupons', value: 'Active Coupons' },
  { label: 'Active Location Views', value: 'Active Location Views' },
  { label: 'Guest Within Range', value: 'Guest Within Range' },
  { label: 'Notification Sent', value: 'Notification Sent' },
  { label: 'Notification Read', value: 'Notification Read' },
];

export type SearchLocationBy = 'name' | 'address' | 'businessPhoneNumber';

export const locationsOptions: SelectOption[] = [
  { label: 'Store Name', value: 'name' },
  { label: 'Address', value: 'address' },
  { label: 'Phone Number', value: 'businessPhoneNumber' },
];

export type SearchOfferBy = 'title' | 'description' | 'shortCode';

export const offersOptions: SelectOption[] = [
  { label: 'Title', value: 'title' },
  { label: 'Description', value: 'description' },
  { label: 'Shortcode', value: 'shortCode' },
];

export const weekDaysOptions: SelectOption[] = [
  {
    label: 'Sunday',
    value: 'Su',
  },
  {
    label: 'Monday',
    value: 'M',
  },
  {
    label: 'Tuesday',
    value: 'T',
  },
  {
    label: 'Wednesday',
    value: 'W',
  },
  {
    label: 'Thursday',
    value: 'Th',
  },
  {
    label: 'Friday',
    value: 'F',
  },
  {
    label: 'Saturday',
    value: 'Sa',
  },
];
