import React, { useEffect, useState } from 'react';

import {
  Button,
  Input,
  Modal,
  ModalProps,
  Select,
  Switch,
  LoaderContainer,
} from '@ui';
import { DayToggler, DayTogglerValues } from '@features';
import { MOCKED_ENTERPRISE_ID, STATES_OPTIONS, WEEK_DAYS } from '@utils';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import cn from 'classnames';

import './style.css';
import {
  Address,
  Location,
  useCreateLocationMutation,
  useEditLocationMutation,
  useGetLocationByIdQuery,
} from '@api';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useToast } from '@hooks';

import {
  AddLocationSchema,
  AddLocationValues,
  adaptEditLocation,
  adaptAddLocation,
} from './helpers';

const initialDayValues = {
  applicable: false,
  startTime: {
    isAMSelected: true,
    selectedHour: '06:00',
  },
  endTime: {
    isAMSelected: false,
    selectedHour: '06:00',
  },
};

const initialApplicableDays = new Array(7).fill(initialDayValues);

type LocationModalProps = ModalProps & {
  locationId?: number | null;
  setLocationId: (id: number | null) => void;
};

export const LocationModal: React.FC<LocationModalProps> = ({
  locationId,
  isModalOpen,
  setLocationId,
  onModalClose,
}) => {
  const { showErrorToast } = useToast();
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);

  const [createLocation] = useCreateLocationMutation();
  const [editLocation] = useEditLocationMutation();
  const { data: location, isFetching: isLocationLoading } =
    useGetLocationByIdQuery(locationId ?? skipToken);

  const isEditMode = !!locationId;

  useEffect(() => {
    if (!locationId) {
      setCurrentLocation(null);
    }

    if (location && locationId) {
      setCurrentLocation(location);
    }
  }, [location, locationId]);

  const onSubmit = async (
    values: AddLocationValues,
    { resetForm }: FormikHelpers<AddLocationValues>
  ) => {
    try {
      if (locationId && location) {
        await editLocation({
          id: locationId,
          location: adaptEditLocation(values, location),
        }).unwrap();

        resetForm();
        onModalClose();

        return;
      }

      await createLocation({
        id: MOCKED_ENTERPRISE_ID,
        location: adaptAddLocation(values),
      }).unwrap();

      resetForm();

      onModalClose();
    } catch (error) {
      showErrorToast();
    }
  };

  const address: Address =
    !isLocationLoading && currentLocation?.jsonStreetAddress
      ? currentLocation?.jsonStreetAddress[0]
      : { street: '', city: '', zipcode: '', state: '' };

  const form = useFormik<AddLocationValues>({
    initialValues: {
      name: (!isLocationLoading && currentLocation?.name) || '',
      street: address.street || '',
      state: null,
      city: address.city || '',
      zipcode: address.zipcode || '',
      businessPhoneNumber:
        (!isLocationLoading && currentLocation?.businessPhoneNumber) || '',
      website: (!isLocationLoading && currentLocation?.website) || '',
      enabled: (!isLocationLoading && currentLocation?.enabled) || true,
      hours: initialApplicableDays,
    },
    enableReinitialize: true,
    validationSchema: toFormikValidationSchema(AddLocationSchema),
    onSubmit,
  });

  const {
    values,
    isSubmitting,
    isValid,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleBlur,
    resetForm,
  } = form;

  const onSelectStateChange = (newValue: string) => {
    setFieldValue('state', newValue);
  };

  const handleChangeDay = (dayIndex: number, dayValues: DayTogglerValues) => {
    const { startTime, endTime, enabled } = dayValues;

    const updatedApplicableDays = values.hours.map((day, index) => {
      if (index === dayIndex) {
        return { startTime, endTime, applicable: enabled };
      }

      return day;
    });

    setFieldValue('hours', updatedApplicableDays);
  };

  const onClose = () => {
    setLocationId(null);
    setCurrentLocation(null);
    resetForm();
    onModalClose();
  };

  return (
    <FormikProvider value={form}>
      <Modal isModalOpen={isModalOpen} onModalClose={onClose}>
        <LoaderContainer loading={isLocationLoading}>
          <div className="flex">
            <div className="flex flex-col justify-between w-[330px] mr-[30px]">
              <div>
                <span className="font-bold text-[29px] leading-[43.5px]">
                  {isEditMode ? 'Edit Store Location' : 'Add Store Location'}
                </span>

                <p>
                  {isEditMode
                    ? 'Please edit store location details for this specific enterprise'
                    : 'Please add store location details for this specific enterprise'}
                </p>

                <div className="mt-2">
                  <Input<AddLocationValues>
                    placeholder="Store"
                    fieldName="name"
                  />
                </div>

                <div className="mt-1">
                  <Input<AddLocationValues>
                    placeholder="Street"
                    fieldName="street"
                  />
                </div>

                <div className="flex items-center justify-between mt-[2px] gap-x-3">
                  <Select
                    id="state"
                    wrapperClassName="basis-[46%]"
                    value={values.state}
                    onChange={onSelectStateChange}
                    onBlur={handleBlur}
                    placeholder="State"
                    options={STATES_OPTIONS}
                    error={!!errors?.state && !!touched.state}
                  />
                  <Input<AddLocationValues>
                    placeholder="City"
                    fieldName="city"
                  />
                  <Input<AddLocationValues>
                    placeholder="Zip"
                    fieldName="zipcode"
                  />
                </div>

                <div className="mt-1">
                  <Input<AddLocationValues>
                    placeholder="Phone Number"
                    fieldName="businessPhoneNumber"
                  />
                </div>

                <div className="mt-1">
                  <Input<AddLocationValues>
                    placeholder="Website"
                    fieldName="website"
                  />
                </div>

                <div className="flex items-center my-6">
                  <span className="text-[13px] text-darkgrey mr-[20px]">
                    Status
                  </span>

                  <Switch
                    checked={values.enabled}
                    onCheckedChange={() => {
                      setFieldValue('enabled', !values.enabled);
                    }}
                  />
                </div>
              </div>

              <Button
                className="w-full"
                loading={isSubmitting}
                type="default"
                label="Save Store Location"
                onClick={handleSubmit}
                disabled={!isValid}
              />
            </div>

            <div className="flex flex-col flex-1 w-[470px] p-2 pb-3 rounded-[5px] linear-background">
              <p className="mb-2 text-white">
                Toggle applicable days and select opening and closing hours.
              </p>

              <div className="flex flex-col flex-1">
                {WEEK_DAYS.map((day, index, days) => {
                  const isLast = index === days.length - 1;

                  return (
                    <div key={day} className={cn({ 'mb-[3px]': !isLast })}>
                      <DayToggler
                        values={values.hours[index]}
                        day={day}
                        // checked={false}
                        zIndex={100 - index}
                        index={index}
                        onChange={handleChangeDay}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </LoaderContainer>
      </Modal>
    </FormikProvider>
  );
};
