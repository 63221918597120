import React from 'react';

import { Spinner } from '../Spinner';
import cn from 'classnames';
import { Icon } from '../Icon';
import { Solution } from '@types';
import { backgroundEnum } from '@utils';

interface ButtonProps {
  type?: 'default' | 'add';
  label: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconRight?: string;
  color?: Solution | 'white';
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  type = 'default',
  label,
  loading,
  disabled,
  className,
  iconClassName,
  iconRight,
  color = 'fullfork',
  onClick,
}) => {
  if (type === 'add') {
    return (
      <div className={cn('flex flex-col items-center', className)}>
        <button
          type="button"
          className={cn(
            'h-[44px] w-[44px] flex justify-center items-center rounded-lg text-white shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] hover:brightness-110',
            {
              ...backgroundEnum(color),
              '!text-text bg-white': color === 'white',
            }
          )}
          onClick={onClick}
        >
          <span className="text-[23px] leading-[23px] text-center translate-y-[1px]">
            +
          </span>
        </button>

        <span className="text-[11px] text-darkgrey text-center mt-[10px]">
          {label}
        </span>
      </div>
    );
  }

  return (
    <button
      type="button"
      className={cn(
        'flex items-center justify-center h-[54px] px-4 rounded-[5px] hover:brightness-110 transition-all text-white disabled:cursor-default disabled:opacity-50 disabled:hover:brightness-100 active:brightness-75',
        {
          ...backgroundEnum(color),
          '!text-text bg-white shadow-lg': color === 'white',
        },
        className
      )}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? <Spinner /> : <span>{label}</span>}
      {iconRight && (
        <Icon
          size="18px"
          icon={iconRight}
          className={cn('ml-1', iconClassName)}
        />
      )}
    </button>
  );
};
