import cn from 'classnames';
import { useField } from 'formik';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  MouseEvent,
  useRef,
  useState,
} from 'react';
import { Icon } from '../Icon';
import { Icons } from '@utils';
import { Modal } from '../Modal';
import { match } from 'ts-pattern';

interface FileInputProps<Form> extends InputHTMLAttributes<HTMLInputElement> {
  fieldName: keyof Form;
  withPreview?: boolean;
}

export const FileInput = <Form extends object>({
  fieldName,
  withPreview,
  ...rest
}: FileInputProps<Form>) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [{ value: formikValue }, , { setValue: setFormikValue }] = useField<
    File | string | null
  >({
    name: fieldName as string,
  });

  const [filePath, setFilePath] = useState('');

  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilePath(e.target.value);

    if (e.target?.files?.length) {
      setFormikValue(e.target.files[0]);
    }
  };

  const onReset = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    setFilePath('');
    setFormikValue(null);
  };

  const onClickInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      className={cn(
        'w-full flex justify-end items-center border border-borderColor rounded-[5px] h-10',
        rest.className
      )}
    >
      {formikValue && isPreviewOpen && (
        <Modal
          isModalOpen={isPreviewOpen}
          onModalClose={() => setPreviewOpen(false)}
        >
          <img
            height={500}
            width={500}
            className=""
            src={
              typeof formikValue === 'string'
                ? formikValue
                : URL.createObjectURL(formikValue)
            }
            alt="preview"
          />
        </Modal>
      )}
      <span
        className={cn(
          ' px-[10px] text-[13px] whitespace-nowrap overflow-hidden text-ellipsis text-darkgrey',
          {
            'w-3/5': !formikValue,
            'w-4/5': formikValue,
          }
        )}
      >
        {match(!!formikValue)
          .with(true, () =>
            typeof formikValue === 'string' ? formikValue : formikValue?.name
          )
          .otherwise(() => rest.placeholder)}
      </span>

      <label
        className={cn(
          'bg-text h-10 text-[13px] rounded-r-[5px] cursor-pointer ',
          {
            'w-2/5 px-1': !formikValue,
            'w-1/5': formikValue,
            'w-2/5 ': formikValue && withPreview,
          }
        )}
      >
        <div className={cn('flex ', {})}>
          {formikValue && withPreview && (
            <button
              type="button"
              onClick={() => setPreviewOpen(true)}
              className="px-2 leading-10 text-white border-r border-white bg-text whitespace-nowrap hover:opacity-80"
            >
              <Icon icon={Icons.Eye} />
            </button>
          )}
          <button
            type="button"
            onClick={formikValue ? onReset : onClickInput}
            className="px-4 leading-10 text-white rounded-r-[5px] bg-text whitespace-nowrap hover:opacity-90"
          >
            {formikValue ? 'Reset' : 'Upload'}
          </button>
        </div>

        <input
          ref={fileInputRef}
          className="hidden"
          name={fieldName as string}
          value={filePath}
          onChange={onChange}
          {...rest}
          type="file"
          accept="image/*"
        />
      </label>
    </div>
  );
};
