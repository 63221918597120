import { Button, Input, Modal, ModalProps, Select } from '@ui';
import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { Icons } from '@utils';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { AddRoleSchema, AddRoleValues } from '../helpers/validation';
import { permissionSetOptions } from '../config';

type AddRoleModalProps = ModalProps;

export const AddRoleModal: FC<AddRoleModalProps> = (props) => {
  const form = useFormik<AddRoleValues>({
    initialValues: {
      role: '',
      permissionSet: 0,
    },
    validationSchema: toFormikValidationSchema(AddRoleSchema),
    onSubmit: console.log,
  });

  const { values, setFieldValue, handleSubmit } = form;

  return (
    <Modal
      {...props}
      className="!px-[60px] !pb-[45px] !pt-6 w-[438px] !overflow-visible"
    >
      <FormikProvider value={form}>
        <div className="flex flex-col gap-y-[10px] ">
          <Input<AddRoleValues>
            fieldName="role"
            label="First Name"
            placeholder="Enter Firstname"
            inputClassName="mt-[1px] "
          />

          <Select
            label="Role"
            value={values.permissionSet}
            onChange={(value) => setFieldValue('permissionSet', value)}
            options={permissionSetOptions}
            placeholder="Select"
          />
        </div>
        <Button
          onClick={handleSubmit}
          color="customerology"
          label="Save Role Details"
          iconRight={Icons.ArrowRight}
          className="mt-6"
        />
      </FormikProvider>
    </Modal>
  );
};
