import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../baseQuery';
import { HTTPMethod } from '../config';

export const sThreeApi = createApi({
  baseQuery,
  reducerPath: 'sThreeApi',
  endpoints: (builder) => ({
    uploadFile: builder.mutation<string, File>({
      query: (file) => {
        const filePath = URL.createObjectURL(file);
        const formData = new FormData();

        formData.append('file', file);

        return {
          url: '/SThree',
          method: HTTPMethod.POST,
          params: { filePath },
          body: formData,
          responseHandler: (response) => response.text(),
        };
      },
    }),
  }),
});

export const { useUploadFileMutation } = sThreeApi;
