import { useState } from 'react';

export const useAccordions = (length: number) => {
  const initialController = new Array(length).fill(null).map(() => ({
    isOpen: false,
  }));

  const [controller, setController] = useState(initialController);

  const onOpen = (index: number) => {
    const newController = [...initialController];
    newController[index].isOpen = true;

    setController(newController);
  };

  const onClose = () => {
    setController(initialController);
  };

  return { accordions: controller, onOpen, onClose };
};
