import React from 'react';
import cn from 'classnames';
import {
  CaptionProps,
  DayContentProps,
  useNavigation,
  DayContent as PickerDayContent,
} from 'react-day-picker';
import { Icons, Colors, formatDate, DateFormats } from '@utils';

import { Icon } from '../Icon';

export const Caption = ({ displayMonth }: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  return (
    <div className="flex flex-row items-center justify-between px-3 mb-2">
      <button
        type="button"
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      >
        <Icon
          icon={Icons.CollapsibleArrow}
          color={Colors.FullFork}
          size={9}
          className="rotate-90"
        />
      </button>

      <span className="text-[11px] translate-y-[1px]">
        {formatDate(displayMonth, DateFormats.MONTH_YEAR)}
      </span>

      <button
        type="button"
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      >
        <Icon
          icon={Icons.CollapsibleArrow}
          color={Colors.FullFork}
          size={9}
          className="-rotate-90"
        />
      </button>
    </div>
  );
};

export const DayContent = (props: DayContentProps) => {
  const { activeModifiers } = props;

  return (
    <div
      className={cn('font-normal text-[11px]', {
        'text-fullfork':
          !!activeModifiers.today &&
          !(
            !!activeModifiers.range_start ||
            !!activeModifiers.range_middle ||
            !!activeModifiers.range_end
          ) &&
          !activeModifiers.selected,
      })}
    >
      <PickerDayContent {...props} />
    </div>
  );
};
