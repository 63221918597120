import { Button, Input, Modal, ModalProps } from '@ui';
import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { AddLocationSchema, AddLocationValues } from '../helpers/validation';
import { Icons } from '@utils';
import { toFormikValidationSchema } from 'zod-formik-adapter';

type AddLocationModalProps = ModalProps;

export const AddLocationModal: FC<AddLocationModalProps> = (props) => {
  const form = useFormik<AddLocationValues>({
    initialValues: {
      name: '',
      address: '',
      areaCode: '',
      phone: '',
    },
    validationSchema: toFormikValidationSchema(AddLocationSchema),
    onSubmit: console.log,
  });

  const { handleSubmit } = form;

  return (
    <Modal {...props} className="!px-[60px] !pb-14 !mt-5 max-w-[438px]">
      <FormikProvider value={form}>
        <div className="flex flex-col gap-y-[10px] ">
          <Input<AddLocationValues>
            fieldName="name"
            label="Location Name"
            placeholder="Enter Location Name"
          />

          <Input<AddLocationValues>
            fieldName="address"
            label="Location Address"
            placeholder="Enter Location Address"
          />

          <div>
            <span className="text-[9px] leading-4 text-darkgrey">
              Phone Number
            </span>

            <div className="flex items-center justify-between mt-2 gap-x-4">
              <div className="basis-1/4">
                <Input<AddLocationValues>
                  fieldName="areaCode"
                  label="Area Code"
                  placeholder="+000"
                  type="number"
                />
              </div>

              <div className="basis-3/4">
                <Input<AddLocationValues>
                  fieldName="phone"
                  label="Phone Number"
                  placeholder="000-0000"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>

        <Button
          onClick={handleSubmit}
          color="customerology"
          label="Save Location Details"
          iconRight={Icons.ArrowRight}
          className="mt-8"
        />
      </FormikProvider>
    </Modal>
  );
};
