import { Button, DatePicker, SearchInput, Select } from '@ui';
import { noop } from '@tanstack/react-table';
import React, { FC } from 'react';
import { locationsOptions } from '../config';
import { Icons } from '@utils';
import { Link } from 'react-router-dom';
import { Routes } from '@navigation';
import cn from 'classnames';
import { AllOrNothing } from '@types';
import { useOuterFrameParam } from '@hooks';

interface BaseReportingFiltersProps {
  className?: string;
}
type ReportingFiltersProps = BaseReportingFiltersProps &
  AllOrNothing<{
    schedulerVariant?: boolean;
    onOpenModal: () => void;
  }>;

export const ReportingFilters: FC<ReportingFiltersProps> = ({
  className,
  schedulerVariant,
  onOpenModal,
}) => {
  const withOuterFrame = useOuterFrameParam();

  return (
    <div
      className={cn(
        'flex items-center w-full py-4 px-4 gap-x-6 border border-[#DCDCDC] rounded-md',
        className
      )}
    >
      <div className="flex items-center justify-between border border-[#DCDCDC] rounded-md ">
        <DatePicker
          value={new Date()}
          onChange={noop}
          withNoBorder
          type="range"
        />
      </div>
      <div className=" grow">
        <Select
          options={locationsOptions}
          onChange={noop}
          placeholder="Location"
          value={null}
        />
      </div>

      <Select
        value={null}
        options={locationsOptions}
        onChange={noop}
        withoutBorder
      />

      <div className="grow">
        <SearchInput placeholder="Search" />
      </div>

      {schedulerVariant && (
        <Button
          onClick={onOpenModal}
          label="Add New Scheduler"
          className="!w-auto !h-10 !bg-customerology hover:opacity-90 whitespace-nowrap"
          iconRight={Icons.Close}
          iconClassName="rotate-45"
        />
      )}

      <Link
        to={{
          pathname: Routes.REPORTING,
          search: withOuterFrame ? '?isOuterFrame=true' : '',
        }}
      >
        <Button
          label="Back"
          className="!w-auto !h-10 !bg-text hover:opacity-90"
          iconRight={Icons.ArrowBack}
        />
      </Link>
    </div>
  );
};
