import { RoutersList } from '@features';
import { Colors, Images, formatTime } from '@utils';
import React, { FC } from 'react';
import { NotificationSettings } from './NotificationSettings';
import { FormikProvider, useFormik } from 'formik';
import { SettingsSchema, SettingsValues } from './helpers/settingsValidation';
import {
  Button,
  Input,
  LoaderContainer,
  Select,
  SelectDay,
  Text,
  TimePicker,
} from '@ui';
import {
  bandWidthOptions,
  downloadLimitOptions,
  durationOptions,
} from './config';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useSelector } from '@store';
import { plumWifiSelectors } from '@store/entities';
import { adaptSelectedDays } from './helpers/adaptSelectedDays';

export const Settings: FC = () => {
  const { settings, isSettingsLoading, currentRouter } = useSelector(
    plumWifiSelectors.all
  );

  const form = useFormik<SettingsValues>({
    initialValues: {
      routerName: currentRouter?.name || '',
      guestSSID: settings?.guestEssid || '',
      employeeESSID: settings?.employeeEssid || '',
      ESSIDPassword: settings?.employeeEssidPassword || '',
      bandWidth: settings?.bandwidth
        ? Math.trunc(Number(settings?.bandwidth) / 1000)
        : 0,
      sessionDuration: settings?.sessionDuration
        ? Number(settings.sessionDuration) / 60
        : 0,
      downloadLimit: settings?.downloadLimit
        ? Number(settings.downloadLimit)
        : 0,
      from: formatTime(settings?.startTime || '12:00'),
      to: formatTime(settings?.endTime || '12:00'),
      days: adaptSelectedDays(settings?.daysAvailable),
    },
    validationSchema: toFormikValidationSchema(SettingsSchema),
    enableReinitialize: true,
    onSubmit: console.log,
  });

  const { values, isValid, setFieldValue, handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <main className="flex items-start justify-between gap-x-[50px]">
        <section className="w-[294px] shrink-0">
          <img src={Images.PlumWifiLogo} alt="plum-wifi" />

          <RoutersList className="mt-10 min-h-[200px]" />
          <div className="mt-10">
            <NotificationSettings />
          </div>
        </section>

        <LoaderContainer
          loading={isSettingsLoading}
          className="basis-[75%]"
          color={Colors.PlumWifi}
        >
          <section className="basis-[75%] rounded-[10px] shadow-accordion-shadow py-[34px] px-[35px] w-full">
            <Text variant="h5" className="!font-bold">
              WiFi Settings
            </Text>

            <div className="flex items-stretch justify-around mt-6 gap-x-6">
              <div className="flex flex-col items-center justify-between gap-y-6 min-w-[180px]">
                <Input<SettingsValues>
                  fieldName="routerName"
                  label="Router Name"
                />

                <Input<SettingsValues>
                  fieldName="guestSSID"
                  label="Guest SSID"
                />

                <Input<SettingsValues>
                  fieldName="employeeESSID"
                  label="Employee ESSID"
                />

                <Input<SettingsValues>
                  fieldName="ESSIDPassword"
                  label="ESSID Password"
                />
              </div>

              <div className="flex flex-col basis-[30%] min-w-[120px] items-start justify-start gap-y-6">
                <Select
                  wrapperClassName="w-full"
                  label="Bandwidth"
                  value={values.bandWidth}
                  onChange={(value) => setFieldValue('bandWidth', value)}
                  options={bandWidthOptions}
                />

                <Select
                  wrapperClassName="w-full"
                  label="Session Duration"
                  value={values.sessionDuration}
                  onChange={(value) => setFieldValue('sessionDuration', value)}
                  options={durationOptions}
                />

                <Select
                  wrapperClassName="w-full"
                  value={values.downloadLimit}
                  label="Download Limit"
                  onChange={(value) => setFieldValue('downloadLimit', value)}
                  options={downloadLimitOptions}
                />
              </div>

              <div className="flex flex-col items-start basis-[30%] justify-between gap-y-6">
                <div className="flex flex-col items-start justify-start w-full gap-y-7">
                  <Text
                    variant="body2"
                    className="text-[11px] leading-[16px] text-darkgrey"
                  >
                    Wifi Availability
                  </Text>

                  <div className="flex items-center justify-between w-full">
                    <TimePicker
                      withoutIcon
                      label="From"
                      value={values.from}
                      onChange={(time) => setFieldValue('from', time)}
                    />

                    <TimePicker
                      withoutIcon
                      label="To"
                      value={values.to}
                      onChange={(time) => setFieldValue('to', time)}
                    />
                  </div>

                  <SelectDay
                    className="w-full"
                    label="Days of the Week"
                    value={values.days}
                    onChange={(value) => setFieldValue('days', value)}
                  />
                </div>

                <Button
                  onClick={handleSubmit}
                  disabled={!isValid}
                  color="plum-wifi"
                  label="Save"
                  className="w-[206px] font-bold !text-[18px] !h-[49px] !self-end"
                />
              </div>
            </div>
          </section>
        </LoaderContainer>
      </main>
    </FormikProvider>
  );
};
