import { Modal, ModalProps, Text } from '@ui';
import React, { FC, useState } from 'react';
import { match } from 'ts-pattern';

import cn from 'classnames';
import {
  LegalInformation,
  LegalInformationVariant,
  BillingDetailsForm,
  CardDetailsForm,
} from './ui';

type AddCardModalProps = ModalProps;

enum Step {
  CARD_DETAILS = 'card-details',
  BILLING_DETAILS = 'billing-details',
}

export const AddCardModal: FC<AddCardModalProps> = ({
  isModalOpen,
  onModalClose,
}) => {
  const [currentStep, setCurrentStep] = useState(Step.CARD_DETAILS);

  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalVariant, setInfoModalVariant] =
    useState<LegalInformationVariant>('privacy');

  const onFinish = () => {
    console.log('finish');
  };

  const onClose = () => {
    setCurrentStep(Step.CARD_DETAILS);
    onModalClose();
  };

  const isCardDetails = currentStep === Step.CARD_DETAILS;

  return (
    <Modal
      withTitle
      title={
        isCardDetails
          ? 'Please enter your card details.'
          : 'Billing Information'
      }
      isModalOpen={isModalOpen}
      onModalClose={onClose}
      className="!p-0 overflow-x-hidden "
    >
      <section
        className={cn('px-[60px] pt-[17px] pb-[43px]', {
          '!pb-[52px]': !isCardDetails,
          '!px-6 !py-[35px]': isInfoModalOpen,
        })}
      >
        {match(isCardDetails)
          .with(true, () => (
            <CardDetailsForm
              onSubmit={() => setCurrentStep(Step.BILLING_DETAILS)}
            />
          ))
          .with(false, () => <BillingDetailsForm onSubmit={onFinish} />)
          .exhaustive()}
      </section>

      {!isCardDetails && (
        <div className="sticky bottom-0 left-0 w-full bg-customerology rounded-b-[10px] px-[64px] h-[35px] flex justify-end items-center gap-x-[10px] text-white">
          <button
            type="button"
            onClick={() => {
              setInfoModalVariant('security');
              setInfoModalOpen(true);
            }}
          >
            <Text
              variant="body2"
              className="!text-[10px] hover:underline cursor-pointer"
            >
              Security
            </Text>
          </button>

          <button
            type="button"
            onClick={() => {
              setInfoModalVariant('privacy');
              setInfoModalOpen(true);
            }}
          >
            <Text
              variant="body2"
              className="!text-[10px] hover:underline cursor-pointer"
            >
              Privacy
            </Text>
          </button>

          <button
            type="button"
            onClick={() => {
              setInfoModalVariant('terms');
              setInfoModalOpen(true);
            }}
          >
            <Text
              variant="body2"
              className="!text-[10px] hover:underline cursor-pointer"
            >
              Terms
            </Text>
          </button>
        </div>
      )}

      <Modal
        isModalOpen={isInfoModalOpen}
        onModalClose={() => setInfoModalOpen(false)}
        className="max-w-[570px] "
      >
        <LegalInformation variant={infoModalVariant} />
      </Modal>
    </Modal>
  );
};
