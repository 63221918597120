export enum Routes {
  ROOT = '/',
  HOME = '/home',
  PRODUCTS = '/products',
  FULLFORK = '/products/fullfork',
  PLUM_WIFI = '/products/plum-wifi',
  REPORTING = '/reporting',
  LIFT_REPORT = '/reporting/lift-report',
  LOCATION_REPORT = '/reporting/location-report',
  REDEMPTION_REPORT = '/reporting/redemption-report',
  REPORT_SCHEDULER = '/reporting/report-scheduler',
  APP_STORE = '/app-store',
  SETTINGS = 'settings',
}
