import { SelectOption } from '@/shared/ui';

export type Role =
  | 'account-holder'
  | 'regional-manager'
  | 'catering-manager'
  | 'general-manager'
  | 'assistant-manager'
  | 'cafe-manager';

export interface User {
  name: string;
  role: string;
  email: string;
  phone: string;
}

export const userRolesOptions: SelectOption[] = [
  { label: 'Account Holder', value: 'account-holder' },
  { label: 'Regional Manager', value: 'regional-manager' },
  { label: 'Catering Manager', value: 'catering-manager' },
  { label: 'General Manager', value: 'general-manager' },
  { label: 'Assistant Manager', value: 'assistant-manager' },
  { label: 'Cafe Manager', value: 'cafe-manager' },
];

export const userFieldsOptions: SelectOption[] = [
  { label: 'User Name', value: 'name' },
  { label: 'Role', value: 'role' },
  { label: 'Email Address', value: 'address' },
  { label: 'Phone Number', value: 'phone' },
];

export const users: User[] = [
  {
    name: 'Ian Rivera',
    role: 'Account Holder',
    email: 'ian.rivera@email.com',
    phone: '+100 123-4567',
  },
  {
    name: 'Bryan Kuniyoshi',
    role: 'Account Holder',
    email: 'ian.rivera@email.com',
    phone: '+100 123-4567',
  },
  {
    name: 'Arbee Cabahug',
    role: 'Account Holder',
    email: 'ian.rivera@email.com',
    phone: '+100 123-4567',
  },
  {
    name: 'Liza Soberano',
    role: 'Account Holder',
    email: 'ian.rivera@email.com',
    phone: '+100 123-4567',
  },
  {
    name: 'Bea Alonzo',
    role: 'Account Holder',
    email: 'ian.rivera@email.com',
    phone: '+100 123-4567',
  },
];
