import React, { FC } from 'react';

interface LogoSlideProps {
  logo: string;
  logoAlt: string | undefined;
}

export const LogoSlide: FC<LogoSlideProps> = ({ logo, logoAlt }) => {
  return (
    <div className="rounded-[10px] bg-white w-[350px] h-[420px]  py-5 px-[14px]">
      <div className="flex items-center justify-center">
        {logo ? (
          <img
            src={logo}
            alt={logoAlt || 'logo'}
            className="object-cover !h-[100px] !w-[100px] rounded-full"
          />
        ) : (
          <div className="h-[100px] w-[100px] bg-lightgrey rounded-full" />
        )}
      </div>
    </div>
  );
};
