import React, { FC } from 'react';
import { SidebarItem as ISidebarItem } from './config';
import { Link, useLocation } from 'react-router-dom';
import { DEFAULT_ICON_SIZE, Icon, Text } from '@ui';
import cn from 'classnames';
import { useOuterFrameParam } from '@hooks';

interface SidebarItemProps extends ISidebarItem {
  isActive: boolean;
  className?: string;
}

export const SidebarItem: FC<SidebarItemProps> = ({
  route,
  isActive,
  icon,
  iconSize,
  label,
  className,
}) => {
  const withOuterFrame = useOuterFrameParam();
  const location = useLocation();

  return (
    <Link
      to={{
        pathname: route || location.pathname,
        search: withOuterFrame ? '?isOuterFrame=true' : '',
      }}
    >
      <div
        className={cn(
          'group relative h-[50px] flex items-center hover:bg-black hover:bg-opacity-10 text-white px-4',
          {
            'bg-black bg-opacity-25': isActive,
          },
          className
        )}
      >
        <div className="w-[30px] flex justify-center mr-2">
          <Icon icon={icon} size={iconSize || DEFAULT_ICON_SIZE} />
        </div>

        <Text
          variant="body1"
          className={cn('text-white', { '!font-bold': isActive })}
        >
          {label}
        </Text>

        <div
          className={cn(
            'absolute top-0 right-0 bottom-0 h-full w-[3px] group-hover:bg-white group-hover:bg-opacity-50',
            {
              'bg-white bg-opacity-100': isActive,
            }
          )}
        />
      </div>
    </Link>
  );
};
