import { Solution } from '@types';
import { SelectOption } from '@ui';

export interface MonthlyBreakdownTableRow {
  name: string;
  locations: number;
  isSubscribed: boolean;
  price: number;
  total: number;
}

export const locationOptions: SelectOption[] = [
  { label: 'Location', value: 'name' },
];

export const monthlyBreakdownTableData: MonthlyBreakdownTableRow[] = [
  {
    name: 'ZIP Loyalty',
    locations: 3,
    isSubscribed: true,
    price: 99,
    total: 99,
  },
  {
    name: 'Plum WiFi',
    locations: 3,
    isSubscribed: true,
    price: 99,
    total: 99,
  },
  {
    name: 'FullFork',
    locations: 3,
    isSubscribed: false,
    price: 99,
    total: 99,
  },
  {
    name: 'ZIP Surveillance',
    locations: 3,
    isSubscribed: true,
    price: 0,
    total: 0,
  },
  {
    name: 'ZIP Marketing Plus',
    locations: 3,
    isSubscribed: true,
    price: 0,
    total: 0,
  },
  {
    name: 'ZIP Cater',
    locations: 3,
    isSubscribed: true,
    price: 0,
    total: 0,
  },
];

export interface SubscribedApp {
  name: Solution;
  price: number;
}

export interface LocationBreakdownTableRow {
  name: string;
  subscribedApps: SubscribedApp[];
  total: number;
}

export const locationBreakdownTableData: LocationBreakdownTableRow[] = [
  {
    name: '190 S. State College Blvd, Brea, CA 92821',
    subscribedApps: [
      {
        name: 'zip-surveillance',
        price: 99,
      },
      {
        name: 'plum-wifi',
        price: 99,
      },
    ],
    total: 189,
  },
  {
    name: 'Location 2 College Blvd, Brea, CA 92821',
    subscribedApps: [
      {
        name: 'zip-surveillance',
        price: 99,
      },
      {
        name: 'plum-wifi',
        price: 99,
      },
    ],
    total: 189,
  },
  {
    name: 'Location 3 College Blvd, Brea, CA 92821',
    subscribedApps: [
      {
        name: 'plum-wifi',
        price: 99,
      },
    ],
    total: 99,
  },
  {
    name: 'Location 4 College Blvd, Brea, CA 92821',
    subscribedApps: [
      {
        name: 'zip-surveillance',
        price: 99,
      },
      {
        name: 'plum-wifi',
        price: 99,
      },
    ],
    total: 189,
  },
  {
    name: 'Location 5 College Blvd, Brea, CA 92821',
    subscribedApps: [
      {
        name: 'zip-surveillance',
        price: 99,
      },
    ],
    total: 99,
  },
];
