import { useClickAway } from '@hooks';
import cn from 'classnames';
import React, { FC, useRef, useState } from 'react';
import { Colors, Icons } from '@utils';
import { Icon } from '@ui';

interface SelectCountProps {
  options: number[];
  value: number;
  className?: string;
  onChange: (value: number) => void;
}

export const SelectCount: FC<SelectCountProps> = ({
  options,
  className,
  value,
  onChange,
}) => {
  const selectRef = useRef<HTMLButtonElement>(null);

  const [isFocused, setFocused] = useState(false);

  const dropdownRef = useClickAway(() => setFocused(false), [selectRef]);

  const onClickOption = (option: number) => {
    onChange(option);
    setFocused(false);
  };

  return (
    <div className={cn('relative w-min', className)}>
      <button
        type="button"
        ref={selectRef}
        onClick={() => setFocused(!isFocused)}
        className="flex justify-center items-center pl-[10px] pr-[3px] gap-x-[6px]  h-6 bg-lightgrey rounded-[5px] text-[13px] leading-5 font-bold"
      >
        {value}

        <Icon
          icon={Icons.ArrowRight}
          size={15}
          className={cn('mb-[1px]', {
            'rotate-90': !isFocused,
            '-rotate-90': isFocused,
          })}
          color={Colors.Black}
        />
      </button>

      {isFocused && (
        <div
          ref={dropdownRef}
          className="absolute top-[33px] flex flex-col border border-borderColor rounded-[5px] bg-white w-full"
        >
          {options.map((option) => (
            <button
              key={option}
              type="button"
              onClick={() => onClickOption(option)}
              className="text-[13px] text-center leading-[19px] hover:bg-lightgrey cursor-pointer"
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
