import React, { FC, useState } from 'react';
import { Button, Carousel, Modal, Text } from '@ui';
import { Icons } from '@utils';
import { appStoreModals } from './config';
import { Solution } from '@types';
import { useDispatch } from '@store';
import { cartActions } from '@store/entities';
import { match } from 'ts-pattern';
import { SelectLocations } from './SelectLocations';
import { BuyRouter } from './BuyRouter';
import cn from 'classnames';

enum ModalStep {
  BUY_APP = 'buy-app',
  SELECT_LOCATIONS = 'select-locations',
  WIFI_ROUTER = 'wifi-router',
}

interface AppStoreModalProps {
  variant: Solution;
  isOpen: boolean;
  onClose: () => void;
}

export const AppStoreModal: FC<AppStoreModalProps> = ({
  variant,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState<ModalStep>(ModalStep.BUY_APP);

  const addToCart = () => {
    const { title, price, logo } = appStoreModals[variant];

    dispatch(
      cartActions.addItem({
        name: title,
        price,
        count: 1,
        image: logo,
      })
    );
  };

  const onClickSubscribe = () => {
    addToCart();
    onClose();
    dispatch(cartActions.openCart());
  };

  const onContinueToRouterSelection = () => {
    addToCart();
    setStep(ModalStep.WIFI_ROUTER);
  };

  const handleClose = () => {
    setStep(ModalStep.BUY_APP);
    onClose();
  };

  const onFinish = () => {
    handleClose();
    dispatch(cartActions.openCart());
  };

  return (
    <Modal
      isModalOpen={isOpen}
      onModalClose={handleClose}
      className={cn({
        '!p-10': step !== ModalStep.SELECT_LOCATIONS,
        '!px-[61px] !py-[35px]': step === ModalStep.SELECT_LOCATIONS,
      })}
    >
      {match(step)
        .with(ModalStep.BUY_APP, () => (
          <>
            <section className="flex justify-between items-start gap-x-[34px]">
              <Carousel
                images={appStoreModals[variant].images}
                color={appStoreModals[variant].color}
              />

              <div>
                <div className="flex items-center justify-between">
                  <Text variant="h1" className="!text-black !font-bold">
                    {appStoreModals[variant].title}
                  </Text>

                  <div
                    className="bg-lightgrey rounded-[5px] px-[18px] py-[10px] text-center"
                    style={{
                      color: appStoreModals[variant].color,
                    }}
                  >
                    <span className="block text-[12px] leading-[18px]">
                      Pricing
                    </span>
                    <span className="text-[45px] leading-[67px] font-bold">
                      $
                      {appStoreModals[variant].price}
                    </span>
                  </div>
                </div>

                <p className="text-base mt-[30px] max-w-[465px]">
                  {appStoreModals[variant].description}
                </p>
              </div>
            </section>

            <Button
              onClick={
                variant === 'plum-wifi'
                  ? () => setStep(ModalStep.SELECT_LOCATIONS)
                  : onClickSubscribe
              }
              label={
                variant === 'plum-wifi' ? 'Select Locations' : 'Subscribe Now'
              }
              color={variant}
              iconRight={Icons.ArrowRightLong}
              className="ml-auto !rounded-full mt-[30px]"
            />
          </>
        ))
        .with(ModalStep.SELECT_LOCATIONS, () => (
          <SelectLocations onContinue={onContinueToRouterSelection} />
        ))
        .with(ModalStep.WIFI_ROUTER, () => <BuyRouter onPurchase={onFinish} />)
        .exhaustive()}
    </Modal>
  );
};
