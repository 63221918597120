/* eslint-disable quote-props */

export type ToUndefinedObject<T extends object> = Partial<
  Record<keyof T, undefined>
>;

export type AllOrNothing<T extends object> = T | ToUndefinedObject<T>;

export type Solution =
  | 'zip-loyalty'
  | 'zip-surveillance'
  | 'fullfork'
  | 'plum-wifi'
  | 'zip-marketing'
  | 'customerology';

export const SolutionTitle: Record<Solution, string> = {
  'fullfork': 'Fullfork',
  'plum-wifi': 'Plum WiFi',
  'zip-loyalty': 'Zip Loyalty',
  'zip-marketing': 'Zip Marketing',
  'zip-surveillance': 'Zip Surveillance',
  'customerology': 'Customerology',
};
