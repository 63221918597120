import { SelectOption } from '@ui';

export interface SocialLink {
  name: string;
  link: string;
  icon?: File;
}

export const categoriesOptions: SelectOption[] = [
  {
    label: 'American, Mediterranean',
    value: 'American',
  },
  { label: 'Italian', value: 'Italian' },
  { label: 'Asian', value: 'Asian' },
  { label: 'Indian', value: 'Indian' },
  { label: 'Middle Eastern', value: 'Middle Eastern' },
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Greek', value: 'Greek' },
  { label: 'French', value: 'French' },
  { label: 'BBQ', value: 'BBQ' },
];
