import React, { FC } from 'react';
import { PeriodSelectItem, periodsItems } from './config';
import cn from 'classnames';

interface PeriodSelectProps {
  value: PeriodSelectItem;
  onChange: (value: PeriodSelectItem) => void;
}

export const PeriodSelect: FC<PeriodSelectProps> = ({ value, onChange }) => {
  return (
    <ul className="flex flex-col gap-y-[5px] ">
      {periodsItems.map((item) => (
        <button
          type="button"
          key={item.label}
          onClick={() => onChange(item)}
          className={cn(
            'rounded-[5px] text-left text-text pl-[10px] pr-[31px] py-[5px] text-[14px] leading-5 hover:bg-plumwifi hover:text-white whitespace-nowrap',
            {
              'bg-plumwifi cursor-default text-white':
                item.label === value.label,
            }
          )}
        >
          {item.label}
        </button>
      ))}
    </ul>
  );
};
