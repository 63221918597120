import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { CardSchema, CardValues } from '../../../helpers/validation';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Button, Input } from '@ui';
import { Icons } from '@utils';

interface CardDetailsFormProps {
  onSubmit: () => void;
}

export const CardDetailsForm: FC<CardDetailsFormProps> = ({ onSubmit }) => {
  const form = useFormik<CardValues>({
    initialValues: {
      holderName: '',
      cardNumber: '',
      expiryDate: '',
      cvv: '',
    },
    validationSchema: toFormikValidationSchema(CardSchema),
    onSubmit: () => {
      onSubmit();
    },
  });

  const { handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <div className="flex flex-col items-start gap-y-[23px] max-w-[472px]">
        <Input<CardValues>
          fieldName="holderName"
          label="Card Holder Name"
          className="w-full"
        />

        <Input<CardValues>
          fieldName="cardNumber"
          label="Card Number"
          className="w-full"
          maxLength={16}
        />

        <div className="flex w-[70%] gap-x-10">
          <Input<CardValues>
            fieldName="expiryDate"
            label="Expiry Date"
            maxLength={4}
          />

          <Input<CardValues> fieldName="cvv" label="CVV Code" maxLength={3} />
        </div>
      </div>
      <Button
        onClick={handleSubmit}
        label="Next"
        iconRight={Icons.ArrowRight}
        color="customerology"
        className="mt-9"
      />
    </FormikProvider>
  );
};
