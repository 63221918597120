import React from 'react';
import { PuffLoader } from 'react-spinners';
import { Colors } from '@utils';
import cn from 'classnames';

interface LoaderProps {
  centered?: boolean;
  withTopIndent?: boolean;
  className?: string;
  color?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  centered = false,
  withTopIndent = false,
  className,
  color = Colors.FullFork,
}) => (
  <div
    className={cn(
      {
        'w-full flex justify-center': centered,
        'pt-8': withTopIndent,
      },
      className
    )}
  >
    <PuffLoader color={color} />
  </div>
);
