import { Icons } from '@utils';
import { CustomizationValues } from './helpers/validation';

interface SocialLinkItem {
  name: string;
  icon: string;
  fieldName: keyof CustomizationValues;
  switchFieldName: keyof CustomizationValues;
}

export const socialLinksItems: SocialLinkItem[] = [
  {
    name: 'facebook',
    icon: Icons.Facebook,
    fieldName: 'facebookLink',
    switchFieldName: 'facebookSelected',
  },
  {
    name: 'instagram',
    icon: Icons.Instagram,
    fieldName: 'instagramLink',
    switchFieldName: 'instagramSelected',
  },
  {
    name: 'twitter',
    icon: Icons.Twitter,
    fieldName: 'twitterLink',
    switchFieldName: 'twitterSelected',
  },
];

export type CustomizationAccordionVariant = 'logo' | 'background' | 'links';

interface CustomizationAccordion {
  variant: CustomizationAccordionVariant;
  title: string;
}

export const accordionsItems: CustomizationAccordion[] = [
  {
    variant: 'logo',
    title: 'Logo',
  },
  {
    variant: 'background',
    title: 'Background Image',
  },
  {
    variant: 'links',
    title: 'Social Media Links',
  },
];
