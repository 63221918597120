import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { match } from 'ts-pattern';

import { TextVariant } from './config';

interface TextProps {
  variant: TextVariant;
  children: ReactNode;
  className?: string;
}

export const Text: FC<TextProps> = ({ variant, className, children }) =>
  match(variant)
    .with('h1', () => (
      <h1 className={cn('font-medium text-[37px] leading-[55.5px]', className)}>
        {children}
      </h1>
    ))
    .with('h2', () => (
      <h2 className={cn('font-medium text-[29px] leading-[43.5px]', className)}>
        {children}
      </h2>
    ))
    .with('h3', () => (
      <h3 className={cn('font-medium text-[24px] leading-[36px]', className)}>
        {children}
      </h3>
    ))
    .with('h4', () => (
      <h4 className={cn('font-medium text-[20px] leading-[30px]', className)}>
        {children}
      </h4>
    ))
    .with('h5', () => (
      <h5 className={cn('font-medium text-[18px] leading-[27px]', className)}>
        {children}
      </h5>
    ))
    .with('body1', () => (
      <p className={cn('font-medium text-base', className)}>{children}</p>
    ))
    .with('body2', () => (
      <p className={cn('font-medium text-xs leading-[18px]', className)}>
        {children}
      </p>
    ))
    .exhaustive();
