import { Button, Text } from '@ui';
import React, { FC } from 'react';
import { locations } from './config';
import { Icons } from '@utils';

interface SelectLocationsProps {
  onContinue: () => void;
}

export const SelectLocations: FC<SelectLocationsProps> = ({ onContinue }) => {
  return (
    <section className="max-w-[850px]">
      <Text variant="h3" className="!font-bold">
        Select Locations
      </Text>
      <Text variant="body1" className="mt-[5px]">
        Lorem ipsum dolor sit amet consectetur. Sagittis nisl viverra netus orci
        non erat malesuada. A id tortor ullamcorper congue. Iaculis ultrices
      </Text>

      <div className="flex justify-between items-center py-[15px] border-b border-borderColor">
        <Text variant="body1" className="!text-darkgrey">
          Location
        </Text>
        <Text variant="body1" className="!text-darkgrey">
          Action
        </Text>
      </div>

      <ul className="flex flex-col gap-y-[15px] pt-[15px]">
        {locations.map((location) => (
          <li className="flex items-center justify-between">
            <Text variant="body1">{location}</Text>

            <Button
              label="Subscribe"
              color="plum-wifi"
              className="!rounded-full !h-[34px]"
            />
          </li>
        ))}
      </ul>

      <div className="flex justify-end items-center gap-x-[15px] mt-[74px]">
        <Button
          label="Add-Ons"
          color="plum-wifi"
          iconRight={Icons.Close}
          className="!rounded-full"
          iconClassName="rotate-45"
        />

        <Button
          onClick={onContinue}
          label="Continue"
          color="white"
          iconRight={Icons.ArrowRight}
          className="!rounded-full"
        />
      </div>
    </section>
  );
};
