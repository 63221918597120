import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../baseQuery';
import { HTTPMethod } from '../config';
import { Router, WifiSettings } from './models';

export const plumWifiApi = createApi({
  baseQuery,
  reducerPath: 'plumWifiApi',
  endpoints: (builder) => ({
    getRoutersByLocation: builder.query<Router[], number>({
      query: (locationId) => ({
        url: `/PlumWifi/router/getlist/location/${locationId}`,
        method: HTTPMethod.GET,
      }),
    }),
    getWifiSettingsByLocation: builder.query<WifiSettings, number>({
      query: (locationId) => ({
        url: `/PlumWifi/location/${locationId}/getWifiSettings`,
        method: HTTPMethod.GET,
      }),
    }),
  }),
});

export const {
  useGetRoutersByLocationQuery,
  useGetWifiSettingsByLocationQuery,
} = plumWifiApi;
