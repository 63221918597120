import React, { FC } from 'react';
import { BreakdownVariant } from '../../config';
import { MonthlyBreakdown } from './MonthlyBreakdown';
import { LocationBreakdown } from './LocationBreakdown';

interface ManageAppsProps {
  variant: BreakdownVariant;
}

export const ManageApps: FC<ManageAppsProps> = ({ variant }) => {
  return (
    <section className="pt-12">
      {variant === 'monthly' && <MonthlyBreakdown />}
      {variant === 'location' && <LocationBreakdown />}
    </section>
  );
};
