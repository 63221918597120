import { Icons, Images } from '@utils';
import { Icon, Modal, Text } from '@ui';
import React, { FC } from 'react';
import cn from 'classnames';
import { match } from 'ts-pattern';

export interface Expiration {
  value: number;
  text: string;
  expired: boolean;
}

interface OfferPreviewProps {
  isOpen: boolean;
  image: string | File;
  title: string;
  description: string;
  expiration: Expiration;
  selectedDays?: (string | undefined)[];
  onClose: () => void;
  finePrint: string;
}

export const OfferPreview: FC<OfferPreviewProps> = ({
  isOpen,
  onClose,
  image,
  title,
  description,
  expiration,
  selectedDays,
  finePrint,
}) => {
  const enterprizeLogo = null;
  const finePrintSentences = finePrint.split('.');
  const resultImage = match(!!image)
    .with(true, () =>
      typeof image === 'string' ? image : URL.createObjectURL(image)
    )
    .otherwise(() => null);

  return (
    <Modal isModalOpen={isOpen} onModalClose={onClose} className="!p-0">
      <div className="w-[414px] h-[50%] pt-4 overflow-hidden">
        {enterprizeLogo ? (
          <img
            src={enterprizeLogo}
            alt="offer-preview"
            className="object-cover w-[55px] h-[55px] rounded-full mx-auto"
          />
        ) : (
          <div className="w-[55px] h-[55px] rounded-full bg-lightgrey mx-auto" />
        )}

        <img
          src={resultImage || Images.FFDefaultOfferImage}
          alt="offer-preview"
          className="object-cover w-full h-[276px] mt-4"
        />

        <div className="px-6 py-5 rounded-[20px] relative -top-4 z-10 bg-white">
          <Text
            variant="h3"
            className="text-[26px] leading-[39px] !font-bold text-text"
          >
            {title}
          </Text>

          <div className="flex items-center justify-start gap-x-4">
            <span
              className={cn(
                'flex items-center italic !font-light justify-start text-[12px] h-[19px] leading-[19px] gap-x-[5px] w-fit mt-[6px] text-white bg-fullfork py-[1px] px-[10px] rounded-md min-w-fit',
                {
                  '!bg-lightgrey !text-text': expiration.expired,
                }
              )}
            >
              <Icon icon={Icons.Clock} size={13} />
              {expiration.expired
                ? 'Expired'
                : `${expiration.value || ''} ${expiration.text}`}
            </span>
          </div>
          <div className="border-b border-b-[#E1E1E1]">
            <Text
              variant="body2"
              className="w-auto mt-[18px] overflow-hidden break-all text-ellipsis text-[#33333380] "
            >
              Description
            </Text>
            <Text
              variant="body1"
              className="w-auto mt-[18px] overflow-hidden break-all text-ellipsis"
            >
              {description}
            </Text>
          </div>
          <div className="border-b border-b-[#E1E1E1]">
            <Text
              variant="body2"
              className="w-auto mt-[18px] overflow-hidden break-all text-ellipsis text-[#33333380] "
            >
              Fine Print
            </Text>
            <div className="mt-[18px]">
              {finePrintSentences.map((sentence, index) => (
                <Text
                  variant="body1"
                  className="w-auto  overflow-hidden break-all text-ellipsis"
                >
                  {sentence}
                </Text>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
