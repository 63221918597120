import React, { FC } from 'react';
import { Icon } from '../Icon';
import { Text } from '../Text';
import cn from 'classnames';
import { Solution } from '@types';

export interface PageTab {
  icon: string;
  label: string;
}

interface PageTabsProps {
  tabs: PageTab[];
  value: number;
  variant: Solution | 'customerology';
  className?: string;
  onChange: (tab: number) => void;
}

export const PageTabs: FC<PageTabsProps> = ({
  tabs,
  value,
  variant,
  className,
  onChange,
}) => {
  return (
    <div className={cn('flex flex-row items-center', className)}>
      {tabs.map(({ icon, label }, index) => {
        const isActive = index === value;

        return (
          <button
            key={label}
            type="button"
            className={cn('relative px-4 py-6 mx-2', {
              'opacity-40': !isActive,
              'text-fullfork': isActive && variant === 'fullfork',
              'hover:text-fullfork': variant === 'fullfork',
              'text-plumwifi': isActive && variant === 'plum-wifi',
              'hover:text-plumwifi': variant === 'plum-wifi',
              'text-customerology': isActive && variant === 'customerology',
              'hover:text-customerology': variant === 'customerology',
            })}
            onClick={() => onChange(index)}
          >
            <div className="flex items-center justify-start">
              <Icon icon={icon} />

              <Text variant="h5" className="inline ml-2">
                {label}
              </Text>
            </div>

            {isActive && (
              <div
                className={cn('absolute left-0 right-0 bottom-2 h-[4px]', {
                  'bg-fullfork': variant === 'fullfork',
                  'bg-plumwifi': variant === 'plum-wifi',
                  'bg-customerology': variant === 'customerology',
                })}
              />
            )}
          </button>
        );
      })}
    </div>
  );
};
