import { Icon, LoaderContainer, Text } from '@ui';
import React, { FC } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { CustomizationValues } from './helpers/validation';
import { useAccordions } from '@hooks';
import { CustomizationAccordion } from './Accordions';
import { ConnectSlide } from './Preview/ConnectSlide';
import { SessionSlide } from './Preview/SessionSlide';
import { LogoSlide } from './Preview/LogoSlide';
import { Colors, Icons, Images } from '@utils';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/pagination';
import { accordionsItems } from './config';
import { useSelector } from '@store';
import { plumWifiSelectors } from '@store/entities';
import { match } from 'ts-pattern';

export const Customization: FC = () => {
  const { settings, isSettingsLoading } = useSelector(plumWifiSelectors.all);

  const { accordions, onClose, onOpen } = useAccordions(3);

  const form = useFormik<CustomizationValues>({
    initialValues: {
      logo: settings?.logo || null,
      logoAlt: 'logo',
      background: settings?.backgroundImg || null,
      backgroundAlt: 'background',
      facebookLink: settings?.facebook || '',
      facebookSelected: settings?.enableFacebook
        ? !!Number(settings?.enableFacebook)
        : false,
      instagramLink: settings?.instagram || '',
      instagramSelected: settings?.enableInstagram
        ? !!Number(settings?.enableInstagram)
        : false,
      twitterLink: settings?.twitter || '',
      twitterSelected: settings?.enableTwitter
        ? !!Number(settings?.enableTwitter)
        : false,
    },
    enableReinitialize: true,
    onSubmit: console.log,
  });
  const { values } = form;

  const backgroundSrc = match(typeof values.background)
    .with('string', () => values.background)
    .otherwise(() =>
      values.background ? URL.createObjectURL(values.background) : null
    );

  const logoSrc = match(typeof values.logo)
    .with('string', () => values.logo)
    .otherwise(() => (values.logo ? URL.createObjectURL(values.logo) : null));

  return (
    <FormikProvider value={form}>
      <main className="relative object-cover h-full bg-no-repeat bg-cover bg-plumwifi-bg rounded-[10px] flex justify-center items-center gap-x-[70px] min-w-[1200px]">
        <div className="absolute flex flex-col items-center top-6 right-6">
          <figure className="bg-white rounded-[5px] py-[5px] px-[7px]">
            <Icon icon={Icons.Phone} color={Colors.PlumWifi} size={24} />
          </figure>
          <figcaption className="text-[11px] leading-4 text-white font-bold mt-[10px] cursor-default">
            Preview
          </figcaption>
        </div>

        <section className="h-[405px] min-w-[504px] rounded-[10px] bg-white py-14 px-[46px] text-text">
          <Text variant="h3" className="!font-bold mb-8">
            Customization
          </Text>

          <div className="flex flex-col gap-y-5">
            {accordionsItems.map(({ variant, title }, index) => (
              <CustomizationAccordion
                key={index}
                title={title}
                variant={variant}
                isOpen={accordions[index].isOpen}
                onOpen={() => onOpen(index)}
                onClose={onClose}
              />
            ))}
          </div>
        </section>

        <LoaderContainer loading={isSettingsLoading} color={Colors.PlumWifi}>
          <section className="h-[512px] w-[445px] flex items-center justify-center bg-no-repeat bg-cover bg-phone-preview py-14">
            <img
              src={backgroundSrc || Images.Food}
              alt="bg"
              className="!w-[400px] !h-[512px] absolute z-0 object-cover"
            />

            <div className="relative z-10">
              <Swiper
                slidesPerView={1}
                spaceBetween={40}
                pagination={{
                  bulletClass:
                    'inline-block !h-4 !w-4 bg-white rounded-full opacity-50 mx-2 cursor-pointer',
                  bulletActiveClass: '!opacity-100',
                  clickable: true,
                }}
                modules={[Pagination]}
                className="w-[400px] h-[602px]"
                direction="horizontal"
              >
                <SwiperSlide className="flex items-center ml-[25px] mx-2.5">
                  <ConnectSlide
                    logo={logoSrc}
                    logoAlt={values.logoAlt}
                    withFacebook={values.facebookSelected}
                    withInstagram={values.instagramSelected}
                    withTwitter={values.twitterSelected}
                  />
                </SwiperSlide>

                <SwiperSlide className="flex items-center">
                  <SessionSlide logo={logoSrc} logoAlt={values.logoAlt} />
                </SwiperSlide>

                <SwiperSlide className="flex items-center">
                  <LogoSlide logo={logoSrc} logoAlt={values.logoAlt} />
                </SwiperSlide>
              </Swiper>
            </div>
          </section>
        </LoaderContainer>
      </main>
    </FormikProvider>
  );
};
