import { Icon, Page, Text } from '@ui';
import React from 'react';
import { reportingItems } from './config';
import { Link } from 'react-router-dom';
import { useOuterFrameParam } from '@hooks';

export const Reports: React.FC = () => {
  const withOuterFrame = useOuterFrameParam();

  return (
    <Page title="Reports" childrenClassName="py-12">
      {/* <div className="flex justify-between items-stretch gap-x-[18px]gap-y-[18px] flex-wrap" */}
      <div className="grid grid-cols-3 gap-x-4 gap-y-4">
        {reportingItems.map(({ title, description, icon, route }) => (
          <Link
            to={{
              pathname: route,
              search: withOuterFrame ? '?isOuterFrame=true' : '',
            }}
            key={route}
          >
            <div className="flex items-stretch justify-start h-full px-6 py-5 border rounded-md basis-1/4 border-mediumgrey grow hover:bg-customerology hover:text-white">
              <Icon
                icon={icon}
                size="72px"
                className="mr-5 min-w-[72px] self-center"
              />
              <div className="h-full">
                <Text variant="h4">{title}</Text>
                <Text variant="body2" className="mt-1">
                  {description}
                </Text>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Page>
  );
};
